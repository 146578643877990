import { SvgIcon } from "@mui/material";
import React from "react";

const OfflineIcon = ({ 
  fill = "none", 
  stroke = "#EE534F", 
  strokeWidth = 2, 
  viewBox = "0 0 20 20", 
  width = "20", 
  height = "20", 
  ...props 
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    sx={{ fill, display: "block" }}
    {...props}
  >
    <line x1="1" y1="1" x2="23" y2="23" />
    <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55" />
    <path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39" />
    <path d="M10.71 5.05A16 16 0 0 1 22.58 9" />
    <path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88" />
    <path d="M8.53 16.11a6 6 0 0 1 6.95 0" />
    <line x1="12" y1="20" x2="12.01" y2="20" />
  </SvgIcon>
);

export default OfflineIcon;
