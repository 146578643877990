import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  List,
  ListItem,
  Typography,
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CustomSwitchLarge from "../../../../../Components/CustomSwitchLarge";
import {
  getDigitalTwinConfigAlerts,
  updateDigitalTwinConfigAlerts,
} from "../../../../../Api/DigitalTwinListApi";
import Loader from "../../../../../Helper/Loaders/Loader";
import { OutlinedButton } from "../../../../../Components/CustomButton";

// Styled Components
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: "30em",
  minHeight: "10em",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: 0,
  paddingTop: "8px",
}));

const AlertConfigurationMap = ({
  config,
  dialogWidth = "30em",
  dialogMinHeight = "10em",
  applyButtonLabel = "Apply",
  cancelButtonLabel = "Cancel",
  trigger = null
}) => {
  const [open, setOpen] = useState(false);
  const [configAlerts, setConfigAlerts] = useState([]);
  const [updatedAlerts, setUpdatedAlerts] = useState({});
  const [loading, setLoading] = useState("");

  
  const handleIconClick = async () => {
    setOpen(true);
    setLoading("Loading alerts ...");
    await fetchAlerts(config.id);
  };

  
  const handleClose = () => {
    setOpen(false);
    setUpdatedAlerts({});
  };


  const fetchAlerts = useCallback(async (configID) => {
    console.log("Fetching alerts for config:", configID);
    try {
      const response = await getDigitalTwinConfigAlerts({ configID });
      if (response.responseStatus.code === 200) {
        setConfigAlerts(response.response.data || []);
      }
    } catch (error) {
      console.error("Error fetching alerts:", error);
    } finally {
      setLoading("");
    }
  }, []);


  const handleToggle = (alertID, newStatus) => {
    setUpdatedAlerts((prev) => ({
      ...prev,
      [alertID]: newStatus,
    }));
  };


  const handleApplyChanges = async () => {
    setLoading("Applying changes ...");
    const alertsToUpdate = Object.keys(updatedAlerts).map((alertID) => ({
      alertID,
      enabled: updatedAlerts[alertID],
    }));

    try {
      const response = await updateDigitalTwinConfigAlerts({
        alertsToUpdate: JSON.stringify(alertsToUpdate),
        configID: config.id,
      });
      console.log("Update response:", response);

      setConfigAlerts((prev) =>
        prev.map((alert) =>
          updatedAlerts.hasOwnProperty(alert.id)
            ? { ...alert, enabled: updatedAlerts[alert.id] }
            : alert
        )
      );

      setUpdatedAlerts({});
      handleClose();
    } catch (error) {
      console.error("Error updating alerts:", error);
    } finally {
      setLoading("");
    }
  };

  return (
    <div>
      {trigger ? (
        React.cloneElement(trigger, { onClick: handleIconClick })
      ) : (
        <IconButton onClick={handleIconClick}>
          <EditOutlinedIcon />
        </IconButton>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="paperHeader">
            Alert Settings for {config.configName}
          </Typography>
        </DialogTitle>

        <StyledDialogContent sx={{ width: dialogWidth, minHeight: dialogMinHeight }}>
          {loading ? (
            <Loader text={loading} />
          ) : configAlerts.length > 0 ? (
            <List sx={{ paddingTop: 0 }}>
              {configAlerts.map((alert) => (
                <StyledListItem key={alert.id}>
                  <Box>
                    <Typography variant="textSubtitleDarkGrey" align="left">
                      {alert.alertDescription}
                    </Typography>
                  </Box>
                  <Box>
                    <CustomSwitchLarge
                      checked={
                        updatedAlerts.hasOwnProperty(alert.id)
                          ? updatedAlerts[alert.id]
                          : alert.enabled
                      }
                      onChange={(newState) => handleToggle(alert.id, newState)}
                    />
                  </Box>
                </StyledListItem>
              ))}
            </List>
          ) : (
            <Typography>No alerts found.</Typography>
          )}
        </StyledDialogContent>


        <DialogActions>
          <OutlinedButton variant="outlined" onClick={handleClose}>
            <Typography variant="textSubtitle">{cancelButtonLabel}</Typography>
          </OutlinedButton>

          <OutlinedButton
            variant="contained"
            fullWidth
            onClick={handleApplyChanges}
            disabled={loading !== ""}
          >
            <Typography variant="textSubtitleWhite">{applyButtonLabel}</Typography>
          </OutlinedButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertConfigurationMap;
