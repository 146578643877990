import { SvgIcon } from "@mui/material";
import React from "react";

const UncheckedBoxSvg = ({ 
  fill = "white", 
  stroke = "#6D72F6", 
  strokeWidth = 1, 
  viewBox = "0 0 14 14", 
  width = "14",
  height= "14",
  ...props 
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width = {width}
    height = {height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    sx={{ fill }}
    {...props}
  >
    <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" />
  </SvgIcon>
);

export default UncheckedBoxSvg;
