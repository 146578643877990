import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import BatteryInfoCardSection from "../BatteryInfoCardSection";
import HealthDegradationChartSection from "../HealthDegradationChartSection";
import { useTranslation } from "react-i18next";

function BatteryOverview({ pagesContent }) {
  const {t} = useTranslation(['common', 'health'])
  const searchID = useSelector((state) => state.SelectedSearchID.value);
  const searchType = useSelector((state) => state.SelectedSearchType.value);
  const searchConfig = useSelector(
    (state) => state.SelectedSearchConfig.value
  );
  const searchColumn = searchConfig.keys[searchType]
  const [batterySOH, setBatterySOH] = useState(null)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <BatteryInfoCardSection pagesContent={pagesContent} searchID={searchID} searchType={searchType} searchColumn={searchColumn} setBatterySOH={setBatterySOH}/>
      </Grid>
      <Grid item xs={12} md={8}>
        <HealthDegradationChartSection 
          pagesContent={pagesContent}
          deviceID={searchID}
          searchID={searchID}
          searchColumn={searchColumn}
          soh={batterySOH}
          pageLabel="Battery Health"
          cardTitle = {t('health:degradation_curve', "Degradation Curve")}
          cardSubTitle= {t('health:degradation_curve_subtitle', "Curve representing the degrading SOH")}   
        />
      </Grid>
    </Grid>
  );
}

export default BatteryOverview;
