import { GetData } from "./Functions/SettingData";

async function getBatteriesHealth(
  filters,
  sort,
  sohLevel
) {
  let newRangeStart = sohLevel?.min || 0;
  let newRangeEnd = sohLevel?.max || 120;
  let apiFilters = {...filters}
  apiFilters['rangeStart'] = newRangeStart
  apiFilters['rangeEnd'] = newRangeEnd
  var rawJson = {
    filters: JSON.stringify(apiFilters),
    sort
  };

  var config = {
    method: "get",
    url: "/api/health/dashboard/fleet_battery_health",
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getBatteryHealthDistribution(
  filters
) {
  var rawJson = {
    filters: JSON.stringify(filters),
  };

  var config = {
    method: "get",
    url: "/api/health/dashboard/fleet_battery_health_group",
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getBatteryHealthSearchSuggestions(columnName, search, limit) {
  var rawJson = {
    columnName: columnName,
    columnSearch: search,
    columnLimit: limit,
  };

  var config = {
    method: "get",
    url: "/api/health/dashboard/search",
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

export {
  getBatteriesHealth,
  getBatteryHealthDistribution,
  getBatteryHealthSearchSuggestions,
};
