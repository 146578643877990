import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTabContainer = styled(Tabs)(({ theme, myColor }) => ({
  borderBottom: null,
  "& .MuiTabs-indicator": {
    display:"none",
    backgroundColor: "#fff",
    borderRadius: "12px 12px 0px 0px",
  },
}));



const StyledIndividualTabV2 = styled((props) => <Tab {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    width: "max-content",
    fontStyle: "normal",
    fontSize: "12px",
    borderBottom: "2px solid transparent",
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '12px',
    paddingBottom: '10px',
    color: `${theme.palette.primary.textMuted}`,
    fontWeight: 500,
    opacity: 1,
    "&:hover": {
      color: `${theme.palette.primary.textHighlight}`,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: `${theme.palette.primary.main}`,
      fontWeight: 700,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    }
  })
);


const StyledIndividualTab = styled((props) => <Tab {...props} />)(
  ({ theme, myColor }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    width: "max-content",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: 400,
    fontSize: "12px",
    borderBottom: "2px solid transparent",

    color: "#000000",
    opacity: 1,
    "&:hover": {
      color: "rgba(0, 0, 0,0.7)",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#000",
      // backgroundColor: "white",
      // borderRadius: "12px 12px 0px 0px",

      borderRadius: "8px",
      borderBottom: `2px solid #6D72F6`,
      // background: "#FFF",
      boxShadow: "0px 2px 40px 0px rgba(17, 6, 38, 0.08)",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

export { StyledTabContainer, StyledIndividualTab, StyledIndividualTabV2 };
