import { SvgIcon } from "@mui/material";
import React from "react";

const SelectDownArrowSvg = ({
  width = "11",
  height = "6",
  viewBox = "0 0 11 6",
  fill = "none",
  stroke = "#444B51",
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    sx={{ fill, display: "block" }}
    {...props}
  >
    <path
      d="M0.130146 0C0.0778976 0 0.0308736 0.0333953 0.00997419 0.086271C-0.0109253 0.139147 0.00213705 0.197588 0.0387111 0.236549L5.40987 5.95826C5.436 5.98609 5.46996 6 5.50131 6C5.53266 6 5.56923 5.98609 5.59274 5.95826L10.9613 0.239332C10.9979 0.203154 11.0109 0.14193 10.99 0.086271C10.9691 0.0333953 10.9221 0 10.8699 0H0.130146Z"
      fill={stroke}
    />
  </SvgIcon>
);

export default SelectDownArrowSvg;
