import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import {
  DigitalTwinInnerBox,
  OuterBox,
  CustomTabSectionOutline,
} from "../../Components/CustomBox";
import PageHeaderSection from "../../PagesContent/DigitalTwinV2/PageHeaderSection/PageHeaderSection";
import { notification } from "antd";
import CustomIcon from "../../Components/CustomIcon";
import TabSection from "../../PagesContent/DigitalTwinV2/TabSection/TabSection";
import { useTranslation } from "react-i18next";

const Context = React.createContext({
  name: "Default",
});

function DigitalTwinMain(props) {

  const {t} =  useTranslation(['digital_twin'])

  const {
    pagesContent,
    dateFilters,
    batteryID,
    deviceID,
    hierarchy,
    hierarchySequence,
    alertsCountHierarchical,
    alertList,
    getAlerts,
    alertsFilters,
    alertsSorts,
    isAlertFetching,
    setDateFilters,
    alertsForAnnotation,
    liveMetricDataForBattery,
    hierarchyLiveMetricData,
    ...other
  } = props;
  
  const allTabs = [
    {
      id: 1,
      name: t('digital_twin:battery_vitals', "Battery Vitals"),
      disabled: false,
      slug: "batteryVitals",
    },
    {
      id: 2,
      name: t('digital_twin:charger_vitals', "Charger Vitals"),
      disabled: false,
      slug: "chargerVitals",
    },
    {
      id: 3,
      name: t('digital_twin:alert_log', "Alert Log") ,
      disabled: true,
      slug: "alertLog",
    },
    {
      id: 4,
      name: t('digital_twin:bms_error_log', "BMS Error Log"),
      disabled: true,
      slug: "bmsErrorLog",
    },
    {
      id: 5,
      name: t('digital_twin:health', "Health"),
      disabled: false,
      slug: "health",
    },
  ];
  const tabsForOrganization = allTabs.filter(tab => pagesContent?.metaInfo?.tabsToShow?.includes(tab.slug))
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      message: (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 500,
              color: "#FFF",
              lineHeight: "32px",
              textAlign: "right",
              marginRight: "26px",
            }}
          >
            {text}
          </Typography>
          <CustomIcon name={"toastInfo"} />
        </Box>
      ),
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "32px",
        width: "360px",
        height: "64px",
        padding: "22px 16px 16px 16px",
        borderRadius: "3px",
        backgroundColor: "#42526E",
        boxShadow: "0px 8px 12px 0px rgba(9, 30, 66, 0.15)",
      },
      icon: <></>,
      closeIcon: <></>,
      placement,
      duration: 1,
    });
  };

  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  return (
    <OuterBox style={{ ...props.style, paddingBottom: 0 }}>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <DigitalTwinInnerBox>
          <CustomTabSectionOutline>
            <PageHeaderSection
              batteryID={batteryID}
              deviceID={deviceID}
              batteryLevelAlertCount={
                alertsCountHierarchical?.data?.totalAlertsCount
              }
              batteryIconStyle={{
                marginTop: "10px",
              }}
            />
          </CustomTabSectionOutline>
          <TabSection
            pagesContent={pagesContent}
            tabs={tabsForOrganization}
            dateFilters={dateFilters}
            hierarchy={hierarchy}
            batteryID={batteryID}
            alertsCountHierarchical={alertsCountHierarchical}
            alertList={alertList}
            getAlerts={getAlerts}
            alertsFilters={alertsFilters}
            alertsSorts={alertsSorts}
            isAlertFetching={isAlertFetching}
            setDateFilters={setDateFilters}
            alertsForAnnotation={alertsForAnnotation}
            openNotification={openNotification}
            liveMetricDataForBattery={liveMetricDataForBattery}
            hierarchyLiveMetricData={hierarchyLiveMetricData}
            hierarchySequence={hierarchySequence}
          />
        </DigitalTwinInnerBox>
      </Context.Provider>
    </OuterBox>
  );
}

export default DigitalTwinMain;
