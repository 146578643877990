import React from "react";
import { Typography } from "@mui/material";
import { getMetricTimeStamp } from "../../../../../Helper/DatePicker/DateConverter";
import { FilterBoxTooltip } from "../../TableStyles";


const Timestamp = ({ config, record }) => {
	const value = record?.[config?.dataIndex];
	let dateFormat = config.dateFormat ? config.dateFormat : "DD MMM'YY hh:mm A"
	let tooltipDateFormat = config.tooltipDateFormat ? config.tooltipDateFormat : "DD MMM'YY hh:mm A"

	let columnDate = value ? getMetricTimeStamp(value, dateFormat) : "Not Available"
	let tooltipDate = value ? getMetricTimeStamp(value, tooltipDateFormat) : "Not Available"

	return (
		<FilterBoxTooltip title={tooltipDate} placement={'bottom'}>
			<Typography variant="fw400s14lh24c3D4B5A">{columnDate}</Typography>
		</FilterBoxTooltip>
	);
};

export default Timestamp
