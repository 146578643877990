import { SvgIcon } from "@mui/material";
import React from "react";

const ChargerPlugSvg = ({ 
  width = "21px", 
  height = "21px", 
  viewBox = "0 0 21 21", 
  fill = "none", 
  stroke = "", 
  strokeWidth = 0.35,
  ...props 
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    sx={{ fill, display: "block" }}
    {...props}
  >
    <path 
      d="M14.4721 5.29869H12.736V1.13202C12.736 0.902866 12.6457 0.68411 12.4808 0.517444C12.3158 0.354248 12.0971 0.263977 11.8679 0.263977C11.3888 0.263977 10.9999 0.652866 10.9999 1.13202V5.29869H5.44431V1.13202C5.44431 0.652866 5.05542 0.263977 4.57627 0.263977C4.34711 0.263977 4.12835 0.354255 3.96169 0.519177C3.79849 0.684108 3.70822 0.902866 3.70822 1.13202V5.29869H1.97211C1.49295 5.29869 1.10406 5.68758 1.10406 6.16673V6.86118C1.10406 7.34033 1.49295 7.72922 1.97211 7.72922H2.31933V13.2797C2.31933 15.4828 4.111 17.2746 6.31244 17.2779V17.6685C6.31244 18.3143 6.83849 18.8404 7.48431 18.8404H7.52771V20.2293C7.52771 20.3248 7.60584 20.4029 7.70132 20.4029H8.74299C8.83848 20.4029 8.9166 20.3248 8.9166 20.2293V18.8404H8.96C9.60583 18.8404 10.1319 18.3143 10.1319 17.6685V17.2779C12.3332 17.2744 14.125 15.4828 14.125 13.2797V7.72922H14.4722C14.9514 7.72922 15.3402 7.34033 15.3402 6.86118V6.16673C15.3402 5.68758 14.9512 5.29869 14.4721 5.29869Z"
      fill="#444B51"
      stroke="#444B51"
    />
    <path 
      d="M7.0085 13.1048H8.04261L6.84358 12.8197C6.82622 12.8718 6.8349 12.9308 6.86789 12.9759L6.82118 13.0101C6.82118 13.0101 6.82118 13.0101 6.82118 13.0101C6.86525 13.0704 6.93481 13.1048 7.0085 13.1048Z"
      fill="#444B51"
      stroke="#444B51"
      strokeWidth="0.115709"
    />
  </SvgIcon>
);

export default ChargerPlugSvg;
