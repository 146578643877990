import React, { useEffect, useState } from 'react';
import { Popover, Typography, Box } from '@mui/material';
import FilterBody from './FilterBody';
import { Close } from '@mui/icons-material';

import { CancelFormActionButton, ActiveButtonBadge, FilterBoxContainer, FilterBoxTooltip, PrimaryFormActionButton, PrimaryIconButton, PrimaryTextButton } from '../TableStyles';

import { changeDateFormat } from '../../../../Helper/DatePicker/DateConverter';
import RecordCountBox from '../../../../Components/RecordCountBox';

import { FilterIconSvg } from '../../Icons/index'
import { useTranslation } from 'react-i18next';


const styles = {
	popoverPaper: {
		width: 554,
		borderRadius: '10px',
		backgroundColor: '#FFFFFF',
		boxShadow: '-2px -2px 4px 0px rgba(151, 151, 151, 0.25), 2px 2px 4px 4px rgba(151, 151, 151, 0.25)',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid #E0E0E0',
		padding: '12px 16px 12px 12px',
		justifyContent: 'space-between'
	},
	headerText: {
		fontFamily: 'Roboto',
		fontWeight: 700,
		fontSize: '14px',
		lineHeight: '24px',
		color: '#3D4B5A',
		px: 1,
	},
	footer: {
		p: 1,
		borderTop: '1px solid #E0E0E0',
		display: 'flex',
		justifyContent: 'flex-end',
	},
};

const FilterBox = ({ label, valuesText, onClick, onRemove, isActive, tooltipTitle = "No title" }) => {
	const [isHovered, setIsHovered] = useState(false)
	let hasActive = isActive || isHovered

	return (
		<FilterBoxTooltip title={tooltipTitle} placement={'bottom'}>
			<FilterBoxContainer
				onClick={onClick}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				hasActive={hasActive}>
				{label && (
					<Typography variant={hasActive ? "fw500s12lh246D72F6" : "fw500s12lh24c808080"}>
						{label}:
					</Typography>
				)}

				<Typography variant={hasActive ? "fw500s12lh24c4D44D8" : "fw500s12lh24c474747"}>
					{valuesText}
				</Typography>

				{onRemove && <Close onClick={onRemove} />}
			</FilterBoxContainer>
		</FilterBoxTooltip>
	)
};

const FilterList = ({ selectedFilters, filters, onFilterClick, onRemove, activeFilterKey }) => {
	const MAX_VISIBLE_FILTERS = 4;
	if (!selectedFilters || selectedFilters.length < 1) return null;

	const filterEntries = Object.entries(selectedFilters);
	const visibleFilters = filterEntries.slice(0, MAX_VISIBLE_FILTERS);
	const remainingFilters = filterEntries.slice(MAX_VISIBLE_FILTERS);
	const remainingFilterKeys = remainingFilters.map(([key]) => key);
	const MAX_LENGTH = 28;

	const formatDate = (dateStr) => {
		return changeDateFormat(dateStr)
	};

	const formatFilterValue = (value, filterMeta) => {
		let rawValue = filterMeta?.values?.find((option) => option.id === value)?.value || value;

		if (filterMeta?.displayType === "dateCalender") {
			try {
				const dates = JSON.parse(rawValue);
				return `${formatDate(dates.startDate)} - ${formatDate(dates.endDate)}`;
			} catch {
				console.warn("Invalid date format", rawValue);
			}
		}

		return rawValue;
	};

	const formatTooltipText = ([key, values]) => {
		const filterMeta = filters[key];
		const label = filterMeta?.label || key;
		const formattedValues = values.map((v) => formatFilterValue(v, filterMeta)).join(", ");
		return `${label}: ${formattedValues}`;
	};

	return (
		<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
			{visibleFilters.map(([key, values]) => {
				const filterMeta = filters[key];
				const label = filterMeta?.label || key;
				const formattedValues = values.map((v) => formatFilterValue(v, filterMeta));

				let displayText = formattedValues[0];
				if (`${label}: ${displayText}`.length > MAX_LENGTH) {
					displayText = `${displayText.substring(0, MAX_LENGTH - label.length)}...`;
				}

				return (
					<FilterBox
						key={key}
						label={label}
						valuesText={formattedValues.length > 1 ? `${displayText} +${formattedValues.length - 1}` : displayText}
						onClick={(event) => onFilterClick(event, key)}
						onRemove={(event) => onRemove(event, [key])}
						isActive={activeFilterKey === key}
						tooltipTitle={formatTooltipText([key, values])}
					/>
				);
			})}

			{remainingFilterKeys.length > 0 && (
				<FilterBox
					key="remaining"
					valuesText={`+${remainingFilters.length}`}
					onClick={(event) => onFilterClick(event, remainingFilterKeys[0])}
					isActive={activeFilterKey === remainingFilterKeys[0]}
					onRemove={(event) => onRemove(event, remainingFilterKeys)}
					tooltipTitle={
						<>
							{remainingFilters.map(formatTooltipText).map((line, index) => (
								<React.Fragment key={index}>
									{line}
									<br />
								</React.Fragment>
							))}
						</>
					}
				/>
			)}
		</Box>
	);
};

export default function TableFilters({
	filters = {},
	selectedFilters = {},
	setSelectedFilters = () => { }
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const { t } = useTranslation(['common', 'generic_table']);
	const open = Boolean(anchorEl);
	const id = open ? 'filter-popover' : undefined;
	const [newFilters, setNewFilters] = useState(selectedFilters)
	const [activeFilterKey, setActiveFilterKey] = useState(null);

	let newFilterLength = newFilters ? Object.values(newFilters).flat().length : 0
	let selectedFilterLength = selectedFilters ? Object.values(selectedFilters).flat().length : 0

	useEffect(() => {
		setNewFilters(selectedFilters)
	}, [selectedFilters])

	const handleClick = (event, key = null) => {
		setAnchorEl(event.currentTarget);
		setActiveFilterKey(key);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setActiveFilterKey(null);
	};

	const onApplyFilters = () => {
		setSelectedFilters(newFilters)
		setActiveFilterKey(null);
		setAnchorEl(null)
	}

	const removeFilter = (event, keys) => {
		event.stopPropagation();
		const updatedFilters = { ...selectedFilters };
		keys.forEach((key) => {
			delete updatedFilters[key];
		});
		setSelectedFilters(updatedFilters);
	};

	const handleReset = () => {
		setNewFilters({})
	}

	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			<ActiveButtonBadge
				variant="dot"
				color="secondary"
				overlap="circular"
				invisible={!selectedFilterLength}>
				<PrimaryIconButton onClick={handleClick} size="small" active={open}>
					<FilterIconSvg />
				</PrimaryIconButton>
			</ActiveButtonBadge>

			<FilterList
				selectedFilters={selectedFilters}
				filters={filters}
				onFilterClick={handleClick}
				onRemove={removeFilter}
				activeFilterKey={activeFilterKey}
			/>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				slotProps={{ paper: { sx: styles.popoverPaper } }}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box sx={styles.header}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<FilterIconSvg sx={{ color: '#5E758D' }} />
							<Typography sx={{ mx: 1 }} variant="fw700s14lh24c3D4B5A"> {t('generic_table:filters.title')} </Typography>
							<RecordCountBox fontVariant={"fw600s10lh24c6D72F6"} count={newFilterLength} />
						</Box>

						{newFilterLength > 0 && <PrimaryTextButton onClick={handleReset}
							sx={{ fontWeight: 500 }}> {t('generic_table:filters.reset')}</PrimaryTextButton>
						}
					</Box>

					<FilterBody
						filters={filters}
						newFilters={newFilters}
						setNewFilters={setNewFilters}
						activeFilterKey={activeFilterKey}
					/>
					<Box sx={styles.footer}>
						<CancelFormActionButton onClick={handleClose}> {t('actions.cancel')} </CancelFormActionButton>
						<PrimaryFormActionButton onClick={onApplyFilters} disabled={newFilterLength < 1} >
							{t('actions.apply')}
						</PrimaryFormActionButton>
					</Box>
				</Box>
			</Popover>
		</Box>
	);
}