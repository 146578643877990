import {
  convertZonetoUTCEnd,
  convertZonetoUTCStart,
} from "../Helper/DatePicker/DateConverter";
import { GetData } from "./Functions/SettingData";
import qs from "qs";

async function getDigitalTwinList(data) {
  let rawJson = {
    ...data.filters,
    deviceID: data?.batteryID,
    searchID: data?.searchID,
    startDate: convertZonetoUTCStart(data?.startDate),
    endDate: convertZonetoUTCEnd(data?.endDate),
    pageCount: data.rowsPerPage,
    sortBy: data?.sortBy ? data?.sortBy : 'addedOn',
    order: data?.orderBy ? data?.orderBy : 'desc',
  };
  var config = {
    method: "get",
    url: `/api/digital_twin_list/${data.pageNumber}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let response = GetData(config, true);
  return response;
}

async function getDigitalTwinData(data) {
  var config = {
    method: "post",
    url: `/api/digital_twin/actions`,
    headers: {
      "Content-Type": "application/json",
    },
    data
  };
  
  let response = GetData(config, true);
  
  return response;
}


async function getDigitalTwinConfigList(data) {
  let rawJson = {
    ...data.filters,
    pageCount: data?.rowsPerPage,
    sortBy: data?.sortBy ? data?.sortBy : 'id',
    order: data?.orderBy ? data?.orderBy : 'desc',
  };
  var config = {
    method: "get",
    url: `/api/battery_config_list/${data.pageNumber}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let response = GetData(config, true);
  return response;
}

async function addDigitalTwin(data) {
  let json = data;
  var config = {
    method: "post",
    url: `/api/digital_twin_list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: json,
  };
  let response = GetData(config, true);
  return response;
}

async function updateDigitalTwin(data) {
  let json = data;
  var config = {
    method: "put",
    url: `/api/digital_twin_list/${data?.id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: json,
  };
  let response = GetData(config, true);
  return response;
}

async function addConfig(data) {
  let json = data;
  var config = {
    method: "post",
    url: `/api/battery_config_list`,
    headers: {
      "Content-Type": "application/json",
    },
    data: json,
  };
  let response = GetData(config, true);
  return response;
}

async function updateConfig(data) {
  let json = data;
  var config = {
    method: "put",
    url: `/api/battery_config_list/${data?.id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: json,
  };
  let response = GetData(config, true);
  return response;
}

async function getConfigList() {
  var config = {
    method: "get",
    url: `/api/battery_config_form_dropdown`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      filters: ["configName", "fleetName"],
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let response = GetData(config, true);
  return response;
}

async function getConfigFilterValues() {
  var config = {
    method: "get",
    url: `/api/battery_config_form_dropdown`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      // filters: ["packOEM", "iotOEM", "bmsOEM"],
      filters: [
        "fleetName",
        "buildingBlockType",
        "applicationOEM",
        "iotOEM",
        "bmsOEM",
        "packOEM",
        "cellOEM",
        "cellModelNumber",
        "cellChemistry",
        "formFactorCell",
        "configName",
      ],
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let response = GetData(config, true);
  return response;
}

async function getDigitalTwinListSearch(search, limit) {
  let newColumnName = "deviceID";

  var rawJson = {
    columnName: newColumnName,
    columnSearch: search,
    columnLimit: limit,
  };
  var config = {
    method: "get",
    url: `/api/digital_twin_list_search`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function addOrganization(data) {
  let json = data;
  var config = {
    method: "post",
    url: `/api/new_fleet`,
    headers: {
      "Content-Type": "application/json",
    },
    data: json,
  };
  let response = GetData(config, true);
  return response;
}


async function getDigitalTwinConfigAlerts(params) {
  let rawJson = {
    ...params,
  };
  var config = {
    method: "get",
    url: `/api/alert/config_alerts`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let response = GetData(config, true);
  console.log("Response set alerts", response)
  return response;
}


async function updateDigitalTwinConfigAlerts(data) {
  let json = data;
  var config = {
    method: "post",
    url: `/api/alert/update_config_alerts`,
    headers: {
      "Content-Type": "application/json",
    },
    data: json,
  };
  let response = GetData(config, true);
  return response;
}

export {
  getDigitalTwinList,
  getDigitalTwinData,
  getDigitalTwinConfigList,
  addDigitalTwin,
  updateDigitalTwin,
  addConfig,
  updateConfig,
  getConfigList,
  getConfigFilterValues,
  getDigitalTwinListSearch,
  addOrganization,
  getDigitalTwinConfigAlerts,
  updateDigitalTwinConfigAlerts
};
