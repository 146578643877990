import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useDispatch } from "react-redux";
import Events from "../../Analytics/Events";
import { normalizeKey } from "../../Helper/Common/common_helpers";
import { useTranslation } from "react-i18next";

export default function AlertType(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation(['common', 'alerts'])


  const ChangeTheSelect = (event) => {
    props.handleSetFilter(
      props.filter.itemName,
      props.filter.items[event.target.value].value
    );
    Events(
      "clicked " + props.pageName + " " + props.filter.itemName + " filter"
    );
  };

  const label = props.filter.items?.find(item => item.key === props.selected)?.value

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "white",
        }}
      >
        <FormControl size="small" variant="standard">
          <Select
            variant="standard"
            sx={{
              ":before": { borderBottomColor: "transparent" },
              ":after": { borderBottomColor: "transparent" },
              "&:hover:after": {
                borderBottomColor: "#ececec",
              },
              "&.MuiSelect-icon": {
                size: 20,
              },
            }}
            labelId="demo-select-small"
            MenuProps={{
              style: { zIndex: 10001, marginTop: "1px" },
            }}
            // id="overflow-text"
            value={props.selected}
            name={props.filter.itemName}
            onChange={ChangeTheSelect}
            renderValue={(selected) => {
              return (
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    mt: 1,
                    color: ThemeProperties.purple,
                  }}
                >
                  {t(`alerts:alert_types.${ normalizeKey(label)}` , label)}
                </Typography>
              );
            }}
          >
            {props.filter.items.map((item, index) => {
              return (
              <MenuItem value={index} key={index}>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 500, fontFamily: "Roboto" }}
                >
                  {t(`alerts:alert_types.${normalizeKey(item.value)}` , item.value)}
                </Typography>
              </MenuItem>
            )})}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
