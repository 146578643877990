import { SvgIcon } from "@mui/material";
import React from "react";

const CheckedBoxSvg = ({ 
  fill = "#6D72F6", 
  stroke = "#6D72F6",
  strokeWidth = 1,  
  viewBox = "0 0 14 14", 
  width = "14",
  height= "14",
  ...props 
}) => (

  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width = {width}
    height = {height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    sx={{ fill, display: "block"}}
    {...props}
  >
    {  console.log({props})}
    <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" />
    <path d="M4 7L6.146 9.146a0.5 0.5 0 0 0 .708 0L11 5" stroke="white" />
  </SvgIcon>
);

export default CheckedBoxSvg;
