import React from "react"
import BatteryHealth from "../../../Assets/Icons/BatteryHealth/HeartWithBattery.png"

const HeartWithBattery = ({
  width = "19px",
  height= "19px",
  ...props
}) => {
  return (
    <img 
      src={BatteryHealth} 
      alt="Description" 
      style={{ width, height }}
    />
  )
}


export default HeartWithBattery
