import { Box, styled, Typography } from "@mui/material";
import React from "react"


const CountBox = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0, 1),
    display: 'inline-block',
  }));

export default function RecordCountBox({count, fontVariant}) {
    if (!count) {
        return null 
    }
    return (
        <CountBox>
            <Typography variant= {fontVariant || "fw600s12lh24c6D72F6"}> {count} </Typography>
        </CountBox>
    )
}