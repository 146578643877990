import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Popover, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AlertConfigurationMap from "./AlertConfigurationMap";
import { encryptTheParams } from "../../../../../Helper/QueryParams/EncryptDecrypt";


const ActionMenuItem = styled(MenuItem)(({ theme }) => ({
  px: 12,
  py: 4,
  marginBottom: "6px",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "12px",
  color: "#3D4B5A",
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  lineHeight: "24px",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    fontWeight: 500,
    color: "#6D72F6",
    backgroundColor: "#F4F5FF",
  },
}));


const styles = {
    popoverPaper: {
        width: '15%',
        p: 1.5,
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 4px 0px #C0C0C052',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '8px',
        justifyContent: 'space-between'
    },
    headerText: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#3D4B5A',
        px: 1,
    },
    footer: {
        p: 0,
        display: 'flex',
        justifyContent: 'flex-end',
    },
};


const ActionMenu = ({ actions, record, config }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editTwin = () => {
    let temp = {
        id: record?.id,
        isEdit: true,
        batteryID: record?.batteryID,
        deviceID: record?.deviceID,
    };
    encryptTheParams(temp, navigate, false, "/DigitalTwin/add-battery");
  }

  const editTwinConfig = () => {
    let temp = {
        configID: record?.id,
        isEdit: true,
        configName: record?.configName,
        application: record?.application,
      };
      encryptTheParams(temp, navigate, false, "/DigitalTwin/add-config");
  }

  const handleMenuItemClick = (action) => {
    if (action.type == 'edit-twin-config') {
        editTwinConfig();
    }

    if(action.type == 'edit-twin-info') {
        editTwin();
    }

    
    action.onClick && action.onClick();
    handleClose();
  }

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <MoreVertIcon />
      </IconButton>
      <Popover 
        id={id}
        anchorEl={anchorEl} 
        open={open} 
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        slotProps={{ paper: { sx: styles.popoverPaper } }}>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={styles.header}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SettingsRoundedIcon sx={{ fontSize: "16px", color: '#5E758D' }} />
                    <Typography sx = {{mx:1}} variant="fw700s12lh24c3D4B5A"> Row Settings </Typography>
                </Box>
            </Box>

            <Box>
                {actions.map((action, index) =>
                    action.type === 'config-alert-settings' ? (
                    <AlertConfigurationMap
                        config = {record}
                        trigger={
                        <ActionMenuItem key={index}>
                            {action.label}
                        </ActionMenuItem>} 
                    />
                    ) : (
                    <ActionMenuItem key={index} onClick={() => handleMenuItemClick(action)}>
                        {action.label}
                    </ActionMenuItem>
                    )
                )}
            </Box>
            
        </Box>

      </Popover>
    </>
  );
};

export default ActionMenu;
