import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, FilterAllSelectedText, FilterClearAllButton, FilterDoneButtonBox, FilterMenuOptionText, FilterPillCrossButtonBox, FilterSearchBox, FilterSectionFormControlLabel, FilterSectionFormGroup, FilterTitleText, PillBox, PillText, SelectedOptionPillsContainer } from "../PagesContent/AlertDrillDown/Components/StyledComponent";
import { FlexCenterBox, FlexSpaceBetweenBox } from "./CustomBox";
import { MoonLoader } from "react-spinners";
import CloseIcon from '@mui/icons-material/Close';
import { getTruncatedName } from "../Helper/Formatters/TextFormatter";
import { Tooltip } from "antd";
import CustomIcon from "./CustomIcon";

function getArrayOfValues(arr) {
  let temp = [];
  arr.map((item) => {
    temp.push(item.id);
  });
  return temp;
}

const AccordionTypeMultiSelect = ({ name, filterConfig = {}, filterOptions = [], optionValue = [], isExpanded, onAccordionChange, handleSetFilter = () => {} }) => {
  const [selected, setSelected] = useState(optionValue);
  const [isHover, setIsHover] = useState(false);
  let totalAvailableWidthOfPillContainer = 216
  const [isAllSelected, setIsAllSelected] = useState(selected?.length === filterOptions?.length);
  const [optionsToShow, setOptionsToShow] = useState(filterOptions)
  const handleAccordionChange = (panel) => (event, newExpanded) => {
    onAccordionChange(newExpanded ? panel : false);
  };
  const onOptionChanged = (checked, value) => {
    let newOptions = [...selected]
    if (value === "All") {
      newOptions = checked ? getArrayOfValues(filterOptions) : [];
      setSelected(newOptions)
      return;
    }

    if (checked){
      newOptions.push(value)
    } else {
      newOptions = newOptions.filter(option => option !== value)
    }
    let uniqueSelectedOptions = new Set(newOptions);
    setSelected([...uniqueSelectedOptions])
  }

  const unselectFilterOption = (event, key) => {
    event.stopPropagation()
    let newSelected = selected.filter(option => option!== key)

    if (newSelected.length === 0) {
      newSelected = getArrayOfValues(filterOptions)
    }

    setSelected(newSelected)
    handleSetFilter(filterConfig.key, newSelected)
  }

  const handleClickClearAll = (event) => {
    event.stopPropagation()
    const newValue = getArrayOfValues(filterOptions)
    setSelected(newValue)
    handleSetFilter(filterConfig.key, newValue)
  }

  const handleDoneButtonClick = () => {
    if (!selected.length) {
      return
    }
    
    onAccordionChange(false)
    handleSetFilter(filterConfig.key, selected)
  }

  const findTotalWidthOfPill = (label) => {
    const singleCharacterAvgWidth = 7
    const pillWidthExcludingCharacters = 30
    const widthOfLabel = label.length * singleCharacterAvgWidth
    const marginWidth = 4

    const totalWidthOfPill = widthOfLabel + pillWidthExcludingCharacters + marginWidth
    return totalWidthOfPill
  }

  const handleSearchChange = (event) => {
    if (event.target.value.trim() === "") {
      setOptionsToShow(filterOptions)
    }
    const newOptions = filterOptions.filter(option => option.value.toLowerCase().includes(event.target.value.trim().toLowerCase()))
    setOptionsToShow(newOptions)
  }

  useEffect(() => {
    setOptionsToShow(filterOptions)
  }, [filterOptions])

  useEffect(() => {
    setSelected(optionValue);
  }, [optionValue, isExpanded]);
  
  useEffect(() => {
    setIsAllSelected(selected?.length === filterOptions?.length)
  }, [selected]);  

  return <Accordion expanded={isExpanded} onChange={handleAccordionChange(name)}>
    <AccordionSummary onMouseEnter={(e) => {setIsHover(true)}} onMouseLeave={() => {setIsHover(false)}} isExpanded={isExpanded} isHover={isHover} selected={optionValue.length} filterOptions={filterOptions.length}>
      <FlexSpaceBetweenBox>
        <FilterTitleText color={isExpanded || isHover ? "#6D72F6" : '#1C1C1C'}>{filterConfig.label}</FilterTitleText>
        {optionValue.length !== filterOptions.length ? 
          <FilterClearAllButton
            color={isExpanded ? "#6D72F6" : "#828A92"}
            onClick={handleClickClearAll}
          >
            {`Clear (${optionValue.length})`}
          </FilterClearAllButton>
          : <></>
        }
      </FlexSpaceBetweenBox>
      {isExpanded
        ? <></>
        : optionValue.length === filterOptions.length
          ? <FilterAllSelectedText>{filterConfig?.selectAllText || "All Selected"}</FilterAllSelectedText> 
          : <SelectedOptionPillsContainer>
            {optionValue.map((optionKey, index) => {
              if (index === 0) {
                totalAvailableWidthOfPillContainer = 216
              }

              if (totalAvailableWidthOfPillContainer <= 30) {
                return <></>
              }

              let label, widthOfPill
              label = filterOptions.find(item => item.id === optionKey)?.value
              widthOfPill = findTotalWidthOfPill(label)
              
              if (totalAvailableWidthOfPillContainer - widthOfPill <= 30) {
                if (index !== 0) {
                  label = `+${optionValue?.length - index}`
                  totalAvailableWidthOfPillContainer -= widthOfPill
                  
                  return <PillBox>
                    <PillText>{label}</PillText>
                  </PillBox>
                }

                let fullLabel = filterOptions.find(item => item.id === optionKey)?.value
                label = getTruncatedName(
                  fullLabel,
                  31,
                  15,
                  13
                )

                if (optionValue.length > 1) {
                  label = getTruncatedName(
                    fullLabel,
                    20,
                    10,
                    7
                  )
                }
              }

              widthOfPill = findTotalWidthOfPill(label)
              totalAvailableWidthOfPillContainer -= widthOfPill
              
              return <PillBox>
                <PillText>{label}</PillText>
                <FilterPillCrossButtonBox onClick={(event) => {unselectFilterOption(event, optionKey)}}>
                  <CloseIcon fontSize="14" sx={{ color: "#6D72F6" }}/>
                </FilterPillCrossButtonBox>
              </PillBox>
            })}
          </SelectedOptionPillsContainer>
      }
    </AccordionSummary>
    <AccordionDetails>
      {filterOptions?.length > 5 && <FilterSearchBox
        endAdornment={<CustomIcon name="filterSearch"/>}
        inputProps={{
          "className": "filter-search-input",
          "placeholder": filterConfig?.placeholder || "",
          "style": {  
            paddingTop: "5px",
            paddingBottom: "5px", 
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 400,
            color: "#444B51"
          },
          'aria-label': 'search',
        }}
        onChange={handleSearchChange}
      />}
      {filterOptions?.length ? <>
        <FilterSectionFormGroup>
          {optionsToShow.length === filterOptions.length && <FilterSectionFormControlLabel control={<Checkbox
            disableRipple
            icon={<CustomIcon name="uncheckedBox" stroke="#444B51"/>}
            checkedIcon={<CustomIcon name="checkedBox" />}
            onChange={(e) => onOptionChanged(e.target.checked, 'All')}
            checked={isAllSelected}/>}
            label={<FilterMenuOptionText color={isAllSelected ? '#6D72F6' : '#444B51'}>Select All</FilterMenuOptionText>}
          />}
          {optionsToShow?.map(option => <FilterSectionFormControlLabel
            control={
              <Checkbox 
                disableRipple
                icon={<CustomIcon name="uncheckedBox" />}
                checkedIcon={<CustomIcon name="checkedBox" />}
                checked={selected.includes(option.id)} onChange={(e) => onOptionChanged(e.target.checked, option.id)}
              />
            } 
            label={
              option.value.length > 22
              ? <Tooltip placement="top" title={option.value}>
                  <FilterMenuOptionText>{getTruncatedName(option.value, 22, 10, 9)}</FilterMenuOptionText>
                </Tooltip>
              : <FilterMenuOptionText>{option.value}</FilterMenuOptionText>
            }
            />
          )}
        </FilterSectionFormGroup>
        <FilterDoneButtonBox onClick={handleDoneButtonClick} isEnabled={selected.length} >Done</FilterDoneButtonBox>
      </> : <FlexCenterBox><MoonLoader size={20}/></FlexCenterBox>}
    </AccordionDetails>
  </Accordion>
}

export default AccordionTypeMultiSelect
