import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { SetBatteryHealthCycleAge } from "../../Actions/BatteryHealth";
import { FormGroup, Tooltip } from "@mui/material";
import Loader from "../../Helper/Loaders/Loader"
import { EqCycleSwitchBox, ShowEqCycleText, SOHChartContainer, EqCycleInfoIcon, EqCycleInfoTooltip } from "./Components/StyledComponent";
import { FlexStartBox, } from "../../Components/CustomBox";
import Events from "../../Analytics/Events";
import CODE from "../../Static/Constants/StatusCodes";
import ApexScatterPlusLine from "../../Charts/Mixed/ApexScatterPlusLine";
import { getMarkerColorForSOHChart } from "../../Helper/BatteryHealth/BatteryHealthHelper";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import CustomSwitch from "../../Components/CustomSwitch";
import CardWithHeader from "../../Features/Cards/CardWithHeader";
import { useNotification } from "../../Hooks/useNotification";
import CustomIcon from "../../Components/CustomIcon"
import { useTranslation } from "react-i18next";

const SOHScatterChartSection = ({
  pagesContent,
  batteriesData
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation([`health`])
  const { openNotification, closeNotification } = useNotification();
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })
  const cycleAge = useSelector((state) => state.BatteryHealthCycleAge.value)
  const cycleTypeText = pagesContent?.translations?.eqCycle || "Eq. Cycle"
  const cycleInfoText = pagesContent?.translations?.eqCycleInfo || "Partial charge-discharge cycle converted to fraction of a complete charge-discharge cycle, accumulated over time."
  const shouldAllowDownloads = pagesContent?.disableDownloads ? false : true

  const generateCsvData = () => {
    setCsvData({
      data: [],
      responseStatus: { code: CODE.LOADING }
    })
    let data = []
    batteriesData.data.forEach((item) => {
      data.push({
        "Serial Number": item.deviceID,
        "Age (months)": item.age !== null ? (item.age / 30).toFixed(2) : "",
        [cycleTypeText]: item.eqCycleEst?.toFixed(2),
        "SOH (%)": item.sohEst?.toFixed(2),
        "RUL (months)": item.rulAge !== null ? (item.rulAge / 30).toFixed(0) : "",
        "Initial Capacity (Ah)": item.initialCapacity?.toFixed(2),
        "Present Capacity (Ah)": item.presentCapacity?.toFixed(2)
      })
    })
    const structuredCsvData = {
      data: data,
      responseStatus: {
        code: CODE.SUCCESS
      }
    }
    setCsvData(structuredCsvData)
  }
  
  const handleToggleCycleAge = (checked) => {
    Events("Battery Health: Toggled Show Eq. Cycle Fleet battery Health")
    dispatch(SetBatteryHealthCycleAge(checked))

    updateQueryParams(navigate, {
      cycleAge: checked
    })
  }

  return (
    <CardWithHeader
      onMouseEnter={() => Events("Battery Health: Hover on State of Health (SOH) of all batteries in the fleet")}
      title={t('health:soh_scatter_chart.title')} 
      subtitle={t('health:soh_scatter_chart.subtitle')} 
      iconElement={<CustomIcon name="healthWithBattery"></CustomIcon>}
      pageLabel="Battery Health"
      showCSVDownload={shouldAllowDownloads}
      csvData={csvData}
      csvName={"BatteriesHealth"}
      openNotification={openNotification}
      closeNotification={closeNotification}
      generateCsvData={generateCsvData}
    >
      <SOHChartContainer>
        <FormGroup>
          <FlexStartBox>
            <EqCycleSwitchBox
              control={
                <CustomSwitch
                  checked={cycleAge}
                  onChange={handleToggleCycleAge}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
              }
              label={<ShowEqCycleText>Show {cycleTypeText}</ShowEqCycleText>}
            />
            <Tooltip
              placement={"right"}
              title={
                <EqCycleInfoTooltip>
                  {cycleInfoText}
                </EqCycleInfoTooltip>
              }
              zIndex={2000}
            >
              <EqCycleInfoIcon />
            </Tooltip>
          </FlexStartBox>
        </FormGroup>
        {[CODE.SUCCESS, CODE.NODATA].includes(batteriesData?.responseStatus?.code) ? (
          <ApexScatterPlusLine
            scatter={batteriesData.scatterChartData}
            batteryID={batteriesData.batteriesIds}
            xMax={batteriesData.xMax}
            xMin={batteriesData.xMin}
            yMax={batteriesData.yMax}
            yMin={batteriesData.yMin}
            xAxisTitle={cycleAge ? (pagesContent?.translations?.eqCycle || "Eq. Cycle") : t('health:age')}
            xAxisLabelColor={ThemeProperties.xAxisLabelColor}
            cycleAge={cycleAge}
            cycleAgeTypeText={cycleTypeText}
            getMarkerColor={getMarkerColorForSOHChart}
            navigate={navigate}
          />
        ) : (
          <Loader />
        )}
      </SOHChartContainer>
    </CardWithHeader>
  );
}

export default SOHScatterChartSection
