import React, { useEffect, useState } from "react";
import { Table, Pagination, ConfigProvider } from "antd";
import ReactDragListView from "react-drag-listview";
import Loader from "../../../Helper/Loaders/Loader";
import NoData from "../../../Helper/Loaders/NoData";
import { styled } from "@mui/material";


const StyledTableContainer = styled("div")({
  
  ".ant-table-row .ant-table-cell": {
     padding: '10px 16px'
  },

  ".ant-table-thead .ant-table-cell": {
    paddingTop: '8px !important',
    paddingBottom: '8px !important'
  },

  ".paginationContainer": {
    marginTop: '8px'
  },

  ".ant-table-row:hover > .ant-table-cell": {
    backgroundColor: "#FBFBFB !important", // Replace with your desired hover color
  },


    /* Change selected row highlight color */
    ".ant-table-row.ant-table-row-selected >.ant-table-cell": {
      backgroundColor: "#F9F9FF !important",  // Light blue highlight
    },

    ".ant-checkbox-inner": {
      borderColor: "#9F9F9F !important",
    },
  
    /* Change row selection checkbox color */
    ".ant-checkbox-checked .ant-checkbox-inner": {
      backgroundColor: "#6D72F6 !important",  // Change to your preferred color
      borderColor: "#6D72F6 !important",  // Change to your preferred color
    },
  
    "th .ant-checkbox-inner:after" : {
      backgroundColor: "#6D72F6 !important",  
    }
});


export default function GenericDraggableTable({
  initialColumns,
  data,
  pageNumber,
  rowsPerPage,
  total,
  onSortChange,
  onPageChange,
  onPageSizeChange,
  emptyMessage = "No matches found for filters applied",
  rowSelection,
  isLoading = true,
  numberOfFixedColumns=1,
  onColumnChange = () => {}
}) {
  const [tableColumns, setTableColumns] = useState(initialColumns);
  const [loading, setLoading] = useState(isLoading);
  const [rowItems, setRowItems] = useState([]);
  const isRowSelectable = rowSelection


  useEffect(() => {
    onColumnChange(tableColumns)
  }, [tableColumns])

  useEffect(() => {
    setRowItems(data);
  }, [data]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setTableColumns(initialColumns);
  }, [initialColumns]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let dragIndex = isRowSelectable
        ? fromIndex > numberOfFixedColumns && toIndex > numberOfFixedColumns
        : fromIndex > numberOfFixedColumns - 1 && toIndex > numberOfFixedColumns - 1;

      if (dragIndex) {
        const newColumns = [...tableColumns];
        const item = isRowSelectable
          ? newColumns.splice(fromIndex - 1, 1)[0]
          : newColumns.splice(fromIndex, 1)[0];
        
        isRowSelectable
          ? newColumns.splice(toIndex - 1, 0, item)
          : newColumns.splice(toIndex, 0, item);

        setTableColumns(newColumns);
      }
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.column && onSortChange) {
      onSortChange(sorter.columnKey, sorter.order === "ascend" ? "asc" : "desc");
    }
  };

  const shouldShowPagination = () => {
    if(isLoading) {
      return false
    }

    if (!rowItems || rowItems == []) {
      return false
    }

    return true
  }


  return (
    <ConfigProvider>
      <StyledTableContainer>
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              bordered
              columns={tableColumns}
              dataSource={loading ? [] : rowItems}
              rowKey={(record) => record.id || record.key}
              rowSelection={rowSelection}
              pagination={false}
              loading={{
                spinning: loading,
                indicator: <Loader />,
              }}
              locale={{
                emptyText: !loading && <NoData text={emptyMessage} />,
              }}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            />

            {shouldShowPagination() && <div className = "paginationContainer" style={{ display: "flex", justifyContent: "right" }}>
                  <Pagination
                    showSizeChanger = {false}
                    onShowSizeChange={(current, size) => onPageSizeChange && onPageSizeChange(size)}
                    current={pageNumber}
                    pageSize={rowsPerPage}
                    total={total}
                    onChange={(page) => onPageChange && onPageChange(page)}
                    showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                  />
              </div>
            }
          </ReactDragListView.DragColumn>
      </StyledTableContainer>
    </ConfigProvider>
  );
}
