import { getFilterData } from "../../Api/Api";
import CODE from "../../Static/Constants/StatusCodes";

const convertIdToString = (values) => {
  return values.map((item) => ({
    ...item,
    id: typeof item.id === "object" ? JSON.stringify(item.id) : item.id,
  }));
};

const fetchFiltersDataObject = async (filtersConfig, currentFilters) => {
    let filtersData = {...currentFilters};
  
    if (!filtersConfig.length) {
      return filtersData;
    }
  
    await Promise.all(
      filtersConfig.map(async (filter) => {
        if (filtersData[filter.key]) {
          return;
        }
  
        if (filter.type === "static") {
          filtersData[filter.key] = {...filter, values: convertIdToString(filter.staticData)};
          return;
        }
  
        const res = await getFilterData(filter);
        if (res && res?.responseStatus?.code === CODE.SUCCESS) {
          let values = res.response.response.filterData
          filtersData[filter.key] = {...filter, values: convertIdToString(values)};
        }
      })
    );

    return filtersData;
};

export { fetchFiltersDataObject};