import React from "react";
import { SvgIcon } from "@mui/material";

const AlertAnalyticsSvg = ({
  width = "19",
  height = "19",
  viewBox = "0 0 19 19",
  fill = "#D3D3D3",
  stroke = "",
  isSelected = false,
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    sx={{ fill, display: "block" }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5849 17.041C15.9172 17.041 16.1865 16.7716 16.1865 16.4394L16.1865 6.976C16.1865 6.64371 15.9171 6.37435 15.5849 6.37435L13.5999 6.37435C13.2676 6.37435 12.9983 6.64371 12.9983 6.976L12.9983 16.4394C12.9983 16.7716 13.2676 17.041 13.5999 17.041L15.5849 17.041ZM11.468 17.041C11.8003 17.041 12.0697 16.7716 12.0697 16.4394L12.0697 10.0214C12.0697 9.68914 11.8003 9.41977 11.468 9.41977L9.48307 9.41977C9.15078 9.41977 8.88142 9.68914 8.88142 10.0214L8.88142 16.4394C8.88142 16.7716 9.15078 17.041 9.48307 17.041L11.468 17.041ZM7.94137 16.4394C7.94137 16.7716 7.67199 17.041 7.33971 17.041L5.35471 17.041C5.02248 17.041 4.75305 16.7716 4.75305 16.4394L4.75305 2.60126C4.75305 2.26903 5.02248 1.99961 5.35471 1.99961L7.33971 1.99961C7.67199 1.99961 7.94136 2.26903 7.94136 2.60126L7.94137 16.4394Z"
      fill={isSelected ? "#4D44D8" : "#989898"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6426 16.7401C17.6426 16.2417 17.2385 15.8376 16.7401 15.8376L3.80448 15.8376L3.80448 2.902C3.80448 2.40359 3.40041 1.99951 2.902 1.99951C2.40358 1.99951 1.99951 2.40359 1.99951 2.902L1.99951 16.7401C1.99951 17.2385 2.40358 17.6426 2.902 17.6426L16.7401 17.6426C17.2385 17.6426 17.6426 17.2385 17.6426 16.7401Z"
      fill={isSelected ? "#BBC5FF" : "#D3D3D3"}
    />
  </SvgIcon>
);

export default AlertAnalyticsSvg;
