import React, { useState, useEffect } from "react";
import { CustomAutocomplete } from "../../Components/CustomAutocomplete";
import { CustomTextField } from "../../Components/CustomTextField";
import Events from "../../Analytics/Events";
import { useNavigate } from "react-router-dom";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useSelector, useDispatch } from "react-redux";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/material";

import { SetSessionExpired } from "../../Actions";
import {
  SetDashboardBatteryID,
  SetDashboardPartner,
  SetIsBatteryIsPartnerIsNull,
} from "../../Actions/Dashboard";

import { getBatteryPartnerList } from "../../Api/DashboardApi";
import { getDiagnosticsBatteryPartnerList } from "../../Api/DiagnosticsApi";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { getBatteryHealthSearchSuggestions } from "../../Api/BatteryHealthApi";
import {
  SetDiagnosticsBatteryID,
  SetDiagnosticsDeviceID,
  SetDiagnosticsErrorTypeIndex,
  SetDiagnosticsPageNumber,
  SetDiagnosticsPartner,
  SetIsBatteryIsPartnerIsNullDiagnostics,
} from "../../Actions/Diagnostics";
import { getDigitalTwinListSearch } from "../../Api/DigitalTwinListApi";
import {
  SetDigitalTwinsBatteryID,
  SetDigitalTwinsPageNumber,
} from "../../Actions/DigitalTwinsList";
import { SetSearchID } from "../../Actions/Search";
import { SetTablePageNumber } from "../../Actions/Table";
import { getDatePickerDate } from "../../Helper/DatePicker/DateConverter";
import { useTranslation } from "react-i18next";

export default function AutocompleteDashboard(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation(['common'])
  const [batteryList, setBatteryList] = useState([]);

  const batteryPartnerSelect = useSelector(
    (state) => state.DashboardBatteryPartnerSelect.value
  );
  const reduxStartDate = useSelector((state) => state.DashboardStartDate.value) || getDatePickerDate(undefined, -14);
  const reduxEndDate = useSelector((state) => state.DashboardEndDate.value) || getDatePickerDate();
  const batteryID = useSelector((state) => state.DashboardBatteryID.value);
  const partner = useSelector((state) => state.DashboardPartner.value);

  const digitalTwinBatteryID = useSelector(
    (state) => state.DigitalTwinsBatteryID.value
  );

  const searchConfig = useSelector(
    (state) => state.SelectedSearchConfig.value
  );
  const selectedSearchType = useSelector(
    (state) => state.SelectedSearchType.value
  );
  const selectedSearchID = useSelector(
    (state) => state.SelectedSearchID.value
  );
  const partnerHealth = useSelector(
    (state) => state.BatteryHealthPartner.value
  );

  const batteryPartnerSelectDiagnostics = useSelector(
    (state) => state.DiagnosticsBatteryPartnerSelect.value
  );
  const batteryIDDiagnostics = useSelector(
    (state) => state.DiagnosticsBatteryID.value
  );
  const deviceIDDiagnostics = useSelector(
    (state) => state.DiagnosticsDeviceID.value
  );
  const partnerDiagnostics = useSelector(
    (state) => state.DiagnosticsPartner.value
  );

  const [typedValue, setTypedValue] = useState("");
  const [errorTextfield, setErrorTextField] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const limit = typedValue.length > 0;

    if (props.page === "batteryHealth") {
      if (!limit) {
        setOpen(false);
        return
      }
      
      getBatteryHealthSearchSuggestions(
        searchConfig?.keys?.[selectedSearchType],
        typedValue,
        10
      ).then((res) => {
        if (res.responseStatus.code === 200) {
          setBatteryList(res.response.data);
        } else {
          if (res.responseStatus.code === -2)
            dispatch(SetSessionExpired(true));
          setBatteryList([]);
        }
      });
    } else if (props.page === "Diagnostics") {
      if (limit) {
        getDiagnosticsBatteryPartnerList(
          batteryPartnerSelectDiagnostics,
          typedValue,
          10,
          props.tabValue
        ).then((res) => {
          if (res.responseStatus.code === 200) {
            setBatteryList(res.response.data);
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));

            setBatteryList([]);
          }
        });
      } else {
        setOpen(false);
      }
    } else if (props.page === "DigitalTwins") {
      if (limit) {
        getDigitalTwinListSearch(typedValue, 5).then((res) => {
          if (res.responseStatus.code === 200) {
            setBatteryList(res.response.data);
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));

            setBatteryList([]);
          }
        });
      } else {
        setOpen(false);
      }
    } else {
      if (limit && searchConfig?.keys?.[selectedSearchType]) {
        getBatteryPartnerList(
          searchConfig?.keys?.[selectedSearchType],
          typedValue,
          10,
          props.tabValue,
          reduxStartDate,
          reduxEndDate
        ).then((res) => {
          if (res?.responseStatus?.code === 200) {
            setBatteryList(res.response.data);
          } else {
            // if (res.responseStatus.code === -2)
            //   dispatch(SetSessionExpired(true));

            setBatteryList([]);
          }
        });
      } else {
        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedValue]);

  useEffect(() => {
    if (["batteryHealth", "alerts"].includes(props.page)) {
      if (selectedSearchID === "") {
        setTypedValue("");
        setErrorTextField(false);
      }
    } else if (props.page === "Diagnostics") {
      if (
        batteryIDDiagnostics === "" &&
        partnerDiagnostics === "" &&
        deviceIDDiagnostics === ""
      ) {
        setTypedValue("");
        setErrorTextField(false);
      }
    } else if (props.page === "DigitalTwins") {
      if (digitalTwinBatteryID === "") {
        setTypedValue("");
        setErrorTextField(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    batteryID,
    partner,
    batteryPartnerSelect,
    selectedSearchType,
    selectedSearchID,
    batteryIDDiagnostics,
    deviceIDDiagnostics,
    partnerDiagnostics,
    batteryPartnerSelectDiagnostics,
  ]);

  useEffect(
    () => () => {
      setBatteryList([]);
      setTypedValue("");
      setErrorTextField(false);
    },
    []
  );

  const handleOpen = (value) => {
    if (value.length > 2) {
      setOpen(true);
    }
  };

  const onTagsChange = (event, value) => {
    let temp;

    if (value !== null && batteryList.includes(value)) {
      let queryParams = decryptTheParams();
      if (["batteryHealth", "alerts"].includes(props.page)) {
        dispatch(SetSearchID(value));
        dispatch(SetTablePageNumber(1));
        Events("searched Battery");
        temp = {
          ...queryParams,
          selectedSearchID: value,
        };
      } else if (props.page === "Diagnostics") {
        dispatch(SetDiagnosticsErrorTypeIndex(0));
        dispatch(SetDiagnosticsPageNumber(1));
        if (batteryPartnerSelectDiagnostics === "Battery ID") {
          dispatch(SetDiagnosticsBatteryID(value));
          dispatch(SetDiagnosticsPartner(""));
          dispatch(SetDiagnosticsDeviceID(""));
          dispatch(SetIsBatteryIsPartnerIsNullDiagnostics("Battery"));
          Events("searched Battery");
          localStorage.setItem("bpSelectDiag", "Battery");
          localStorage.setItem("bpValueDiag", value);
        } else if (batteryPartnerSelectDiagnostics === "Device ID") {
          dispatch(SetDiagnosticsBatteryID(""));
          dispatch(SetDiagnosticsPartner(""));
          dispatch(SetDiagnosticsDeviceID(value));
          dispatch(SetIsBatteryIsPartnerIsNullDiagnostics("Device"));
          Events("searched Device");
          localStorage.setItem("bpSelectDiag", "Device");
          localStorage.setItem("bpValueDiag", value);
        } else {
          dispatch(SetDiagnosticsPartner(value));
          dispatch(SetDiagnosticsBatteryID(""));
          dispatch(SetIsBatteryIsPartnerIsNullDiagnostics("Partner"));
          Events("searched Partner");
          localStorage.setItem("bpSelectDiag", "Partner");
          localStorage.setItem("bpValueDiag", value);
        }
      } else if (props.page === "DigitalTwins") {
        Events("searched Battery");
        dispatch(SetDigitalTwinsBatteryID(value));
        dispatch(SetDigitalTwinsPageNumber(1));
        temp = {
          ...queryParams,
          deviceID: value,
        };
      }
      encryptTheParams(temp, navigate);
      setErrorTextField(false);
      setOpen(false);
    }
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      if (batteryList.includes(e.target.value)) {
        onTagsChange(undefined, e.target.value);
        setErrorTextField(false);
      } else {
        if (["batteryHealth", "alerts"].includes(props.page)) {
          props.openNotification(
            "bottomLeft",
            `${selectedSearchType} ${e.target.value} couldn't be found`
          );
        } else if (props.page === "Diagnostics") {
          if (batteryPartnerSelectDiagnostics === "Battery ID")
            props.openNotification(
              "bottomLeft",
              `Battery ${e.target.value} couldn't be found`
            );
          else if (batteryPartnerSelectDiagnostics === "Device ID")
            props.openNotification(
              "bottomLeft",
              `Device ${e.target.value} couldn't be found`
            );
          else
            props.openNotification(
              "bottomLeft",
              `Occupant ${e.target.value} couldn't be found`
            );
        } else if (props.page === "DigitalTwinsList") {
          props.openNotification(
            "bottomLeft",
            `BatteryID ${e.target.value} couldn't be found`
          );
        }
        setErrorTextField(true);
      }
    }
  }

  return (
    <>
      <CustomAutocomplete
        filterSelectedOptions
        freeSolo
        ListboxProps={{
          sx: {
            fontSize: 13,
            fontWeight: 500,
            textAlign: "left",
          },
        }}
        disablePortal
        id="combo-box-demo"
        options={batteryList.filter((item, i, ar) => ar.indexOf(item) === i)}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        clearOnBlur={true}
        value={
          ["batteryHealth", "alerts"].includes(props.page)
            ? selectedSearchID
            : props.page === "Diagnostics"
              ? batteryPartnerSelectDiagnostics === "Battery ID"
                ? batteryIDDiagnostics
                : batteryPartnerSelectDiagnostics === "Device ID"
                  ? deviceIDDiagnostics
                  : partnerDiagnostics
              : props.page === "DigitalTwins"
                ? digitalTwinBatteryID
                : batteryPartnerSelect === "Battery ID"
                  ? batteryID
                  : partner
        }
        inputValue={typedValue}
        onChange={onTagsChange}
        onKeyDown={keyPress}
        onInputChange={(event, value) => {
          setTypedValue(value);
          setErrorTextField(false);
          handleOpen(value);
        }}
        getOptionLabel={(option) => option}
        forcePopupIcon={false}
        size="small"
        renderInput={(params) => (
          <CustomTextField
            {...params}
            error={errorTextfield}
            InputProps={{
              ...params.InputProps,
              sx: {
                "& input": {
                  textAlign: "center",
                  ml: 1,
                  color: errorTextfield ? "#c62828" : "black",
                },
              },
              endAdornment: (
                <InputAdornment position="end" sx={{ mr: 0.5 }}>
                  <Box>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setTypedValue("");
                        setErrorTextField(false);
                        if (
                          batteryID ||
                          partner ||
                          selectedSearchID ||
                          partnerHealth ||
                          batteryIDDiagnostics ||
                          deviceIDDiagnostics ||
                          partnerDiagnostics ||
                          digitalTwinBatteryID
                        ) {
                          let temp = "";

                          if (props.page === "batteryHealth") {
                            dispatch(SetSearchID(""))
                            let queryParams = decryptTheParams();
                            temp = {
                              ...queryParams,
                              selectedSearchID: ""
                            };
                            props.openNotification(
                              "bottomLeft",
                              `Resetting all filters.`
                            );
                            props.onClearSearch();
                          } else if (props.page === "Diagnostics") {
                            props.onClearSearch();

                            dispatch(SetDiagnosticsBatteryID(""));
                            dispatch(SetDiagnosticsDeviceID(""));
                            dispatch(
                              SetIsBatteryIsPartnerIsNullDiagnostics(null)
                            );
                            dispatch(SetDiagnosticsPartner(""));

                            let queryParams = decryptTheParams();
                            temp = {
                              ...queryParams,
                              batteryID: "",
                              deviceID: "",
                              partner: "",
                              type: "",
                              IsBatteryIsPartnerIsNull: null,
                            };
                            props.openNotification(
                              "bottomLeft",
                              `Cleared all filters if selected`
                            );
                          } else if (props.page === "DigitalTwins") {
                            // If page is Digital Twins
                            props.onClearSearch();

                            dispatch(SetDigitalTwinsBatteryID(""));

                            let queryParams = decryptTheParams();
                            temp = {
                              ...queryParams,
                              batteryID: "",
                            };
                            props.openNotification(
                              "bottomLeft",
                              `Cleared Battery Search Filter`
                            );
                          } else {
                            props.onClearSearch("alert");
                            dispatch(SetDashboardBatteryID(""));
                            dispatch(SetIsBatteryIsPartnerIsNull(null));
                            dispatch(SetDashboardPartner(""));
                            dispatch(SetSearchID(""))

                            let queryParams = decryptTheParams();
                            temp = {
                              ...queryParams,
                              selectedSearchID: "",
                            };
                            props.openNotification(
                              "bottomLeft",
                              `Cleared all filters if selected`
                            );
                          }

                          encryptTheParams(temp, navigate);
                        }
                      }}
                    >
                      <HighlightOffIcon sx={{ fontSize: "19px", mt: "-1px" }} />
                    </IconButton>
                  </Box>
                </InputAdornment>
              ),
            }}
            placeholder={
              props.page === "batteryHealth"
                ? t('common:search_placeholders.device')
                : props.page === "Diagnostics"
                  ? batteryPartnerSelectDiagnostics === "Battery ID"
                    ? t('common:search_placeholders.battery')
                    : batteryPartnerSelectDiagnostics === "Device ID"
                      ? t('common:search_placeholders.device')
                      : t('common:search_placeholders.occupant')
                  : props.page === "DigitalTwins"
                    ? "Search single device"
                    : batteryPartnerSelect === "Battery ID"
                      ? t('common:search_placeholders.battery')
                      : t('common:search_placeholders.occupant')
            }
            sx={{
              fontSize: 17,
              fontWeight: 500,
              fontFamily: "Roboto",
              bgcolor: ThemeProperties.white,
              color: ThemeProperties.red_100,
              borderRadius: "30px",
              width: "212px",
              "& label": {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                "&.Mui-focused": {
                  width: "10%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                },
              },
            }}
          />
        )}
      />
    </>
  );
}
