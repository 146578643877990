import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import PoweredBy from "../../Assets/Images/Sidebar/PoweredBy.svg";
import PoweredByCircle from "../../Assets/Images/Sidebar/PoweredByCircle.svg";
import MyNavLink from "./Links/MyNavLink";
import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import { Backdrop } from "@mui/material";
import DropdownNavLink from "./Links/DropdownNavLink";
import CustomIcon from "../../Components/CustomIcon";
import { SetSessionExpired } from "../../Actions";

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "55px"
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...theme.mixins.toolbar,
}));

function MiniDrawer(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [expandedAccordion, setExpandedAccordion] = React.useState(null);
  const [hoverOnSidebarIcon, setHoverOnSidebarIcon] = React.useState(false);

  const handleAccordionChange = (value) => {
    setExpandedAccordion(value);
  };

  const handleDrawerClick = () => {
    setSidebarOpen(!sidebarOpen);
    setHoverOnSidebarIcon(false)
  };

  const cookies = new Cookies();
  const navigationCardData = cookies.get("navigationCard")
  let availablePages = cookies.get("pages")
  availablePages.push("Inventory")
  availablePages = availablePages.filter(
    (value) => value !== 'DigitalTwin'
  );

  if (navigationCardData === undefined || availablePages === undefined) {
    removeAccessToken()
  }

  return (
    <>
      <Drawer
        variant="permanent"
        open={sidebarOpen}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            border: "0px, 1px, 0px, 0px",
            borderColor: "#E9EFF5",
            paddingLeft: "8px",
            paddingRight: "8px"
          },
        }}
        ModalProps={{ onBackdropClick: () => setSidebarOpen(false) }}
      >
        <List style={{
          paddingTop: "64px",
        }}>
          {navigationCardData?.map((page, index) => {
            // Mark Session of the user expired if their application still has old navbar
            if (page?.tabs?.length > 0) {
              dispatch(SetSessionExpired(true));
              return
            }

            if (page?.listItems) {
              const availablePagesFromList = page.listItems.filter(page => availablePages.includes(page.mykey))
              if (availablePagesFromList.length === 0) {
                return null;
              }
              return <DropdownNavLink 
                name={page.title}
                key={index}
                navigationData={page}
                sidebarOpen={sidebarOpen}
                onAccordionChange={handleAccordionChange}
                isExpanded={expandedAccordion === page.title && sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                availablePages={availablePages}
              />
            }

            if (availablePages.includes(page.mykey)) {
              return <MyNavLink
                to={page.to}
                mykey={page.mykey}
                textPrimary={page.text}
                iconName={page.iconName}
                key={index}
                open={sidebarOpen}
              />
            }
        })}
        </List>
        <IconButton
          disableRipple
          onClick={handleDrawerClick}
          onMouseEnter={() => {setHoverOnSidebarIcon(true)}}
          onMouseLeave={() => {setHoverOnSidebarIcon(false)}}
          sx={{ mb: "12px", ml: sidebarOpen ? "80%" : "0" }}
        >
          <CustomIcon
            name={sidebarOpen ? "closeSidebar" : "expandSidebar"}
            fill={hoverOnSidebarIcon ? '#6D72F6' : '#9B9B9B'}
            style={{ fontSize: 20 }}
          />
        </IconButton>
        {props.hideLogo || <DrawerHeader open={sidebarOpen}>
          {sidebarOpen ? (
            <img src={PoweredBy} alt="not found" />
          ) : (
            <img src={PoweredByCircle} alt="not found" />
          )}
        </DrawerHeader>}
      </Drawer>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer - 1,
          color: "#fff",
        }}
        open={sidebarOpen}
        onClick={() => setSidebarOpen(false)}
      ></Backdrop>
    </>
  );
}

export default MiniDrawer;
