import { SvgIcon } from "@mui/material";
import React from "react";

const SortSvg = ({ 
  width = "25", 
  height = "16", 
  viewBox = "0 0 25 16", 
  fill = "#444B51", 
  stroke = "#444B51",
  strokeWidth = 2,  
  ...props 
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    sx={{ fill, display: "block" }}
    {...props}
  >
    <path d="M6 4H19" />
    <path d="M6 8H16" />
    <path d="M6 12H14" />
  </SvgIcon>
);

export default SortSvg;
