import React from "react";
import { SvgIcon } from "@mui/material";

const NavbarBatteryHealthIcon = ({
  width = "19",
  height = "19",
  viewBox = "0 0 19 19",
  isSelected = false,
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    sx={{ display: "block" }}
    {...props}
  >
    <path
      d="M9.51904 5.7224C8.11976 2.54097 4.86017 2.28937 3.33956 3.64473C2.10205 4.72414 1.5116 7.42673 2.63588 9.88585C4.58516 14.1142 9.51904 16.3786 9.51904 16.3786C9.51904 16.3786 14.4529 14.1548 16.4022 9.92643C17.5265 7.49166 16.936 4.79718 15.6985 3.68531C14.1779 2.28937 10.9183 2.54097 9.51904 5.7224Z"
      fill={isSelected ? "#4D44D8" : "#989898"}
      stroke={isSelected ? "#4D44D8" : "#989898"}
      strokeWidth="0.871212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.51562 9.59395H6.28479L8.71128 12.0287L11.1378 7.15918L12.7554 9.59395H16.5246"
      stroke={isSelected ? "#BBC5FF" : "#D3D3D3"}
      strokeWidth="1.15"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default NavbarBatteryHealthIcon;
