const getMinXForScatterChart = (eqCycle) => {
  switch (eqCycle) {
    case "0 to 100":
      return 0;
    case "100 to 500":
      return 100;
    case "500 to 1200":
      return 500;
    case "1200 and above":
      return 1200;
    default:
      return 0;
  }
}

const getSOHColor = (soh) => {
  if (soh === undefined) return

  if (soh >= 98) return "#185E01"
  if (soh >= 95) return "#64AA35"
  if (soh >= 90) return "#B5E398"
  if (soh >= 85) return "#F0CC48"
  if (soh >= 80) return "#F89F35"
  if (soh >= 75) return "#F22C25"
  if (soh >= 70) return "#AA100A"

  return "#430A07"
}

const getMarkerColorForSOHChart = (soh) => {
  if (soh === undefined) return

  if (soh >= 98) return "#185E01"
  if (soh >= 95) return "#64AA35"
  if (soh >= 90) return "#B5E398"
  if (soh >= 85) return "#F0CC48"
  if (soh >= 80) return "#F89F35"
  if (soh >= 75) return "#F22C25"
  if (soh >= 70) return "#AA100A"

  return "#430A07"
}

const getSOHLevels = (sohLevels) => {
  let data = {
    'labels': [],
    'data': {}
  };
  sohLevels.forEach((item) => {
    let level = "";
    if (item.includes("-")) {
      let temp = item.split("-");
      level = "SOH " + temp[1] + "% to " + temp[0] + "%";
      data.labels.push(level);
      data.data[level] = {
        label: level,
        min: parseInt(temp[0]),
        max: parseInt(temp[1])
      }
    } else if (item.includes(">") || item.includes("<")) {
      level = "SOH " + item + "%"
      data.labels.push(level);
      data.data[level] = {
        label: level,
        min: item.includes(">") ? 99 : 0,
        max: item.includes(">") ? 120 : 70
      }
    }
  });
  return data;
}

const findTotalBatteries = (healthDistributionData, sohLevel) => {
  let label = 0;
  if(healthDistributionData.series.length>0)
  if(!sohLevel)
  {
    healthDistributionData.series.forEach((item)=>{
      label = label + item
    })
  }
  else
  {
    label = healthDistributionData.series[healthDistributionData.labels.indexOf(sohLevel)]
  }

  return label
}

const findAgeInMonths = (value, defaultValue="-", decimalPlaces=0) => {
  if (!Number.isFinite(value)) {
    return defaultValue
  }

  return (value/30).toFixed(decimalPlaces)   
}

export {
  findTotalBatteries,
  getMinXForScatterChart,
  getMarkerColorForSOHChart,
  findAgeInMonths,
  getSOHLevels,
  getSOHColor
}
  