import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import Header from "../../../../Features/Header/Header";
import { PaperHighlight } from "../../../../Components/CustomPaper";

import BasicTableNoPagination from "../../../../Features/Table/AntDesign/BasicTableNoPagination";
import GetSpecificBatteryAlertsColumns from "../../../../Static/Data/SpecificBattery/SpecificBatteryAlert";

import Events from "../../../../Analytics/Events";
import { decryptTheParams } from "../../../../Helper/QueryParams/EncryptDecrypt";

import { getTableBatteries } from "../../../../Api/DiagnosticsApi";
import {
  newIOT,
  newPack,
  newStatus,
  newZone,
} from "../../../../Static/Data/Dashboard/DashboardFilters";
import { combineWithUnderscore } from "../../../../Helper/Formatters/TextFormatter";
import { useDispatch } from "react-redux";
import { SetSessionExpired } from "../../../../Actions";
import AppCache from "../../../../Helper/Common/AppCache";

function Alerts(props) {
  const dispatch = useDispatch();
  const newCity = AppCache.getItem("LocationFilter")
  const newOrg = localStorage.getItem("OrgFilter")?.split(",");

  const [batteryAlertsList, setBatteryAlertsList] = useState({
    data: [],
    responseStatus: { code: -1, msg: "" },
  });

  useEffect(() => {
    let finalDate = ["", ""];
    finalDate[0] = props.date[0];
    finalDate[1] = props.date[1];

    let queryParams = decryptTheParams();
    let finalType, finalValue;

    if (queryParams["batteryID"] && queryParams["batteryID"] !== "undefined") {
      finalType = "Device";
      finalValue = queryParams["batteryID"];
    } else if (
      queryParams["batteryIDGlobal"] &&
      queryParams["batteryIDGlobal"] !== "undefined"
    ) {
      finalType = "Battery";
      finalValue = queryParams["batteryIDGlobal"];
    }

    setBatteryAlertsList({
      data: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    const packID =
      props.hierarchy.level === "pack"
        ? combineWithUnderscore(
            queryParams["batteryID"],
            props.hierarchy.selectedPack.value,
            props.hierarchy.selectedModule.value,
            props.hierarchy.selectedCell.value
          )
        : "";

    getTableBatteries(
      -1,
      newOrg,
      newCity,
      newZone,
      newStatus,
      newPack,
      newIOT,
      [packID],

      "",
      "",
      "",
      "",
      queryParams["batteryID"],
      "",
      "Device ID",

      1,
      "errorTime",
      "desc",
      999999
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        setBatteryAlertsList({
          data: res.response.errorList,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setBatteryAlertsList({
          data: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });
  }, [
    props.date,
    props.hierarchy.selectedPack,
    props.hierarchy.selectedModule,
    props.hierarchy.selectedCell,
  ]);
  return (
    <>
      <Box sx={{ mt: 0 }}></Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PaperHighlight
            elevation={0}
            sx={{ height: "475px" }}
            onMouseEnter={() => Events("SB Hovered Alerts Table")}
          >
            <Header
              heading="Alerts' Timeline"
              description="Alerts log over time during the selected calendar period"
              showIButton={true}
              showThreeDots={false}
              id="Alerts_Timeline"
              page="specificBattery"
            />
            <div className="section" id="Alerts_Timeline">
              <BasicTableNoPagination
                data={batteryAlertsList}
                columns={GetSpecificBatteryAlertsColumns()}
                noDataMsg={"No alerts found"}
                dateString={props.dateString}
                setBatteryConfigList={setBatteryAlertsList}
                changeTheDate={props.ChangeTheDate}
                ChangeTheTab={props.ChangeTheTab}
                openNotification={props.openNotification}
                onClickColumn={"errorTime"}
                height={340}
                pagePerSize={8}
                id="CanBeClicked"
              />
            </div>
          </PaperHighlight>
        </Grid>
      </Grid>
    </>
  );
}

export default Alerts;
