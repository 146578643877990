import { SvgIcon } from "@mui/material";
import React from "react";

const BatteryDischargingStateIcon = ({
  width = "34",
  height = "34",
  viewBox = "0 0 34 34",
  fill = "#3CA967",
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    sx={{ fill: "none", display: "block", ...props.sx }}
    {...props}
  >
    <circle cx={29} cy={29} r={3} fill={fill} />
    <path
      d="M10.3 28h11.4c.442 0 .8-.358 .8-.8V8.3c0-.442-.358-.8-.8-.8h-2.95V5.3c0-.442-.358-.8-.8-.8h-3.9c-.442 0-.8.358-.8.8v2.2H10.3c-.442 0-.8.358-.8.8v18.9c0 .442.358.8.8.8Z"
      stroke="#586CE9"
    />
    <rect x={11.2} y={14.6} width={9.6} height={12} fill="#586CE9" />
    <path
      d="M15 18.6v-4h2v4h2l-3 3-3-3h2Z"
      fill="white"
    />
  </SvgIcon>
);

export default BatteryDischargingStateIcon;
