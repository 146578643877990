import React, { useState } from "react";
import { Paper } from "@mui/material";
import SelectionClearWarning from "./SelectionClearWarning";
import TableFiltersAndColumnSettings from "./TableFiltersAndColumnSettings";
import TableHeaderAndActions from "./TableHeaderAndActions";
import GenericDraggableTable from "./GenericDraggableTable";
import DownloadCSV from "../../../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../../../Helper/CSVOperations/NameTheCSV";


export default function GenericDraggableTableView({
    title,
    count,
    primaryButtonLabel,
    showPrimaryButton,
    onPrimaryButtonClick,
    applySearch,
    disableSearch = false,
    getTotalRowSelection = () => { },
    getCSVData,
    onSendEmail,
    availableEmailDomains,
    filters,
    selectedFilters,
    applyFilters,
    initialColumns,
    selectedColumns,
    disableDownloads = false,
    applySelectedColumns,
    filteredCount,
    tableColumns,
    tableData,
    onColumnOrderChange,
    columnOrder,
    isLoading,
    pageNumber,
    rowsPerPage,
    total,
    applySort,
    onPageChange,
    emptyMessage = "No matches found for filters applied",
    rowSelection,
    setSelectedRowKeys

}) {

    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [pendingAction, setPendingAction] = useState(null);

    const onDownloadCSV = async () => {
        const csvData = await getCSVData()
        const orderKeys = columnOrder
        const reorderedCSVData = csvData.map((item) =>
            Object.fromEntries(orderKeys.map((key) => [key, item[key] ?? ""]))
        );
        DownloadCSV(NameTheCSV("digitalTwinCSV", "Download CSV"), reorderedCSVData)
    }

    const getFixedColumnCount = () => {
        return tableColumns?.filter((columnConfig) => columnConfig?.fixed == 'left')?.length || 0
    }

    const confirmSelectionClear = (action) => {
        if (getTotalRowSelection().length > 0) {
            setPendingAction(() => action);
            setIsWarningOpen(true);
        } else {
            action();
        }
    };

    const handleConfirm = () => {
        setSelectedRowKeys({});
        if (pendingAction) {
            pendingAction();
            setPendingAction(null);
        }
        setIsWarningOpen(false);
    };

    const handleCancel = () => {
        setIsWarningOpen(false);
        setPendingAction(null);
    };

    const onSearchApply = (selectedDevices) => {
        confirmSelectionClear(() => {
            applySearch(selectedDevices);
        });
    }

    const onFiltersApply = (args) => {
        confirmSelectionClear(() => {
            applyFilters(args);
        });
    }

    const onSortApply = (key, order) => {
        confirmSelectionClear(() => {
            applySort(key, order)
        });
    }



    return (
        <Paper elevation={0} sx={{ height: "100%", borderRadius: "12px", px: 3, py: 2, border: "1px solid #ececec" }}>
            <TableHeaderAndActions
                title={title}
                count={count}
                primaryButtonLabel={primaryButtonLabel}
                onSearchApply={(selectedDevices) => onSearchApply(selectedDevices)}
                disableSearch={disableSearch}
                selectedRows={getTotalRowSelection()}
                onDownloadCSV={onDownloadCSV}
                getCSVData={getCSVData}
                onSendEmail={onSendEmail}
                availableEmailDomains={availableEmailDomains || []}
                showPrimaryButton={showPrimaryButton}
                disableDownloads = {disableDownloads}
                onPrimaryButtonClick={() => onPrimaryButtonClick()}
            />

            <TableFiltersAndColumnSettings
                selectedRows={getTotalRowSelection()}
                filters={filters}
                selectedFilters={selectedFilters}
                setSelectedFilters={onFiltersApply}
                initialColumns={initialColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={applySelectedColumns}
                filteredCount={filteredCount}
            />

            <GenericDraggableTable
                initialColumns={tableColumns}
                data={tableData}
                onColumnChange={onColumnOrderChange}
                isLoading={isLoading}
                pageNumber={pageNumber}
                rowsPerPage={rowsPerPage}
                total={total}
                onSortChange={(key, order) => onSortApply(key, order)}
                onPageChange={(pageNo) => onPageChange(pageNo)}
                onPageSizeChange={() => { }}
                emptyMessage={emptyMessage}
                numberOfFixedColumns={getFixedColumnCount()}
                rowSelection={rowSelection}
            />

            <SelectionClearWarning
                open={isWarningOpen}
                onProceed={handleConfirm}
                onCancel={handleCancel}
            />

        </Paper>
    )
}
