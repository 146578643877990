import React from "react";
import { Typography, Box } from "@mui/material";

function NoData(props) {
  return (
    <div className="mytable">
      <div>
        <Box sx = {{ my: 5 }}>
          <Typography variant="fw600s14lh20c3D4B5A">
            {props.text}
          </Typography>
        </Box>
      </div>
    </div>
  );
}

export default NoData;
