import { SvgIcon } from "@mui/material";
import React from "react";

const OnlineIcon = ({ 
  fill = "none", 
  stroke = "#3CA967", 
  strokeWidth = 2, 
  viewBox = "0 0 20 20", 
  width = "20",
  height = "20",
  ...props 
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    sx={{ fill, display: "block" }}
    {...props}
  >
    <path d="M5 12.55a11 11 0 0 1 14.08 0" />
    <path d="M1.42 9a16 16 0 0 1 21.16 0" />
    <path d="M8.53 16.11a6 6 0 0 1 6.95 0" />
    <line x1="12" y1="20" x2="12.01" y2="20" />
  </SvgIcon>
);

export default OnlineIcon;
