import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CancelFormActionButton, PrimaryFormLoadingActionButton } from '../Table/GenericDraggableTable/TableStyles';
import Cookies from 'universal-cookie';


const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    width: "200px",
    maxWidth: "90%",
  },
}));


const ChangeLanguageDialog = ({ open, onClose }) => {
  const cookies = new Cookies();
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleApply = () => {
    cookies.set('lang', selectedLanguage)
    i18n.changeLanguage(selectedLanguage);
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>Change Language</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            value={selectedLanguage}
            onChange={handleChange}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="fr">French</MenuItem>
            <MenuItem value="pt">Portuguese</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
            {/* Add more languages as needed */}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <CancelFormActionButton onClick={onClose}>Cancel</CancelFormActionButton>
        <PrimaryFormLoadingActionButton onClick={handleApply} color="primary">Apply</PrimaryFormLoadingActionButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default ChangeLanguageDialog;
