import { SvgIcon } from "@mui/material";
import React from "react";

const DegradationSvg = ({ 
  width = "14", 
  height = "21", 
  viewBox = "0 0 14 21", 
  fill = "none", 
  stroke = "#4CAF50",
  strokeWidth = 0.7,
  ...props 
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    sx={{ fill, display: "block", ...props.sx }}
    {...props}
  >
    <path d="M2.23311 20.5H11.7664C12.2083 20.5 12.5664 20.1418 12.5664 19.7V3.83333C12.5664 3.3915 12.2083 3.03333 11.7664 3.03333H9.39977V1.3C9.39977 0.858172 9.0416 0.5 8.59977 0.5H5.39977C4.95794 0.5 4.59977 0.858172 4.59977 1.3V3.03333H2.23311C1.79128 3.03333 1.43311 3.3915 1.43311 3.83333V19.7C1.43311 20.1418 1.79128 20.5 2.23311 20.5Z" />
    <path d="M3.19983 7.33337L5.53829 11.3637L7.87675 9.6364L10.7998 13.6667M10.7998 13.6667V11.3637M10.7998 13.6667H8.46137" />
  </SvgIcon>
);

export default DegradationSvg;
