import {
  Box,
  Button,
  IconButton,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import StackIcon from "../../Assets/Icons/Dashboard/StackIcon.svg";
import ManageIcon from "../../Assets/Icons/Dashboard/ManageIcon.svg";
import BarChartIcon from "../../Assets/Icons/Dashboard/BarChartIcon.svg";
import InfoIcon from "../../Assets/Icons/Dashboard/InfoIcon.svg";
import { useState } from "react";
import AlertsInfoPopover from "../../Features/Popover/AlertsInfoPopover";
import {
  StyledListItemButton,
  StyledListItemButtonIcon,
} from "../../Components/CustomListItemButton";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { Typography } from "antd";
import { OutlinedButton } from "../../Components/CustomButton";
import { useTranslation } from "react-i18next";

const AlertHeaderIcons = (props) => {
  const {t} = useTranslation(['common', 'alerts'])
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorElProfilePopup, setAnchorElProfilePopup] = useState(null);

  const handleOpenPopup = (event) => {
    setOpenPopover(true);
    setAnchorElProfilePopup(event.currentTarget);
  };

  return (
    <Box display="flex" gap={1} justifyContent="center">
      <Tooltip
        title={
          <h2>
            {props.cartCount > 0
              ? "Alerts Stacked"
              : "Select Alerts to Open Cart"}
          </h2>
        }
        placement="bottom"
        zIndex={200}
      >
        {props.cartCount > 0 ? (
          <StyledListItemButtonIcon
            selected={props.cartCount > 0}
            onClick={props.openBatteryStackDialog}
            myColor={ThemeProperties.purpleSelected}
            border={true}
            sx={{
              m: 0,
              height: 35,
            }}
          >
            <Box
              color={ThemeProperties.Purple1}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
              width={148}
            >
              <img src={StackIcon} />
              <h1>{props.cartCount}</h1>
              <Box display="flex" flexDirection="column">
                <Box fontWeight="bold">{t('alerts:stack.label')}</Box>
              </Box>
            </Box>
          </StyledListItemButtonIcon>
        ) : (
          // <IconButton onClick={props.openBatteryStackDialog}>
          <Box
            sx={{
              height: 35,
            }}
          >
            <img src={StackIcon} />
          </Box>
        )}
      </Tooltip>
      {/* Commenting these icons for now. Will show them in future. */}
      {/* <Tooltip title={<h2>Information</h2>} placement="bottom" zIndex={200}>
        <IconButton data-testid="info-icon" onClick={handleOpenPopup}>
          <img src={InfoIcon} />
        </IconButton>
      </Tooltip>
      <IconButton data-testid="info-icon">
        <img src={ManageIcon} />
      </IconButton>
      <IconButton>
        <img src={BarChartIcon} />
      </IconButton> */}
      {openPopover && (
        <AlertsInfoPopover
          openPopup={openPopover}
          onClosePopup={() => setOpenPopover(false)}
          anchorElProfilePopup={anchorElProfilePopup}
        />
      )}
    </Box>
  );
};

export default AlertHeaderIcons;
