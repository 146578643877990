import { PaperHighlight } from "../../Components/CustomPaper"
import GenericHeader from "../Header/GenericHeader"
import { CardBodyContainer} from "../../Components/CustomBox";
import { useEffect } from "react";
import ScrollToTheElement from "../../Helper/Operations/ScrollToTheElement";


const CardWithHeader = ({
  children,
  csvData,
  onMouseEnter,
  bodyStyle,
  id = "",
  pageLabel = "",
  headerClass = "",
  title = "",
  subtitle = null,
  iconElement = null,
  csvName = "",
  showCSVDownload =  false,
  hasFilter = false,
  clickableHeader = false,
  filterData = {},
  cardStyle = {height: "540px"},
  generateCsvData = () => {},
  openNotification = () => {},
  closeNotification = () => {},
  onFilterChange = () => {},
  onClickHeader = () => {}

}) => {
  useEffect(() => {
    ScrollToTheElement()
  }, []);

  return (
    <PaperHighlight
      id={id}
      elevation={0}
      onMouseEnter={onMouseEnter}
      sx={{ height: "540px", ...cardStyle }}
    >
      <GenericHeader
        id={id}
        headerClass={headerClass}
        iconElement={iconElement}
        heading={title}
        description={subtitle}
        pageLabel={pageLabel}
        csvName={csvName}
        csvData={csvData}
        showCSVDownload={showCSVDownload}
        generateCsvData={generateCsvData}
        openNotification={openNotification}
        closeNotification={closeNotification}
        hasFilter={hasFilter}
        filterData={filterData}
        onFilterChange={onFilterChange}
        onClickHeader={onClickHeader}
        clickableHeader={clickableHeader}
      />
      <CardBodyContainer sx={bodyStyle}>{children}</CardBodyContainer>
    </PaperHighlight>
  );
}



export default CardWithHeader
