import { SvgIcon } from "@mui/material";
import React from "react";

const ExpandSidebarSvg = ({
  width = "21",
  height = "18",
  viewBox = "0 0 21 18",
  fill = "#9B9B9B",
  stroke = "",
  isSelected = false,
  style = {},
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    sx={{ fill, display: "block", width, height, ...style }}
    {...props}
  >
    <path
      d="M3.67658 8.82422H13.9707"
      stroke={fill}
      strokeWidth="1.47059"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.82364 13.9709L13.9707 8.82382L8.82364 3.67676"
      stroke={fill}
      strokeWidth="1.47059"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 2L20 16"
      stroke={fill}
      strokeWidth="1.47059"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default ExpandSidebarSvg;
