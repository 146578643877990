import { SvgIcon } from "@mui/material";
import React from "react";

const SelectUpArrowSvg = ({ 
  width = "11", 
  height = "6", 
  viewBox = "0 0 11 6", 
  fill = "none", 
  stroke = "", 
  ...props 
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    sx={{ fill, display: "block", ...props.sx }}
    {...props}
  >
    <path 
      d="M0.130146 6C0.0778976 6 0.0308736 5.9666 0.00997419 5.91373C-0.0109253 5.86085 0.00213705 5.80241 0.0387111 5.76345L5.40987 0.0417438C5.436 0.0139146 5.46996 0 5.50131 0C5.53266 0 5.56923 0.0139146 5.59274 0.0417438L10.9613 5.76067C10.9979 5.79685 11.0109 5.85807 10.99 5.91373C10.9691 5.9666 10.9221 6 10.8699 6H0.130146Z" 
      fill="#6D72F6" 
    />
  </SvgIcon>
);

export default SelectUpArrowSvg;
