import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BatteryInfoBox,
  BatteryInfoContainer,
  BatteryInfoGotToSBContainer,
  BatteryInfoItem,
  BatteryInfoLabel,
  BatteryInfoSOHBox,
  BatteryInfoSOHValueText,
  BatteryInfoValue,
  LaunchIcon,
  GoToBatteryPageText,
} from "./Components/StyledComponent";
import Events from "../../Analytics/Events";
import { useNotification } from "../../Hooks/useNotification";
import CardWithHeader from "../../Features/Cards/CardWithHeader";
import CustomIcon from "../../Components/CustomIcon";
import { getRealTimeStatus } from "../../Api/SpecificBatteryApi";
import CODE from "../../Static/Constants/StatusCodes";
import { SetSessionExpired } from "../../Actions";
import { findAgeInMonths } from "../../Helper/BatteryHealth/BatteryHealthHelper";
import { encryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const batteryInfoNotFoundState = {
  soh: null,
  sohText: "-",
  age: "-",
  rul: "-",
  eqCycle: "-",
  deviceID: "-",
  batteryID: null,
  packOEM: "-",
  maxSoh: "-",
  minSOh: "-",
  initialCapacity: "-",
  presentCapacity: "-",
  cellType: "-",
  predictedEOL: "-",
  optimisticEOL: "-",
  pessimisticEOL: "-",
};

const batteryInfoLoadingState = {
  sohText: "...",
  soh: null,
  age: "...",
  rul: "...",
  eqCycle: "...",
  deviceID: "...",
  batteryID: null,
  maxSoh: "...",
  minSOh: "...",
  initialCapacity: "...",
  presentCapacity: "...",
  packOEM: "...",
  cellType: "...",
  predictedEOL: "...",
  optimisticEOL: "...",
  pessimisticEOL: "...",
}


const BatteryInfoCardSection = ({ pagesContent, searchID, searchType, searchColumn, setBatterySOH = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation(['common', 'health'])
  const { openNotification, closeNotification } = useNotification();
  const [batteryInfo, setBatteryInfo] = useState(batteryInfoNotFoundState);

  useEffect(() => {
    fetchBatteryInfo();
  }, [searchID, searchColumn]);

  const batteryInfoFields = [
    { label: t('health:device_id', "Serial Number"), value: batteryInfo.deviceID },
    { label: t('health:pack_oem', "Pack Manufacturer"), value: batteryInfo.packOEM },
    { label: t('health:initial_capacity', "Initial Capacity (Ah)") , value: batteryInfo.initialCapacity },
    { label: t('health:present_capacity', "Present Capacity (Ah)") , value: batteryInfo.presentCapacity },
    { label: t('health:age', "Age (months)"),  value: batteryInfo.age },
    { label: pagesContent?.translations?.eqCycle || "Eq. Cycle", value: batteryInfo.eqCycle },
    { label: t('health:rul', "RUL (months)"), value: batteryInfo.rul },
  ];

  const fetchBatteryInfo = async () => {
    setBatteryInfo(batteryInfoLoadingState);

    try {
      const { responseStatus, response } = await getRealTimeStatus(searchColumn, searchID);

      if (responseStatus.code === CODE.SUCCESS) {
        updateBatteryInfo(response.data);
      } else {
        handleError(responseStatus.code);
      }
    } catch (error) {
      handleError();
    }
  };

  const updateBatteryInfo = (data) => {
    const {
      sohEst,
      calendarAgeDaysEst,
      RULCalendarAgeDaysPredicted,
      eqCycle,
      initialCapacity,
      presentCapacity,
      deviceID,
      batteryID,
      packOEM,
      maxSoh,
      minSOh,
      cellType,
      eol,
      optimisticEol,
      pessimisticEol,
    } = data;

    const soh = parseFloat(sohEst?.toFixed(1)) || null;

    setBatteryInfo({
      soh,
      sohText: Number.isFinite(soh) ? `${soh}%` : "-",
      deviceID: deviceID || "-",
      batteryID: batteryID || null,
      cellType: cellType || "-",
      predictedEOL: eol || "-",
      packOEM: packOEM || "-",
      initialCapacity: Number.isFinite(initialCapacity) ? initialCapacity.toFixed(2) : "-",
      presentCapacity: Number.isFinite(presentCapacity) ? presentCapacity.toFixed(2) : "-",
      optimisticEOL: optimisticEol || "-",
      pessimisticEOL: pessimisticEol || "-",
      age: findAgeInMonths(calendarAgeDaysEst, "-"),
      rul: findAgeInMonths(RULCalendarAgeDaysPredicted, "-"),
      eqCycle: Number.isFinite(eqCycle) ? eqCycle : "-",
      maxSoh: Number.isFinite(maxSoh) ? maxSoh : "-",
      minSOh: Number.isFinite(minSOh) ? minSOh : "-",
    });

    setBatterySOH(soh);
  };

  const handleError = (statusCode) => {
    if (statusCode === CODE.SESSION_EXPIRED) {
      dispatch(SetSessionExpired(true));
    }
    setBatteryInfo(batteryInfoNotFoundState);
  };

  const handleGoToBatteryPage = () => {
    Events("Battery Health: Clicked go to specific battery page");

    if (!batteryInfo.batteryID) {
      return
    }

    const queryParams = {
      batteryID: batteryInfo.batteryID,
      deviceID: batteryInfo.deviceID,
      navigateFromTab: true,
      tabValue: 'health',
    };

    encryptTheParams(queryParams, navigate, true);
  };

  return (
    <CardWithHeader
      title= {t('health:present_soh',  "Present SOH")}
      subtitle={ t('health:present_soh_subtitle',  {searchType: t('health:device_id', "Serial Number"), searchID: searchID})}
      iconElement={<CustomIcon name="healthWithLine" />}
      pageLabel="Battery Health"
      onMouseEnter={() =>
        Events("Battery Health Page: Hover over Battery Info Card")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
    >
      <BatteryInfoContainer>
        <BatteryInfoSOHBox>
          <BatteryInfoSOHValueText soh={batteryInfo.soh}>
            {batteryInfo.sohText}
          </BatteryInfoSOHValueText>
        </BatteryInfoSOHBox>

        {/* Battery Info Fields */}
        <BatteryInfoBox>
          {batteryInfoFields.map((item, index) => (
            <BatteryInfoItem key={index}>
              <BatteryInfoLabel>{item.label}</BatteryInfoLabel>
              <BatteryInfoValue>{item.value}</BatteryInfoValue>
            </BatteryInfoItem>
          ))}
        </BatteryInfoBox>

        {/* Go to Battery Page */}
        <BatteryInfoGotToSBContainer onClick={handleGoToBatteryPage}>
          <GoToBatteryPageText>{t("health:go_to_battery_page", "Go to battery page")} </GoToBatteryPageText>
          <LaunchIcon />
        </BatteryInfoGotToSBContainer>
      </BatteryInfoContainer>
    </CardWithHeader>
  );
};

export default BatteryInfoCardSection;
