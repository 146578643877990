import * as amplitude from '@amplitude/analytics-browser';
import Cookies from 'universal-cookie';

const Events = (value) => {
  const cookies = new Cookies();

  // Initialize Amplitude with your API key
  amplitude.init(cookies.get("amplitudeID"), {
    defaultTracking: true,
  });

  const getSessionId = () => {
    const previousTimestamp = cookies.get('sessionId', { path: '/' });
    const currentTimestamp = Date.now();

    let difference = Math.round((currentTimestamp - previousTimestamp) / 1000);
    if (difference >= 1800) {
      cookies.set('sessionId', currentTimestamp, { path: '/' });
    }
    return cookies.get('sessionId', { path: '/' });
  };

  // Log the event to Amplitude
  amplitude.track('Event Name', {
    event_type: value,
    user_id: cookies.get('name'),
    session_id: getSessionId(),
    location_lat: 12.9716,
    location_lng: 77.5946,
    ip: '127.0.0.1',
    event_properties: {
      keyString: 'valueString',
      keyInt: 11,
      keyBool: true,
    },
  });
};

export default Events;
