import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box, Typography } from "@mui/material";
import CODE from "../../Static/Constants/StatusCodes";

import {
	SetDigitalTwinsColumns,
	SetDigitalTwinsConfigurationType,
	SetDigitalTwinsTabValue,
} from "../../Actions/DigitalTwinsList";

import Loader from "../../Helper/Loaders/Loader";

import {
	decryptTheParams,
	encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";

import TextHyperLinkWithCopyIcon from "../../Helper/Operations/TextHyperLinkWithCopyIcon";

import { ThemeProperties } from "../../Theme/ThemeProperties";

import InventoryMain from "./InventoryMain";
import { batteryConfigurations } from "../../Static/Data/DigitalTwinList/BatteryConfigurations";
import { getPagesMetaData } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";
import DeviceIDLink from "../../Features/Table/GenericDraggableTable/Renderers/columnTypes/DeviceIDLink";
import Cookies from "universal-cookie";
import ProgressBarColumnView from "../../Features/Table/GenericDraggableTable/Renderers/columnTypes/ProgressBarColumnView";
import { getSOHColor } from "../../Helper/BatteryHealth/BatteryHealthHelper";
import { getColumnsConfig } from "../../Features/Table/GenericDraggableTable/GenericDraggableTableFuncations";
import { useTranslation } from "react-i18next";

export default function Inventory(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cookies = new Cookies();
	const permissions = cookies.get('permissions') || [];
	const queryParams = decryptTheParams();
	const [pagesContent, setPagesContent] = useState({
		batteryID: "",
		partner: "",
		tabValue: 0,
		pageHeader: "",
		mounted: false,
	});

	const customRenderers = {
		"digital-twin-list-series-parallel": (record, config) => {
			const level = config?.seriesParallelConfigLevel
			const tableValue = level === "pack"
				? `${record?.seriesPacks}S / ${record?.parallelPacks}P`
				: level === "module"
					? `${record?.seriesModulesInPack}S / ${record?.parallelModulesInPack}P`
					: `${record?.seriesModulesInPack * record?.seriesCellsInModule}S / ${record?.parallelModulesInPack * record?.parallelCellsInModule}P`
			return <Typography variant="tableRow">{tableValue}</Typography>
		},

		'device-id-link': (record, config) => {
			return <DeviceIDLink record={record} config={config} onTextClick={() => onBatteryIDClick(record)} />
		},

		'battery-config-link': (record, config) => {
			const value = record?.[config?.dataIndex];
			return <div className="renderID">{TextHyperLinkWithCopyIcon(value, "Config Name", onConfigIDClick, record)}</div>;
		},

		'progress-bar': (record, config) => {
			const value = record?.[config?.dataIndex];
			const decimalPlaces = config?.decimalPlaces
			const barColor = getSOHColor(value)
			const startProgressFrom = config?.startProgressFrom ? config?.startProgressFrom : 0
			const unitPostFix = config?.unitPostfix ? config?.unitPostfix : ""
			return <ProgressBarColumnView minProgress={startProgressFrom} defaultText={"Not Available"} value={value ? value?.toFixed(decimalPlaces) : 0} barColor={barColor} record={record} config={config} unitPostFix={unitPostFix} />
		}
	};

	useEffect(() => {
		if ('tabValue' in queryParams) {
			dispatch(SetDigitalTwinsTabValue(+queryParams['tabValue']));
		}
		getPageMeta();
	}, []);


	async function getPageMeta() {
		const pageName = 'Inventory'
		const res = await getPagesMetaData(pageName)
		let allowAddDigitalTwin = true
		let allowAddBatteryConfiguration = true
		let filterConfigurations
		let availableEmailDomains = []

		if (res?.responseStatus?.code === CODE.SESSION_EXPIRED) {
			SetSessionExpired(true);
			return null;
		}

		let pageData = {}
		if (res && res?.responseStatus?.code === CODE.SUCCESS) {
			pageData = res.response?.response
			const tablesData = pageData.metaInfo.table
			filterConfigurations = pageData.metaInfo?.filterConfigurations
			allowAddDigitalTwin = pageData.metaInfo?.allowAddDigitalTwin
			allowAddBatteryConfiguration = pageData.metaInfo.allowAddBatteryConfiguration
			availableEmailDomains = pageData.metaInfo.availableEmailDomains
			initializeTableColumns(tablesData)
		}

		setPagesContent({
			...pageData.metaInfo,
			filterConfigurations: filterConfigurations,
			allowAddDigitalTwin: allowAddDigitalTwin,
			allowAddBatteryConfiguration: allowAddBatteryConfiguration,
			availableEmailDomains,
			mounted: true
		})
	}

	function onBatteryIDClick(record) {

		let temp;
		temp = {
			...queryParams,
			batteryID: record.batteryID,
			deviceID: record.deviceID,
			batterySearchedGlobal: "false",
			prevLink: '/',
			navigateFromTab: true,
			tabValue: 'batteryVitals'
		};

		console.log("onBatteryIDClick", { temp })
		encryptTheParams(temp, navigate, true);
	}

	function onConfigIDClick(record) {

		let temp = {
			...queryParams,
			configID: record?.id,
			isEdit: true,
			isView: true,
			configName: record?.configName,
			application: record?.application
		};
		const batteryConfig = batteryConfigurations?.find(item => item?.value === record?.application)
		dispatch(SetDigitalTwinsConfigurationType(batteryConfig?.value));
		encryptTheParams(temp, navigate, false, "/DigitalTwin/add-config");

	}

	const shouldShowActionMenu = (config) => {
		const requiredPermissions = config?.requiredPermissions ?? [];
		const userPermissions = permissions ? [...permissions] : [];
		return requiredPermissions.every(permission => userPermissions.includes(permission));

	};

	function initializeTableColumns(tablesData) {
		const columnsConfig = [
			{
				name: "Digital Twins Columns",
				columns: getColumnsConfig({
					tableColumns: tablesData.digitalTwinListTable?.columnsConfig,
					tableColumnOrder: tablesData.digitalTwinListTable?.columnOrdering || [],
					shouldShowActionMenu,
					customRenderers,
					t
				})
			},
			{
				name: "Battery Configurations Columns",
				columns: getColumnsConfig({
					tableColumns: tablesData.digitalTwinListBatteryConfigurationTable?.columnsConfig,
					tableColumnOrder: tablesData.digitalTwinListBatteryConfigurationTable?.columnOrdering || [],
					shouldShowActionMenu,
					customRenderers,
					t
				})
			}
		]
		dispatch(SetDigitalTwinsColumns(columnsConfig));
	}

	if (!pagesContent.mounted) {
		return (
			<Box sx={{ height: "100vh", bgcolor: ThemeProperties.backgroundPurpleDarker }}>
				<Loader />
			</Box>
		)
	}

	return (
		<div style={{ fontFamily: 'Roboto', WebkitFontSmoothing: "antialiased", MozOsxFontSmoothing: "grayscale", background: '#FAFAFA', height: '100%' }}>
			<InventoryMain pagesContent={pagesContent} permissions={permissions} />
		</div>
	)
}
