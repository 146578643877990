import qs from "qs";
import axios from "axios";
import { GetData } from "./Functions/SettingData";
import { convertZonetoUTCStart } from "../Helper/DatePicker/DateConverter";
import { pages_info } from "../Static/Data/Dashboard/DashboardResponses";

const postLogout = () => {
  return `/api/logout`;
};

const getPagesMetaData = (pageName) => {
  var config = {
    method: "get",
    url: `/api/get_page_meta`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      pageName: pageName,
    },
  };
  let data = GetData(config);

  return data;
};

const getOrgTranslations = () => {
  var config = {
    method: "get",
    url: `/api/get_page_meta`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      pageName: "login",
    },
  };
  let data = GetData(config);
  return data;
};




const getFilterData = (filterConfig) => {
  var config = {
    method: "get",
    url: `/api/get_filter_data`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      filterConfig : JSON.stringify(filterConfig)
    },
  };
  let data = GetData(config);

  return data;
};

async function sendRequestforPasswordReset(emailID) {
  let data = {
    response: {
      msg: "not found",
    },
    responseStatus: {
      code: 0,
      msg: null,
    },
  };

  var config = {
    method: "post",
    url: `/api/reset`,
    data: { email: emailID, link: `${window.location.origin}/forgot_password` },
  };
  let res = await axios(config);

  if (res.data.responseStatus.code === 200) {
    data.response.msg = "found";
  } else {
    data.response.msg = "not found";
  }
  data.responseStatus.code = res.data.responseStatus.code;
  data.responseStatus.msg = res.data.responseStatus.msg;

  return data;
}

async function sendRequestforProfilePassReset(oldPassword, newPassword) {
  let data = {
    response: {
      msg: "not found",
    },
    responseStatus: {
      code: 0,
      msg: null,
    },
  };

  var config = {
    method: "post",
    url: `/api/reset_password`,
    data: { oldPassword: oldPassword, newPassword: newPassword },
  };
  let res = await axios(config);

  if (res.data.responseStatus.code === 200) {
    data.response.msg = "found";
  } else {
    data.response.msg = "not found";
  }
  data.responseStatus.code = res.data.responseStatus.code;
  data.responseStatus.msg = res.data.responseStatus.msg;

  return data;
}

async function sendGetRequestforProfilePassReset(
  email,
  oldPassword,
  newPassword
) {
  let data = {
    response: {
      msg: "not found",
    },
    responseStatus: {
      code: 0,
      msg: null,
    },
  };

  var config = {
    method: "get",
    url: `/api/reset_password`,
    params: {
      email: email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
  };
  let res = await axios(config);

  if (res.data.responseStatus.code === 200) {
    data.response.msg = "found";
  } else {
    data.response.msg = "not found";
  }
  data.responseStatus.code = res.data.responseStatus.code;
  data.responseStatus.msg = res.data.responseStatus.msg;

  return data;
}

async function getIfLinkIsValid(token) {
  let data = {
    response: {
      msg: "not found",
      emailID: "",
    },
    responseStatus: {
      code: 0,
      msg: null,
    },
  };

  var config = {
    method: "get",
    url: `/api/validate`,
    params: { token: token },
  };
  try {
    let res = await axios(config);
    if (res.data.responseStatus.code === 200) {
      data.response.msg = "found";
      data.response.emailID = res.data.email;
    } else {
      data.response.msg = "not found";
    }
    data.responseStatus.code = res.data.responseStatus.code;
    data.responseStatus.msg = res.data.responseStatus.msg;
  } catch (err) {}

  return data;
}

async function sendRequestforChangePassword(token, newPassword) {
  let data = {
    response: {
      msg: "not found",
    },
    responseStatus: {
      code: 0,
      msg: null,
    },
  };

  var config = {
    method: "post",
    url: `/api/validate`,
    data: { token: token, new_password: newPassword },
  };
  let res = await axios(config);

  if (res.data.responseStatus.code === 200) {
    data.response.msg = "found";
  } else {
    data.response.msg = "not found";
  }
  data.responseStatus.code = res.data.responseStatus.code;
  data.responseStatus.msg = res.data.responseStatus.msg;

  return data;
}

//get orgInfo
async function getOrgInfo() {
  let data = {
    response: {
      orgName: "",
      amplitudeID: "",
      timeZone: "",
      metaInfo: "",
    },
    responseStatus: {
      code: 0,
      msg: null,
    },
  };
  var config = {
    method: "get",
    url: `/api/org_info`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let res = await axios(config);
  if (res.data.responseStatus.code === 200) {
    data.response.orgName = res.data.response.orgName;
    data.response.amplitudeID = res.data.response.amplitudeID; //rename to amplitudeAPIKey
    data.response.timeZone = res.data.response.primaryTimeZone;
    data.response.metaInfo = res.data.response.metaInfo;
  }
  data.responseStatus.code = res.data.responseStatus.code;
  data.responseStatus.msg = res.data.responseStatus.msg;

  return data;
}

//get timezone
async function getUserPrefrenceTimezone() {
  var config = {
    method: "get",
    url: `/api/user_timezone_preferences`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      preferenceKey: "timezone",
    },
  };
  let data = GetData(config);
  return data;
}

async function putUserPrefrenceTimezone(timezone, isAutomaticTime) {
  var params = {
    preferenceKey: "timezone",
    preferenceValue: {
      timezonename: timezone,
      isAutomaticTime: isAutomaticTime,
    },
  };
  var config = {
    method: "put",
    url: `/api/user_timezone_preferences`,
    headers: {
      "Content-Type": "application/json",
    },

    data: params,
  };
  let data = GetData(config);
  return data;
}

//get pagesComponents
async function getPagesComponents(pageName) {
  var config = {
    method: "get",
    url: `/api/page_info`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      pageName: pageName,
    },
  };
  const data = pages_info;
  return data;

  // let data = GetData(config);
  // return data;
}

//Profile Popup
async function sendPostRequestFeature(feature, description) {
  var config = {
    method: "post",
    url: `/api/request_feature`,
    data: {
      featureName: feature,
      featureBody: description,
    },
  };
  let data = GetData(config);
  return data;
}

async function sendPostSendFeedback(description) {
  var config = {
    method: "post",
    url: `/api/feedback`,
    data: {
      feedbackBody: description,
    },
  };
  let data = GetData(config);
  return data;
}

//Toolbar
async function getAllToolbarBatteries(value) {
  var config = {
    method: "get",
    url: `/api/battery_list`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      // battery_search: value,
      columnName: "assetID",
      columnSearch: value,
      columnLimit: 10,
    },
  };
  let data = GetData(config);
  return data;
}

// Cart Operations

//Landing page
async function getLastUpdated() {
  var config = {
    method: "get",
    url: `/api/last_pinged_time/v2`,
    headers: {
      "Content-Type": "application/json",
    },
    // params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getConnectedBatteries() {
  var config = {
    method: "get",
    url: `/api/connected_batteries/v2`,
    headers: {
      "Content-Type": "application/json",
    },
    // params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getFourMetrics() {
  var config = {
    method: "get",
    url: "/api/metrics",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      batteryType: "",
    },
  };
  let data = GetData(config);
  return data;
}

async function getSuperCriticalAlerts(alertStatus, severity) {
  var config = {
    method: "get",
    url: "/api/metrics_today",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      alertStatus: alertStatus,
      startDate: "",
      endDate: "",
      severity: severity,
      batteryFlag: [0],
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getOpenAlerts(alertStatus) {
  var config = {
    method: "get",
    url: "/api/metrics_today",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      alertStatus: alertStatus,
      batteryFlag: [0],
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}
async function getResolvedAlerts(startDate, endDate) {
  var config = {
    method: "get",
    url: "/api/metrics_today",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      alertStatus: "Resolved", //Required always
      startDate: convertZonetoUTCStart(startDate), //Only required for resolved alerts  -utc time
      endDate: convertZonetoUTCStart(endDate),
      batteryFlag: [0],
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}
async function getTotalBatteries() {
  var config = {
    method: "get",
    url: `/api/total_alert_batteries`,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      batteryFlag: [0],
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}
async function getActiveAlerts() {
  var rawJson = {
    location: [],
    zone: [],
    voltConf: "",
    alertCode: "",
    batteryStatus: [],
    priority: "",
    alertState: "Active",
    batteryState: "",
    startDate: "",
    alertType: "",
    endDate: "",
    batteryID: "",
    partner: "",
    batteryType: "",
    batteryFlag: [0],
  };
  var config = {
    method: "get",
    url: "/api/alert_group_count/v2",
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getDevicePingStatus() {
  var config = {
    method: "get",
    url: `/api/metrics_iot`,
    headers: {
      "Content-Type": "application/json",
    },
    // params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getDevicePingStatusCSV(status) {
  let value;
  if (status === "All") {
    value = ["Offline", "Live", "Not Pinging"];
  }
  var rawJson = {
    batteryVendor: [],
    iotVendor: ["Wynncom", "Intellicar"],
    status: status === "All" ? value : [status],
  };
  var config = {
    method: "get",
    url: `/api/metrics_iot_csv`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
    // params: rawJson,
  };
  let data = GetData(config);
  return data;
}

export {
  sendRequestforPasswordReset,
  sendGetRequestforProfilePassReset,
  sendRequestforProfilePassReset,
  sendRequestforChangePassword,
  getIfLinkIsValid,
  getOpenAlerts,
  getSuperCriticalAlerts,
  getResolvedAlerts,
  sendPostRequestFeature,
  sendPostSendFeedback,
  postLogout,
  getLastUpdated,
  //landing
  getOrgInfo,
  getUserPrefrenceTimezone,
  putUserPrefrenceTimezone,
  getFourMetrics,
  getTotalBatteries,
  getConnectedBatteries,
  getActiveAlerts,
  getPagesMetaData,
  getPagesComponents,
  getAllToolbarBatteries,
  getDevicePingStatus,
  getDevicePingStatusCSV,
  getFilterData,
  getOrgTranslations
};
