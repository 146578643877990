import { SvgIcon } from "@mui/material";
import React from "react";

const ClockSvg = ({
  width = "19",
  height = "19",
  viewBox = "0 0 19 19",
  fill = "none",
  stroke = "#444B51",
  strokeWidth = 1.5,
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    sx={{ fill, display: "block" }}
    {...props}
  >
    <circle cx="9.5" cy="9.5" r="7.9167" stroke={stroke} />
    <path d="M9.5 4.75V9.5L12.6667 11.0833" stroke={stroke} />
  </SvgIcon>
);

export default ClockSvg;
