import React, { useEffect, useState } from 'react';
import { Popover, Typography, Box, Checkbox } from '@mui/material';
import {
	ActiveButtonBadge,
	CheckBoxFormControlLabel,
	PrimaryFormActionButton,
	PrimaryIconButton
} from '../TableStyles';

import RecordCountBox from '../../../../Components/RecordCountBox'

import { ColumnIconSvg } from '../../Icons/index'

import { useTranslation } from 'react-i18next';

const styles = {
	popoverPaper: {
		width: '15%',
		p: 1.5,
		borderRadius: '10px',
		backgroundColor: '#FFFFFF',
		boxShadow: '-2px -2px 4px 0px rgba(151, 151, 151, 0.25), 2px 2px 4px 4px rgba(151, 151, 151, 0.25)',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontFamily: 'Roboto',
		fontWeight: 700,
		fontSize: '14px',
		lineHeight: '24px',
		color: '#3D4B5A',
		px: 1,
	},
	footer: {
		p: 0,
		display: 'flex',
		justifyContent: 'flex-end',
	},
};


export default function TableColumns({
	initialColumns = [],
	selectedColumns,
	setSelectedColumns,
}) {

	const { t } = useTranslation(['common', 'generic_table']);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? 'filter-popover' : undefined;
	const [newColumns, setNewColumns] = useState(selectedColumns)

	let newColumnLength = newColumns ? newColumns.length : 0

	useEffect(() => {
		setNewColumns(selectedColumns)
	}, [selectedColumns])

	const handleClick = (event, key = null) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onApplyColumns = () => {
		setSelectedColumns(newColumns)
		setAnchorEl(null)
	}

	const handleCheckboxChange = (columnKey) => (event) => {
		setNewColumns((prevColumns) => {
			if (event.target.checked) {
				return prevColumns.includes(columnKey) ? prevColumns : [...prevColumns, columnKey];
			} else {
				return prevColumns.filter((col) => col !== columnKey);
			}
		});
	}


	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

			<ActiveButtonBadge variant="dot" overlap="circular" invisible={selectedColumns.length == initialColumns.length}>
				<PrimaryIconButton onClick={handleClick} active={open} >
					<ColumnIconSvg />
				</PrimaryIconButton>
			</ActiveButtonBadge>


			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				slotProps={{ paper: { sx: styles.popoverPaper } }}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box sx={styles.header}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<ColumnIconSvg sx={{ color: '#5E758D' }} />
							<Typography sx={{ mx: 1 }} variant="fw700s14lh24c3D4B5A">{t('generic_table:column_view.title')}</Typography>
							<RecordCountBox count={newColumnLength} />
						</Box>
					</Box>

					<Box sx={{ px: 0.5, py: 1 }}>
						{initialColumns.length > 0 && <Typography variant="fw500s10lh24c5E758D" >{t('generic_table:column_view.subtitle', { count: initialColumns.length })} </Typography>}
						<Box sx={{ height: '240px', overflow: 'auto' }}>
							{initialColumns.filter((columnConfig) => columnConfig.headerLabel).map((columnConfig, index) => (
								<CheckBoxFormControlLabel
									key={index}
									control={
										<Checkbox
											sx={{ color: '#9F9F9F' }}
											disabled={columnConfig.fixed !== undefined}
											checked={newColumns.includes(columnConfig.dataIndex)}
											onChange={handleCheckboxChange(columnConfig.dataIndex)}
										/>
									}
									label={<Typography variant={columnConfig.fixed !== undefined ? 'fw400s12lh24c989898' : 'fw400s12lh24c3D4B5A'}> {columnConfig.headerLabel || "No Label"} </Typography>}
								/>
							))}
						</Box>
					</Box>

					<Box sx={styles.footer}>
						<PrimaryFormActionButton onClick={onApplyColumns}>
							{t('actions.apply')}
						</PrimaryFormActionButton>
					</Box>
				</Box>
			</Popover>
		</Box>
	);
}