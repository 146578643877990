import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import Events from "../../../Analytics/Events";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/AlertAnalyticsApi";
import CODE from "../../../Static/Constants/StatusCodes";
import { findYAxisPartitionSizeForPositiveAxis, getXAxisDateString, getXAxisLabelForWeeklyGranularity, nextDivisibleBy } from "../../../Helper/Charts/ChartHelper";
import AnalyticsStackBarPlusLine from "../../../Charts/Mixed/AnalyticsStackBarPlusLine";
import { toTitleCase } from "../../../Helper/Formatters/TextFormatter";
import { getFilterData } from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import { decryptTheParams, encryptTheParams, updateQueryParams } from "../../../Helper/QueryParams/EncryptDecrypt";
import { formatDateWithoutYear } from "../../../Helper/DatePicker/DateFormatters";
import moment from "moment";

const defaultChartState = {
  series: {
    line: [{x: null, y: 1}, {x: null, y: 1}, {x: null, y: 2}, {x: null, y: 3}, {x: null, y: 4}, {x: null, y: 5}], 
    column:[{x: null, y: 1}, {x: null, y: 1}, {x: null, y: 2}, {x: null, y: 3}, {x: null, y: 4}, {x: null, y: 5}]
  },
  statusCode: CODE.LOADING
}

const numberOfYAxisPartition = 5

const linePlotYAxisSetting = {
  show: false
}


const FleetSeverityDistributionChart = ({ 
  filters, 
  startDate, 
  endDate, 
  granularity, 
  cardSubtitleTimePeriodText, 
  clickableHeader = false, 
  chartSelectedFilter = () => {} }) => {
  const navigate = useNavigate()
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const [lowSeverityAlertCount, setLowSeverityAlertCount] = useState(0)
  const [mediumSeverityAlertCount, setMediumSeverityAlertCount] = useState(0)
  const [highSeverityAlertCount, setHighSeverityAlertCount] = useState(0)
  const [cardFilterData, setCardFilterData] = useState({
    menuItemData: [],
    selectedItemId: null,
  })
  const [isCardFilterMounted, setIsCardFilterMounted] = useState(false)
  const isFilterMounted = useSelector((state) => state.IsFiltersMounted.value);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const yAxisPartitionSize = findYAxisPartitionSizeForPositiveAxis(lowSeverityAlertCount + mediumSeverityAlertCount + highSeverityAlertCount)
  const barPlotYAxisSetting = {
    title: {
      text: 'Alert Count',
      style: {
        color: "#87939D",
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      },
    },
    showAlways: true,
    axisBorder: {
      show: false,
      color: '#87939D',
      offsetX: 2.5,
      offsetY: 0
    },
    labels: {
      style: {
          colors: '#87939D',
          fontSize: '10px',
          fontFamily: 'Roboto',
          fontWeight: 500,
      },
    },
    tickAmount: 5,
    max: nextDivisibleBy(lowSeverityAlertCount + mediumSeverityAlertCount + highSeverityAlertCount, yAxisPartitionSize * numberOfYAxisPartition)
  }
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  useEffect(() => {
    fetchCardFilterData()
  }, [])

  useEffect(() => {
    if (isCardFilterMounted && isFilterMounted) {
      fetchData();
      chartSelectedFilter(isCardFilterMounted,cardFilterData.selectedItemId, 'alertMetaID')
    }
  }, [
    granularity,
    filters,
    cardFilterData.selectedItemId
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["lowSeverity", "mediumSeverity", "highSeverity"], startDate, endDate, granularity.granularity, {...filters, alertMetaID: [cardFilterData?.selectedItemId]}, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  const fetchCardFilterData = async () => {
    const filterConfig = {
      "queryParams": { "table": "alertMeta", "column": "alertDescription", "keyColumn": "id"}
    }
    const res = await getFilterData(filterConfig);
    if (res.responseStatus.code === CODE.SUCCESS) {
      const data = res.response.response
      const queryParamsCardFilterValue = decryptTheParams()?.fleetSeverityDistributionCardFilterValue 
      setCardFilterData({
        menuItemData: data.filterData,
        selectedItemId: queryParamsCardFilterValue ? parseInt(queryParamsCardFilterValue) : data.filterData?.[0]?.id
      })
      setIsCardFilterMounted(true)
    }
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    let lowSeverityAlertCount = 0;
    let mediumSeverityAlertCount = 0;
    let highSeverityAlertCount = 0;

    const series = {
      column: [
        {
          name: "High Severity",
          type: "bar",
          stacked: true,
          color: "#843307",
          data: response.data?.map(data => {
            const value = data.metricsArray.find(item => item.type == 'highSeverity')?.value
            highSeverityAlertCount = Math.max(highSeverityAlertCount, value)
            
            let x, dateRangeString
            if (granularity.granularity === 'daily') {
              const date = new Date(data.range.startDate);
              x = formatDateWithoutYear(date)
              dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
            } else {
              const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
              x = result.x;
              dateRangeString = result.dateRangeString;
            }
            return {
              x,
              y: value,
              dateRangeString
            }
          })
        },
        {
          name: "Medium Severity",
          type: "bar",
          stacked: true,
          color: "#FF7C35",
          data: response.data?.map(data => {
            const value = data.metricsArray.find(item => item.type == 'mediumSeverity')?.value
            mediumSeverityAlertCount = Math.max(mediumSeverityAlertCount, value)
            
            let x, dateRangeString
            if (granularity.granularity === 'daily') {
              const date = new Date(data.range.startDate);
              x = formatDateWithoutYear(date)
              dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
            } else {
              const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
              x = result.x;
              dateRangeString = result.dateRangeString;
            }
            return {
              x,
              y: value,
              dateRangeString
            }
          })
        },
        {
          name: "Low Severity",
          type: "bar",
          stacked: true,
          color: "#FFB891",
          data: response.data?.map(data => {
            const value = data.metricsArray.find(item => item.type === 'lowSeverity')?.value
            lowSeverityAlertCount = Math.max(lowSeverityAlertCount, value)
            
            let x, dateRangeString
            if (granularity.granularity === 'daily') {
              const date = new Date(data.range.startDate);
              x = formatDateWithoutYear(date)
              dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
            } else {
              const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
              x = result.x;
              dateRangeString = result.dateRangeString;
            }
            return {
              x,
              y: value,
              dateRangeString,
            };
          }),
        },
      ],
      line: null,
    };

    setLowSeverityAlertCount(lowSeverityAlertCount)
    setMediumSeverityAlertCount(mediumSeverityAlertCount)
    setHighSeverityAlertCount(highSeverityAlertCount)

    setChartState({
      series: series, 
      statusCode: responseStatus.code
    })
  }

  const generateCsvData = () => {
    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }

    const formatItem = (highSeverityAlertRow, mediumSeverityAlertRow, lowSeverityAlertRow) => ({
      "Date Range": highSeverityAlertRow.dateRangeString?.replace(/,/g, " "),
      "Low Severity Alert Count": lowSeverityAlertRow.y,
      "Medium Severity Alert Count": mediumSeverityAlertRow.y,
      "High Severity Alert Count": highSeverityAlertRow.y
    });

    const data = [
      ...chartState.series.column[0].data.map((highSeverityAlertData, index) => formatItem(
        highSeverityAlertData, 
        chartState.series.column[1].data[index],
        chartState.series.column[2].data[index],
      )),
    ];

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }

  const handleFilterChange = (event, selectedItem) => {
    setCardFilterData((prev) => ({
      ...prev,
      selectedItemId: selectedItem.props.value
    }))

    updateQueryParams(navigate, {fleetSeverityDistributionCardFilterValue: selectedItem.props.value})
  }

  const onClickHeader = () => {
    const temp = {
      chartName: 'fleetSeverityDistribution',
      startDate,
      endDate,
      fleetSeverityDistributionCardFilterValue: cardFilterData.selectedItemId
    };
    encryptTheParams(temp, navigate, false, '/AlertDrillDown', true);
  }
  
  return (
    <CardWithHeader
      id='fleetSeverityDistributionChart'
      headerClass="header-hover"
      title="Fleet Severity Distribution for each Alert"
      subtitle={`${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="barGraph" style={{ width: 21, height: 21 }}/>}
      pageLabel="Alert Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"fleetSeverityDistributionChart"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Alert Analytics: Hover over Fleet Severity Distribution for each Alert Chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{ height:"363px", position: "relative", paddingLeft: 0, paddingRight: chartState.statusCode === CODE.SUCCESS ? "22px" :  "35px" }}
      hasFilter
      filterData={cardFilterData}
      onFilterChange={handleFilterChange}
      clickableHeader={clickableHeader}
      onClickHeader={onClickHeader}
    >
      <AnalyticsStackBarPlusLine
        hideAlternateLabels={granularity.granularity === "daily" && chartState.series?.column?.[0]?.data?.length > 15}
        state={chartState.statusCode}
        stackBarPlotDataSeriesList={chartState.series.column}
        linePlotDataSeries={chartState.series.line}
        barPlotYAxisSetting={barPlotYAxisSetting}
        linePlotYAxisSetting={linePlotYAxisSetting}
        granularity={toTitleCase(granularity.granularity)}
      />
    </CardWithHeader>
  )
}



export default FleetSeverityDistributionChart;
