const selectedMetricsDataForVitalTab = [
  {
    label: "Current (A)",
    slug: "current",
    status: "active",
    checked: true,
    showInComparisonMode: true,
    yAxisDecimalPlaces: 0,
    color: "#7855FF",
    unit: "A",
    yMin: -20,
    yMax: 20
  },
  {
    label: "Voltage (V)",
    slug: "voltage",
    status: "active",
    checked: true,
    showInComparisonMode: true,
    yAxisDecimalPlaces: 0,
    color: "#FF7557",
    unit: "V",
    yMin: 20,
    yMax: 35
  },
  {
    label: "SOC (%)",
    slug: "soc",
    status: "active",
    checked: true,
    showInComparisonMode: true,
    yAxisDecimalPlaces: 0,
    color: "#F8BB3B",
    unit: "%",
    yMin: 0,
    yMax: 100
  },
  {
    label: "Max Temperature (°C)",
    slug: "temperature",
    status: "active",
    checked: true,
    showInComparisonMode: true,
    yAxisDecimalPlaces: 0,
    color: "#229087",
    unit: "°C",
    yMin: -20,
    yMax: 80
  },
  {
    label: "Capacity (Ah)",
    slug: "capacity",
    status: "inactive",
    checked: true,
    showInComparisonMode: true,
    yAxisDecimalPlaces: 0,
    color: "#3E5AAB",
    unit: "Ah",
    yMin: 0,
    yMax: 125
  },
  {
    label: "Energy Used (kWh)",
    slug: "energy",
    status: "inactive",
    checked: true,
    showInComparisonMode: true,
    yAxisDecimalPlaces: 0,
    color: "#84BCEF",
    unit: "kWh",
    yMin: 0,
    yMax: 4
  },
  {
    label: "Cell Imbalance (mV)",
    slug: "balanceState",
    status: "inactive",
    checked: true,
    showInComparisonMode: true,
    yAxisDecimalPlaces: 0,
    color: "#A75E6E",
    unit: "mV",
    yMin: 0,
    yMax: 250
  },
];

const maxHierarchySelectionLimit = 20

const chartColorCoding = [
  "#7855FF",
  "#FF7557",
  "#F8BB3B",
  "#30978F",
  "#84BCEF",
  "#A75E6E",
  "#55C780",
  "#3E5AAB",
  "#F4BEB4",
  "#1C1C1C",
  "#D4AC0D",
  "#FF5733",
  "#C70039",
  "#900C3F",
  "#581845",
  "#DAF7A6",
  "#FF8C00",
  "#FFD700",
  "#00CED1",
  "#FF1493" 
];

const multiplyFactors = {
  current: 1,
  voltage: 1,
  soc: 1,
  temperature: 1,
  capacity: 1,
  energy: 0.001,
  health: 1,
  balanceState: 1000,
};

const decimalPlacesForMetrics = {
  current: 2,
  voltage: 2,
  soc: 2,
  temperature: 2,
  capacity: 2,
  energy: 2,
  health: 1,
  balanceState: 3,
};

const disableMetricsForHierarchyLevel = {
  battery: [],
  pack: [],
  module: ["soc", "capacity", "energy"],
  cell: ["soc", "capacity", "balanceState", "energy"]
}

export {
  maxHierarchySelectionLimit,
  selectedMetricsDataForVitalTab,
  chartColorCoding,
  multiplyFactors,
  decimalPlacesForMetrics,
  disableMetricsForHierarchyLevel
}
