const dashboardClickablekpis = [
  {
    name: "All Batteries",
    nameKey: "kpi_header.all_batteries",
  },
];
const dashboardNonClickablekpis = [
  {
    name: "Total Batteries Connected",
    nameKey: "kpi_header.total_connected_batteries",
  }
];
export { dashboardClickablekpis, dashboardNonClickablekpis };
