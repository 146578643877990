import { SvgIcon } from "@mui/material";
import React from "react";

const ChartCarouselNextArrowIcon = ({
  width = "22",
  height = "22",
  viewBox = "0 0 22 22",
  fill = "#828A92",
  stroke = "none",
  strokeWidth = 1,
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    sx={{ fill, display: "block", ...props.sx }}
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8 0.25H14C19.43 0.25 21.75 2.57 21.75 8V14C21.75 19.43 19.43 21.75 14 21.75H8C2.57 21.75 0.25 19.43 0.25 14V8C0.25 2.57 2.57 0.25 8 0.25ZM14 20.25C18.61 20.25 20.25 18.61 20.25 14V8C20.25 3.39 18.61 1.75 14 1.75H8C3.39 1.75 1.75 3.39 1.75 8V14C1.75 18.61 3.39 20.25 8 20.25H14Z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <g clipPath="url(#clip1)">
        <path
          d="M9.74 6.72c.19 0 .38.07.53.22l3.53 3.53a.75.75 0 010 1.06l-3.53 3.53a.75.75 0 11-1.06-1.06L12.21 11 9.21 8a.75.75 0 011.06-1.06c.15-.15.34-.22.53-.22Z"
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="6" height="10" fill="white" transform="translate(8 6)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ChartCarouselNextArrowIcon;
