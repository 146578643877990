import React from "react"
import AlertFilterSvg from "./Icons/DigitalTwin/AlertFilterSvg"
import SortSvg from "./Icons/Menu/SortSvg"
import DotStatusSvg from "./Icons/DigitalTwin/DotStatusSvg"
import ChartCarouselPreviousArrowIcon from "./Icons/DigitalTwin/ChartCarouselPreviousArrowSvg"
import ChartCarouselNextArrowIcon from "./Icons/DigitalTwin/ChartCarouselNextArrowSvg"
import NavbarAlertIcon from "./Icons/Navbar/AlertSvg"
import NavbarDigitalTwinIcon from "./Icons/Navbar/DigitalTwinSvg"
import NavbarMapIcon from "./Icons/Navbar/MapIconSvg"
import NavbarDiagnosticsIcon from "./Icons/Navbar/DiagnosticsSvg"
import ToastInfoIcon from "./Icons/DigitalTwin/ToastIconInfoSvg"
import BatteryChargingStateIcon from "./Icons/DigitalTwin/BatteryChargingStateSvg"
import BatteryDischargingStateIcon from "./Icons/DigitalTwin/BatteryDischargingStateSvg"
import BatteryIdleStateIcon from "./Icons/DigitalTwin/BatteryIdleStateSvg"
import OfflineIcon from "./Icons/DigitalTwin/OfflineSvg"
import OnlineIcon from "./Icons/DigitalTwin/OnlineSvg"
import ImageIcon from "./Icons/DigitalTwin/ImageSvg"
import DocumentIcon from "./Icons/DigitalTwin/DocumentSvg"
import NavbarBatteryHealthIcon from "./Icons/Navbar/BatteryHealthSvg"
import HeartWithLine from "./Icons/BatteryHealth/HeartWithLine"
import HeartWithBattery from "./Icons/BatteryHealth/HeartWithBattery"
import ChartSvg from "./Icons/BatteryHealth/ChartSvg"
import DegradationSvg from "./Icons/BatteryHealth/DegradationSvg"
import ChargingAnalyticsSvg from "./Icons/Navbar/ChargingAnalyticsSvg"
import ChargerPlugSvg from "./Icons/ChargingAnalytics/ChargerPlugSvg"
import ClockSvg from "./Icons/ChargingAnalytics/Clock"
import EnergyEfficiencySvg from "./Icons/ChargingAnalytics/EnergyEfficiencySvg"
import ChargingFrequencySvg from "./Icons/ChargingAnalytics/ChargingFrequencySvg"
import LocationSvg from "./Icons/Filters/LocationSvg"
import SelectDownArrowSvg from "./Icons/Filters/SelectDownArrowSvg"
import SelectUpArrowSvg from "./Icons/Filters/SelectUpArrowSvg"
import CalendarIconSvg from "./Icons/Filters/CalendarIconSvg"
import BarGraphSvg from "./Icons/AlertAnalytics/BarGraphSvg"
import AlertAnalyticsSvg from "./Icons/Navbar/AlertAnalyticsSvg"
import SearchIconSvg from "./Icons/Filters/SearchIconSvg"
import CloseSidebarSvg from "./Icons/Navbar/CloseSidebarSvg"
import ExpandSidebarSvg from "./Icons/Navbar/ExpandSidebarSvg"
import CheckedBoxSvg from "./Icons/Filters/CheckedBoxSvg"
import UncheckedBoxSvg from "./Icons/Filters/UncheckedBoxSvg"

const CustomIcon = (props) => {
  const { name, fill, height, width, viewBox, stroke, isSelected, ...other } = props

  if (name === "alert-filter") {
    return (
      <AlertFilterSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    )
  }
  if (name === "sort") {
    return (
      <SortSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    )
  }
  if (name === 'dot') {
    return <OnlineIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'chartCarouselPreviousArrow') {
    return <ChartCarouselPreviousArrowIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'chartCarouselNextArrow') {
    return <ChartCarouselNextArrowIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'navbarAlerts') {
    return <NavbarAlertIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'navbarDigitalTwin') {
    return <NavbarDigitalTwinIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'navbarDiagnostics') {
    return <NavbarDiagnosticsIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'navbarMap') {
    return <NavbarMapIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'toastInfo') {
    return <ToastInfoIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'batteryChargingState') {
    return <BatteryChargingStateIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'batteryDischargingState') {
    return <BatteryDischargingStateIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'batteryIdleState') {
    return <BatteryIdleStateIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'navbarBatteryHealth') {
    return <NavbarBatteryHealthIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'offline') {
    return <OfflineIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === 'online') {
    return <OnlineIcon
      style={props.style}
      height={height}
      width={width}
      fill={fill}
      viewBox={viewBox}
      stroke={stroke}
      isSelected={isSelected}
    />
  }
  if (name === "downloadData") {
    return (
      <DocumentIcon
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "downloadImage") {
    return (
      <ImageIcon
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "healthWithLine") {
    return (
      <HeartWithLine 
        height={height}
        width={width}
      />
    );
  }
  if (name === "healthWithBattery") {
    return (
      <HeartWithBattery 
        height={height}
        width={width}
      />
    );
  }
  if (name === "chart") {
    return (
      <ChartSvg 
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "degradation") {
    return (
      <DegradationSvg 
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "chargingAnalytics") {
    return (
      <ChargingAnalyticsSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "chargerPlug") {
    return (
      <ChargerPlugSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "clock") {
    return (
      <ClockSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "energyEfficiency") {
    return (
      <EnergyEfficiencySvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "chargingFrequency") {
    return (
      <ChargingFrequencySvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "mapIcon") {
    return (
      <LocationSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "downArrow") {
    return (
      <SelectDownArrowSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "upArrow") {
    return (
      <SelectUpArrowSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "calendar") {
    return (
      <CalendarIconSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "barGraph") {
    return (
      <BarGraphSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "analytics") {
    return (
      <AlertAnalyticsSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "closeSidebar") {
    return (
      <CloseSidebarSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "expandSidebar") {
    return (
      <ExpandSidebarSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
        isSelected={isSelected}
      />
    );
  }
  if (name === "filterSearch") {
    return (
      <SearchIconSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
      />
    );
  }
  if (name === "checkedBox") {
    return (
      <CheckedBoxSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
      />
    );
  }
  if (name === "uncheckedBox") {
    return (
      <UncheckedBoxSvg
        style={props.style}
        height={height}
        width={width}
        fill={fill}
        viewBox={viewBox}
        stroke={stroke}
      />
    );
  }
}

export default CustomIcon
