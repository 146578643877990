import React, { useState, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from '@mui/material';

import {decryptTheParams} from "../Helper/QueryParams/EncryptDecrypt";

import { getDigitalTwinListSearch } from "../Api/DigitalTwinListApi";


export default function DeviceSearchInput({
    onSelect,
    ...props
}) {
    const batteryID = ""
    const [batteryList, setBatteryList] = useState([]);
    const [typedValue, setTypedValue] = useState("");
    const [open, setOpen] = React.useState(false);
    const [errorTextfield, setErrorTextField] = React.useState(false);

  useEffect(() => {
        getDigitalTwinListSearch(typedValue, 5).then((res) => {
          if (res.responseStatus.code === 200) {
            setBatteryList(res.response.data);
          } else {
            if (res.responseStatus.code === -2)
            setBatteryList([]);
          }
        });
  }, [typedValue]);

  useEffect(() => {
    if (batteryID === "") {
        setTypedValue("");
        setErrorTextField(false);
    }
  }, [ batteryID]);

  useEffect(
    () => () => {
      setBatteryList([]);
      setTypedValue("");
      setErrorTextField(false);
    },
    []
  );

  const handleOpen = (value) => {
    if (value.length > 2) {
      setOpen(true);
    }
  };

  const onTagsChange = (event, value) => {
    let temp;
    if (value !== null && batteryList.includes(value)) {
      let queryParams = decryptTheParams();
        temp = {
          ...queryParams,
          deviceID: value,
        };
      setErrorTextField(false);
      setOpen(false);
    }
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      if (batteryList.includes(e.target.value)) {
        onTagsChange(undefined, e.target.value);
        setErrorTextField(false);
      } else {
        setErrorTextField(true);
      }
    }
  }

  return (
    <>
      <Autocomplete
        filterSelectedOptions
        disableClearable
        freeSolo
        disablePortal
        id="combo-box-demo"
        options={batteryList.filter((item, i, ar) => ar.indexOf(item) === i)}
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        slotProps={{
          listbox: {
            sx: { fontSize: "14px"},
          },
        }}
        clearOnBlur={true}
        value={batteryID}
        inputValue={typedValue}
        onChange={(event, value, action, option) => {onSelect(value); setTypedValue("")}}
        onKeyDown={keyPress}
        onInputChange={(event, value) => {
          setTypedValue(value);
          setErrorTextField(false);
          handleOpen(value);
        }}
        getOptionLabel={(option) => option}
        forcePopupIcon={false}
        size="small"
        sx={{  width: "100%", p:0 }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={errorTextfield}
            placeholder={props.placeholder ? props.placeholder : "Search batteries"}
            autoFocus = {true}
            sx={{
                width: "100%",
                paddingLeft: '0px !important',
                "& fieldset": { border: "none"},
    
                "& .MuiInputBase-input": {
                    fontSize: "14px !important",
                    caretColor: "#5D5D5D",
                    paddingLeft: '0px !important',
                    "&::placeholder": {
                      fontWight: 400, 
                      fontSize: "14px",   
                      color: "#ADB5BE",  
                      opacity: 1, 
                    },
                }
            }}
          />
        )}
      />
    </>
  );
}
