import React from "react";
import { Box, Dialog, DialogContent, Typography, Button, styled, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {CancelFormActionButton, PrimaryFormActionButton} from './TableStyles'

const DialogContainer = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    textAlign: "center",
    borderRadius: 10,
    width: '436px'
  },
}));

const SelectionClearWarning = ({ open, onCancel, onProceed }) => {
  return (
    <DialogContainer open={open}>
        <IconButton size="small" onClick={onCancel} sx = {{
            width: 30,
            height: 30,
            backgroundColor: "#E5E5E5",
            textAlign: 'right',
            borderRadius: '50%',
            position: "absolute",
            right: 12,
            top: 12,
            "& .MuiSvgIcon-root": {
                color: "#2F2F2F",
                fontSize: "24px",
            },
        }}>
            <CloseIcon />
        </IconButton>

      <Box sx = {{p: 4}} >
        <Box>
            <Typography variant="fw600s15lh17c000000">
            Attention! Changing Table Settings
            </Typography>
        </Box>
        <Box sx={{ mt: 2.5 }}>
            <Typography variant="fw400s14lh19c000000" >
            You will lose selected batteries if you proceed to change filter settings.
            </Typography>
        </Box>
       

        <Box sx={{ mt: 3, display: "flex", justifyContent: "center"}}>
          <CancelFormActionButton onClick={onCancel}>Cancel</CancelFormActionButton>
          <PrimaryFormActionButton primary onClick={onProceed}>
            Proceed
          </PrimaryFormActionButton>
        </Box>
      </Box>
    </DialogContainer>
  );
};

export default SelectionClearWarning;
