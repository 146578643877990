import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

const options = {
  init: {
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    applicationID: "601542534"
  },
  info: {
    licenseKey: "NRJS-9901300c6f3dff82a36",
    applicationID: "601542534",
    agent: "601542534",
    trustKey: "6552865"
  },
  loader_config: {
    accountID: "6552865",
    trustKey: "6552865",
    agentID: "601542534",
    licenseKey: "NRJS-9901300c6f3dff82a36",
    applicationID: "601542534"
  }
}

const agent = new BrowserAgent(options);

console.log("New relic agent added", agent)

export default agent