import { SvgIcon } from "@mui/material";
import React from "react";

const CloseSidebarSvg = ({
  width = "21",
  height = "18",
  viewBox = "0 0 21 18",
  fill = "#9B9B9B",
  stroke = "",
  isSelected = false,
  style,
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    sx={{ fill, display: "block", ...style }}
    {...props}
  >
    <path d="M17.3234 8.82422H7.0293" stroke={fill} strokeWidth="1.47059" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.1764 13.9709L7.0293 8.82382L12.1764 3.67676" stroke={fill} strokeWidth="1.47059" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 2L1 16" stroke={fill} strokeWidth="1.47059" strokeLinecap="round" />
  </SvgIcon>
);

export default CloseSidebarSvg;
