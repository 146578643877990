const AppCache = {
    setItem: (key, value, persist = false) => {
      try {
        const isObject = typeof value === "object" && value !== null;
        localStorage.setItem(key, isObject ? JSON.stringify(value) : value);
      } catch (error) {
        console.error("Error setting item in localStorage", error);
      }
    },
  
    getItem: (key) => {
      if(key === "LocationFilter") {
        return AppCache.getLocationFilter(key)
      }

      try {
        const item = localStorage.getItem(key);
        if (item === null) return null;
        
        try {
          return JSON.parse(item);
        } catch {
          return item; // Return as string if parsing fails
        }
      } catch (error) {
        console.error("Error getting item from localStorage", error);
        return null;
      }
    },
  
    removeItem: (key) => {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error("Error removing item from localStorage", error);
      }
    },
  
    clearOnLogin: () => {
      try {
        Object.keys(localStorage).forEach((key) => {
            localStorage.removeItem(key);
        });
      } catch (error) {
        console.error("Error clearing cache on login", error);
      }
    },
  
    clearOnLogout: () => {
      try {
        Object.keys(localStorage).forEach((key) => {
            localStorage.removeItem(key);
        });
      } catch (error) {
        console.error("Error clearing cache on logout", error);
      }
    },

    // This is a special function we will remove that once all users has refreshed the data
    getLocationFilter: (key) => {
      try {
        const item = localStorage.getItem(key);
        if (item === null) return [];
        
        try {
          return JSON.parse(item);
        } catch {
          return item.split(',');
        }
      } catch (error) {
        console.error("Error getting item from localStorage", error);
        return [];
      }
    },
  };
  
  export default AppCache;
  