import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import CODE from "../../Static/Constants/StatusCodes";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";
import CustomIcon from "../../Components/CustomIcon"

import {
  SetDigitalTwinsColumns,
  SetDigitalTwinsConfigurationType,
  SetDigitalTwinsHiddenColumns,
  SetDigitalTwinsTabValue,
} from "../../Actions/DigitalTwinsList";

import Loader from "../../Helper/Loaders/Loader";
import {
  getMetricTimeStamp,
} from "../../Helper/DatePicker/DateConverter";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import TextWithCopyIcon from "../../Helper/Operations/TextWithCopyIcon";
import TextHyperLinkWithCopyIcon from "../../Helper/Operations/TextHyperLinkWithCopyIcon";

import { ThemeProperties } from "../../Theme/ThemeProperties";

import DigitalTwinsListMain from "./DigitalTwinListMain";
import info from "../../Assets/Images/Common/info.png";
import { batteryConfigurations } from "../../Static/Data/DigitalTwinList/BatteryConfigurations";
import { getPagesMetaData } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";
import AlertConfigurationMap from "../../Features/Table/GenericDraggableTable/Renderers/columnTypes/AlertConfigurationMap";

export default function DigitalTwinsList(props) {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = props?.permissions;
  const queryParams = decryptTheParams();
  const [pagesContent, setPagesContent] = useState({
    batteryID: "",
    partner: "",
    tabValue: 0,
    pageHeader: "",
    mounted: false,
  });

  useEffect(() => {
    if ('tabValue' in queryParams) {
      dispatch(SetDigitalTwinsTabValue(+queryParams['tabValue']));
    }
    getPageMeta();
  }, []);

  async function getPageMeta() {
    const pageName = 'digitalTwinList'
    const res = await getPagesMetaData(pageName)
    let allowAddDigitalTwin = true
    let allowAddBatteryConfiguration = true
    let filterConfigurations

    if (res && res?.responseStatus?.code === CODE.SUCCESS) {
      const pageData = res.response?.response
      const tablesData = pageData.metaInfo.table
      filterConfigurations = pageData.metaInfo?.filterConfigurations
      allowAddDigitalTwin = pageData.metaInfo?.allowAddDigitalTwin
      allowAddBatteryConfiguration = pageData.metaInfo.allowAddBatteryConfiguration
      
      initializeTableColumns(tablesData)
    } else if (res?.responseStatus?.code === CODE.SESSION_EXPIRED) {
      SetSessionExpired(true);
      return null;
    }

    setPagesContent({
      ...pagesContent,
      filterConfigurations: filterConfigurations,
      allowAddDigitalTwin: allowAddDigitalTwin,
      allowAddBatteryConfiguration: allowAddBatteryConfiguration,
      mounted: true
    })
  }

  function onBatteryIDClick(record) {
    let temp;
    temp = {
      ...queryParams,
      batteryID: record.batteryID,
      deviceID: record.deviceID,
      batterySearchedGlobal: "false",
      prevLink: location.pathname + location.search,
      navigateFromTab: true,
    };
    encryptTheParams(temp, navigate, true);
  }

  function onConfigIDClick(record) {
    if (props?.permissions?.includes("view-config")) {
      let temp = {
        ...queryParams,
        configID: record?.id,
        isEdit: true,
        isView: true,
        configName: record?.configName,
        application: record?.application
      };
      const batteryConfig = batteryConfigurations?.find(item => item?.value === record?.application)
      dispatch(SetDigitalTwinsConfigurationType(batteryConfig?.value));
      encryptTheParams(temp, navigate, false, "/DigitalTwin/add-config");
    }
  }

  function initializeTableColumns(tablesData) {
    const columnsConfig = [
      {
        name: "Digital Twins Columns",
        columns: getColumnsConfig(tablesData.digitalTwinListTable)
      },
      {
        name: "Battery Configurations Columns",
        columns: getColumnsConfig(tablesData.digitalTwinListBatteryConfigurationTable)
      }
    ]

    dispatch(SetDigitalTwinsColumns(columnsConfig));
    dispatch(SetDigitalTwinsHiddenColumns([]));
  }

  const getColumnsConfig = (tableData) => {
    const columnOrdering = tableData?.columnOrdering || []
    const columnsConfig = []
    columnOrdering.map((columnKey) => {
      const config = tableData.columnsConfig?.[columnKey]
      if (!config) {
        return null
      }
      columnsConfig.push({
        dataIndex: config?.dataIndex,
        title: titleProps => getColumnTitleFromTitleType(
          config.title, 
          titleProps, 
          config.titleRenderingType, 
          config?.dataIndex, 
          config?.sorter,
          config?.icon
        ),
        key: config?.key || columnKey,
        sorter: config?.sorter || false,
        defaultSortOrder: config?.defaultSortOrder || "",
        showSorterTooltip: config?.showSorterTooltip || false,
        sortDirections: config?.sortDirections,
        filterMode: config?.filterMode || "tree",
        filterSearch: config?.filterSearch || false,
        decimalPlaces: config?.decimalPlaces || 0,
        uniqueKey: columnKey+"UniqueKey",
        align: config?.align || "left",
        width: config?.width || 170,
        render: (value, record) => getColumnValueFromValueType(
          record,
          config
        ),
        show: config?.tabs,
      })
    })

    return columnsConfig
  }

  const getColumnTitleFromTitleType = (title, titleProps, type, key, sorter, icon) => {
    if (type === 'bold-text' && sorter === true) {
      return getColumnTitleSort(titleProps, key, title)
    }
    
    if (type === 'bold-text') {
      return <Typography variant="tableHeadBold">{title}</Typography>
    }

    if (type === "text-with-icon") {
      let iconComponent
      switch (icon?.type) {
        case "info":
        default:
          iconComponent = info
      }
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="tableHeadBold">{title}</Typography>
          <Tooltip
            placement={icon?.tooltip?.placement}
            title={
              <Box display="flex" flexDirection="column">
                {icon?.tooltip?.textLines?.map((line) => (
                  <h2>{line}</h2>
                ))}
              </Box>
            }
            zIndex={2000}
          >
            <IconButton>
              <img src={iconComponent} height={15} width={15} />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  }

  const getColumnValueFromValueType = (record, config) => {
    const value = record?.[config?.dataIndex];
    const decimalPlaces = config?.decimalPlaces
    const type = config?.valueRenderingType
    const copyToClipBoardTypeName = config?.copyToClipBoardTypeName

    switch(type) {
      case 'ping-status-icon':
        return <CustomIcon name={value === "Active" ? "online" : "offline"} style={{ width: "24px" }} />;
      case 'text':
        return <Typography variant="tableRow">{value}</Typography>;
      case 'text-with-copy':
        return <div className="renderID">{TextWithCopyIcon(value, copyToClipBoardTypeName)}</div>;
      case 'number':
        return <Typography variant="tableRow">{value?.toFixed(decimalPlaces)}</Typography>;
      case 'timestamp':
        return <Typography variant="tableRow">{getMetricTimeStamp(value)}</Typography>;
      case 'digital-twin-list-series-parallel':
        const level = config?.seriesParallelConfigLevel
        const tableValue = level === "pack" 
                            ? `${record?.seriesPacks}S / ${record?.parallelPacks}P` 
                            : level === "module"
                              ? `${record?.seriesModulesInPack}S / ${record?.parallelModulesInPack}P`
                              : `${record?.seriesModulesInPack * record?.seriesCellsInModule}S / ${record?.parallelModulesInPack * record?.parallelCellsInModule}P`

        return <Typography variant="tableRow">{tableValue}</Typography>;
      case 'digital-twin-list-past-current':
        return <Typography variant="tableRow">{record?.minCurrentPack !== null && record?.maxCurrentPack !== null ? `${record?.minCurrentPack} min / ${record?.maxCurrentPack} max` : ""}</Typography>;
      case 'digital-twin-list-past-voltage':
        return <Typography variant="tableRow">{record?.minVoltageCell !== null && record?.maxVoltageCell !== null ? `${record?.minVoltageCell} min / ${record?.maxVoltageCell} max` : ""}</Typography>;
      case 'digital-twin-list-past-temperature':
        return <Typography variant="tableRow">{record?.minTemperaturePack !== null && record?.maxTemperaturePack !== null ? `${record?.minTemperaturePack} min / ${record?.maxTemperaturePack} max` : ""}</Typography>;
      case 'digital-twin-list-edit':
        return (
          <div className="renderID">
            {props?.permissions?.includes("edit-twin") && (
              <IconButton
                onClick={() => {
                  let temp = {
                    ...queryParams,
                    id: record?.id,
                    isEdit: true,
                    batteryID: record?.batteryID,
                    deviceID: record?.deviceID,
                  };
                  encryptTheParams(temp, navigate, false, "/DigitalTwin/add-battery");
                  cookies.set("batteryID", record?.batteryID);
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            )}
          </div>
        );
      case 'battery-configuration-edit':
        return (
          <div>
            {props?.permissions?.includes("edit-config") && (
              <div className="renderID">
                <IconButton
                  onClick={() => {
                    let temp = {
                      ...queryParams,
                      configID: record?.id,
                      isEdit: true,
                      configName: record?.configName,
                      application: record?.application,
                    };
                    encryptTheParams(temp, navigate, false, "/DigitalTwin/add-config");
                    cookies.set("configID", record?.configID);
                    const batteryConfig = batteryConfigurations?.find(item => item?.value === record?.application);
                    dispatch(SetDigitalTwinsConfigurationType(batteryConfig?.value));
                  }
                }
                >
                  <EditOutlinedIcon />
                </IconButton>
              </div>
            )}
          </div>
        );

      case 'alert-configuration-map':
        return <AlertConfigurationMap config = {record} />
      case 'digital-twin-link':
        return <div className="renderID">{TextHyperLinkWithCopyIcon(value, "Device ID", onBatteryIDClick, record)}</div>;
      case 'battery-config-link':
        return <div className="renderID">{TextHyperLinkWithCopyIcon(value, "Config Name", onConfigIDClick, record)}</div>;
      default:
        return null;
    }
  }

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <DigitalTwinsListMain
            pagesContent={pagesContent}
            permissions={permissions}
          />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}
