import React from 'react';
import Grid2 from '@mui/material/Grid2';
import TableSearch from './TableSearch';
import EmailComposerDialog from './EmailComposerDialog';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Typography } from '@mui/material';
import { BatchActionIconButton, PrimaryActionButtonForTable } from './TableStyles';
import RecordCountBox from '../../../Components/RecordCountBox'
import { DownloadFileIcon, ExtraSettingsIcon } from '../Icons';
import { useTranslation } from 'react-i18next';


const TableHeaderAndActions = ({
    title,
    count,
    onPrimaryButtonClick,
    showPrimaryButton = true,
    primaryButtonLabel,
    onSearchApply,
    disableSearch,
    onSendEmail,
    disableDownloads = false,
    selectedRows = [],
    getCSVData = () => { },
    onDownloadCSV = () => { },
    availableEmailDomains = []
}) => {

    let isBatchActionButtonActive = selectedRows && selectedRows.length > 0
    const { t } = useTranslation(['common', 'generic_table'])

    return (
        <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>

            <Grid2 container direction="row" alignItems="center" spacing={2} sx={{ minHeight: '35px' }}>
                <Typography variant="fw500s16lh24c000000"> {title}</Typography>
                <RecordCountBox count={count} />

                {
                    showPrimaryButton && <PrimaryActionButtonForTable variant="outlined" color="primary" onClick={onPrimaryButtonClick} >
                        {primaryButtonLabel}
                    </PrimaryActionButtonForTable>
                }
            </Grid2>

            <Grid2
                container
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="flex-end"
                sx={{ flexGrow: 1, flexWrap: 'nowrap' }}>
                {
                    !disableSearch && <TableSearch onSearch={(selectedBatteries) => onSearchApply(selectedBatteries)} />
                }

                {
                    selectedRows.length > 0 && <Typography variant="fw600s14lh16c6D72F6">{selectedRows.length} {t('generic_table:row_selected')} </Typography>
                }

                {!disableDownloads && <BatchActionIconButton
                    onClick={onDownloadCSV}
                    active={isBatchActionButtonActive}
                >
                    <DownloadFileIcon fontSize="inherit" />
                </BatchActionIconButton>

                }

                {!disableDownloads && <EmailComposerDialog
                    availableEmailDomains={availableEmailDomains}
                    getCSVData={getCSVData}
                    selectedRows={selectedRows}
                    active={selectedRows.length > 0}
                    onSendEmail={onSendEmail}
                />
                }

                {false && <BatchActionIconButton onClick={() => { }} active={false} >
                    <ExtraSettingsIcon />
                </BatchActionIconButton>}

            </Grid2>

        </Grid2>
    );
};

export default TableHeaderAndActions;