import { Box, Typography } from "@mui/material";
import React from "react";
import BatteryHierarchySelect from "../../../../../Features/Menu/BatteryHierarchySelect";
import { Separator } from "../../../../../Components/CustomBox";
import { useTranslation } from "react-i18next";


const BatterySystemTab = (props) => {
  const {
    title,
    tabValue,
    hierarchy,
    hierarchySequence,
    onSelectHierarchy,
    alertsCountHierarchical,
    selectedHierarchy,
    defaultHierarchy,
    defaultHierarchyLevel,
    selectedHierarchyLevel,
    hierarchyLiveMetricData,
  } = props;
  const displayPackCount = hierarchy?.batteryConfig?.displayPackCount;
  const displayModuleCount = hierarchy?.batteryConfig?.displayModuleCount;
  const displayCellCount = hierarchy?.batteryConfig?.displayCellCount;
  const {t} = useTranslation(['common', 'digital_twin'])

  return (
    <Box
      style={{
        ...props.style,
      }}
    >
      <Typography
        style={{
          fontFamily: "Roboto",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#444B51",
          paddingBottom: "4px",
          marginLeft: "8px",
        }}
      >
        {title || t('digital_twin:battery_system')}
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Roboto",
          marginBottom: "4px",
          marginLeft: "8px",
        }}
      >
        {displayPackCount > 0 && (
          <Box
            style={{
              display: "flex",
            }}
          >
            <Typography
              style={{
                marginRight: "5px",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#87939D",
              }}
            >
              {t('digital_twin:packs')} 
            </Typography>
            <Typography
              style={{
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#444B51",
              }}
            >
              {displayPackCount}
            </Typography>
          </Box>
        )}
        {displayModuleCount > 0 && (
          <>
            <Separator />
            <Box
              style={{
                display: "flex",
              }}
            >
              <Typography
                style={{
                  marginRight: "5px",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#87939D",
                }}
              >
                {t('digital_twin:modules')}
              </Typography>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#444B51",
                }}
              >
                {(displayPackCount || 1) * displayModuleCount}
              </Typography>
            </Box>
          </>
        )}
        {displayCellCount > 0 && (
          <>
            <Separator />
            <Box
              style={{
                display: "flex",
                paddingRight: "10px",
              }}
            >
              <Typography
                style={{
                  marginRight: "5px",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#87939D",
                }}
              >
                
                {t('digital_twin:cells')}
              </Typography>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#444B51",
                }}
              >
                {(displayPackCount || 1) *
                  (displayModuleCount || 1) *
                  (displayCellCount || 1)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {hierarchy && (
        <BatteryHierarchySelect
          key={tabValue}
          hierarchy={hierarchy}
          selectedHierarchy={selectedHierarchy}
          hierarchyLiveMetricData={hierarchyLiveMetricData}
          defaultHierarchy={defaultHierarchy}
          hierarchySequence={hierarchySequence}
          defaultHierarchyLevel={defaultHierarchyLevel}
          selectedHierarchyLevel={selectedHierarchyLevel}
          style={{
            marginTop: "12px",
          }}
          onChange={onSelectHierarchy}
          alertsCountHierarchical={alertsCountHierarchical}
        />
      )}
    </Box>
  );
};

export default BatterySystemTab;
