import React, { useState } from "react"
import GenericFilterContainer from "../Features/Menu/GenericFilterContainer";

function AccordionTypeGenericFilter({ filtersConfig }) {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (value) => {
    setExpanded(value);
  };

  return <GenericFilterContainer
    filterType="accordionMultiSelect"
    showResetButton={false}
    filtersConfig={filtersConfig}
    handleAccordionChange={handleChange}
    expandedAccordion={expanded}
  />
}

export default AccordionTypeGenericFilter
