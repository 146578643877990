import React from "react";
import { OuterBox, InnerBox, FilterAndSearchContainer, FlexStartBox, FullWidthSeparator, BatteryHealthLoaderBox, BatteryHealthTabContentLoaderBox } from "../../Components/CustomBox";
import { PaperWithBottomRadius } from "../../Components/CustomPaper";
import BatteryHealthTabs from "../../Static/Data/BatteryHealth/BatteryHealthTabs";
import BasicTabs from "../../Features/Tabs/BasicTabs";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import GenericSelectRedux from "../../Features/Menu/GenericSelectRedux";
import AutocompleteDashboard from "../../Features/Autocomplete/AutocompleteDashboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Events from "../../Analytics/Events";
import LandingOverview from "../../PagesContent/BatteryHealth/Overview/LandingOverview";
import { decryptTheParams,encryptTheParams, updateQueryParams} from "../../Helper/QueryParams/EncryptDecrypt";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import "../../Features/Toast/NewNotification.css"
import { MapNonClickablekpis } from "../../Static/Data/Map/MapKPIS";
import GenericFilterContainer from "../../Features/Menu/GenericFilterContainer";
import { SetFilterValues } from "../../Actions/Filters"
import { SetSearchType } from "../../Actions/Search";
import { useNotification } from "../../Hooks/useNotification"
import { SetBatteryHealthEqCycle, SetBatteryHealthSOH } from "../../Actions/BatteryHealth";
import BatteryOverview from "../../PagesContent/BatteryHealth/Overview/BatteryOverview";
import Loader from "../../Helper/Loaders/Loader";
import { useTranslation } from "react-i18next";

const FilterBox = ({ 
  pagesContent, 
  onChangeSearchType, 
  onClearSearch, 
  openNotification, 
  handleFilterSelection, 
  onResetFilters
}) => {
  const searchType = useSelector((state) => state.SelectedSearchType.value);
  return (
    <FilterAndSearchContainer>
      <FlexStartBox>
        <GenericSelectRedux
          filter={pagesContent.searchSelectConfig}
          handleSetFilter={onChangeSearchType}
          selected={searchType}
          maxWidth={"max-content"}
          showClearButton={false}
        />
        <AutocompleteDashboard
          width={300}
          onClearSearch={onClearSearch}
          tabValue={0}
          page={pagesContent.pageName}
          openNotification={openNotification}
        />
      </FlexStartBox>
      <GenericFilterContainer
        filtersConfig={pagesContent.filterConfigurations?.batteryHealthTable?.filters}
        onSelectFilter={handleFilterSelection}
        onResetFilters={onResetFilters}
      />
    </FilterAndSearchContainer>
  );
}

const TabContent = ({ pagesContent, tabValue, searchID }) => {
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);

  if (Object.keys(defaultFilterValues || {}).length === 0) {
    return <BatteryHealthTabContentLoaderBox>
    <Loader />
  </BatteryHealthTabContentLoaderBox>
  }

  switch (tabValue) {
    case 0:
      return searchID === "" ? <LandingOverview pagesContent={pagesContent.pageMeta}/> : <BatteryOverview pagesContent={pagesContent.pageMeta}/>
    default:
      return(<></>)
  }
}

const BatteryHealthMain = ({ pagesContent }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation(['common', 'health'])
  const [tabValue, setTabValue, refTabValue] = useState(0);
  const { openNotification } = useNotification();
  const searchID = useSelector((state) => state.SelectedSearchID.value);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);

  const BatteryHealthTabs = [
    {
        id:1,
        name: t(`health:tabs.overview`),
        color:"#000"
    }
  ]

  const changeTheTab = (newValue) => {
    setTabValue(newValue);
  }

  const onClearSearch = () => {
    dispatch(SetBatteryHealthEqCycle(""));
    dispatch(SetBatteryHealthSOH(""));
    dispatch(SetFilterValues(defaultFilterValues))
    updateQueryParams(navigate, {
      sohLevel: "",
      selectedSearchID: "",
      filters: ""
    })
  }

  const onResetFilters = () => {
    dispatch(SetBatteryHealthEqCycle(""));
    dispatch(SetBatteryHealthSOH(""));
    Events("Battery Health Page: Clicked Battery Health Reset Filter");
  }

  const onChangeSearchType = (dropdownName, value) => {
    let temp;
    const queryParams = decryptTheParams();

    temp = {
      ...queryParams,
      selectedSearchType: value
    };
    dispatch(SetSearchType(value))

    if(searchID !== "") {
      openNotification("bottomLeft",`Resetting all the filters.`);
    }

    encryptTheParams(temp,navigate);
  }

  const handleFilterSelection = (key, label, values) => {
    Events("Battery Health Page: Changed BatteryHealth Filter " + label);
  }

  return (
    <OuterBox>
      <InnerBox>
        <PageHeaderKPI
          sx={{ mt: 0.5 }}
          name={t(`health:page_header`, pagesContent.pageHeader)}
          nonClickableKpis={MapNonClickablekpis}
        />
        <BasicTabs
          ChangeTheTab={changeTheTab}
          tabs={BatteryHealthTabs}
          tabValue={refTabValue}
        />
        <BasicTabPanel sx={{ mt: 1 }} value={tabValue} index={tabValue} key={tabValue}>
          <PaperWithBottomRadius elevation={0}>
            <FilterBox 
              pagesContent={pagesContent}
              onChangeSearchType={onChangeSearchType}
              onClearSearch={onClearSearch}
              openNotification={openNotification}
              handleFilterSelection={handleFilterSelection}
              onResetFilters={onResetFilters}
            />
            <FullWidthSeparator />
            <br></br>
            <TabContent pagesContent={pagesContent} tabValue={tabValue} searchID={searchID}/>
          </PaperWithBottomRadius>
        </BasicTabPanel>
      </InnerBox>
    </OuterBox>
  );
}

export default BatteryHealthMain;
