import { SvgIcon } from "@mui/material";
import React from "react";

const ChargingFrequencySvg = ({
  fill = "none",
  stroke = "",
  width = "30",
  height = "25",
  viewBox = "0 0 30 25",
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    sx={{ fill, display: "block" }}
    {...props}
  >
    <g clipPath="url(#clip0_340_9270)">
      <path d="M6 0.390625L6 4.6875L0.374999 4.6875C0.168749 4.6875 -8.80204e-07 4.86328 -8.70813e-07 5.07813C-8.61422e-07 5.29297 0.168749 5.46875 0.374999 5.46875L6 5.46875L6 12.1094L0.374999 12.1094C0.168749 12.1094 -5.55783e-07 12.2852 -5.46392e-07 12.5C-5.37001e-07 12.7148 0.168749 12.8906 0.375 12.8906L6 12.8906L6 19.5312L0.375 19.5312C0.16875 19.5312 -2.31363e-07 19.707 -2.21972e-07 19.9219C-2.12581e-07 20.1367 0.16875 20.3125 0.375 20.3125L6 20.3125L6 24.6094C6 24.8242 6.16875 25 6.375 25C6.58125 25 6.75 24.8242 6.75 24.6094L6.75 20.3125L23.625 20.3125C23.8313 20.3125 24 20.1367 24 19.9219C24 19.707 23.8313 19.5312 23.625 19.5312L6.75 19.5312L6.75 12.8906L23.625 12.8906C23.8313 12.8906 24 12.7148 24 12.5C24 12.2852 23.8313 12.1094 23.625 12.1094L6.75 12.1094L6.75 5.46875L23.625 5.46875C23.8313 5.46875 24 5.29297 24 5.07813C24 4.86328 23.8313 4.6875 23.625 4.6875L6.75 4.6875L6.75 0.390625C6.75 0.175781 6.58125 -2.87676e-07 6.375 -2.7866e-07C6.16875 -2.69645e-07 6 0.175781 6 0.390625Z" fill="#444B51"/>
    </g>
    <defs>
      <clipPath id="clip0_340_9270">
        <rect width="25" height="30" fill="white" transform="translate(0 25) rotate(-90)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ChargingFrequencySvg;
