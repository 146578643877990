import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'universal-cookie';

import enCommon from './lang/en/common.json';
import enGenericTable from './lang/en/generic_table.json';
import enInventory from './lang/en/inventory.json';
import enAlerts from './lang/en/alerts.json';
import enHealth from './lang/en/health.json';
import enDigitalTwin from './lang/en/digital_twin.json';


import frCommon from './lang/fr/common.json';
import frInventory from './lang/fr/inventory.json';
import frGenericTable from './lang/fr/generic_table.json';
import frAlerts from './lang/fr/alerts.json';
import frHealth from './lang/fr/health.json';
import frDigitalTwin from './lang/fr/digital_twin.json';

import ptCommon from './lang/pt/common.json';
import ptInventory from './lang/pt/inventory.json';
import ptGenericTable from './lang/pt/generic_table.json';
import ptAlerts from './lang/pt/alerts.json';
import ptHealth from './lang/pt/health.json';
import ptDigitalTwin from './lang/pt/digital_twin.json';

import esCommon from './lang/es/common.json';
import esInventory from './lang/es/inventory.json';
import esGenericTable from './lang/es/generic_table.json';
import esAlerts from './lang/es/alerts.json';
import esHealth from './lang/es/health.json';
import esDigitalTwin from './lang/es/digital_twin.json';

import { getOrgTranslations } from './Api/Api';



const cookies = new Cookies();
const language = cookies.get('lang') || 'en';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                common: enCommon,
                inventory: enInventory,
                generic_table: enGenericTable,
                alerts: enAlerts,
                health: enHealth,
                digital_twin: enDigitalTwin
            },

            pt: {
                common: ptCommon,
                inventory: ptInventory,
                generic_table: ptGenericTable,
                alerts: ptAlerts,
                health: ptHealth,
                digital_twin: ptDigitalTwin
            },

            fr: {
                common: frCommon,
                inventory: frInventory,
                generic_table: frGenericTable,
                alerts: frAlerts,
                health: frHealth,
                digital_twin: frDigitalTwin
            },

            es: {
                common: esCommon,
                inventory: esInventory,
                generic_table: esGenericTable,
                alerts: esAlerts,
                health: esHealth,
                digital_twin: esDigitalTwin
            },
        },
        lng: language,
        fallbackLng: 'en',
        ns: ['common', 'generic_table', 'inventory', 'alerts', 'health', 'digital_twin'],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false,
        },
    });


export const mergeUserTranslations = async (userId) => {
    try {
        const response = await getOrgTranslations()

        if (response.responseStatus.code !== 200) {
            console.log("hello", response.responseStatus.msg)
            throw new Error(response.responseStatus.msg)
        }

        const data = response.response.data ? response.response.data : {};
        const { language, translations } = data;

        if (!language || !translations) {
            return;
        }

        Object.keys(translations).forEach((namespace) => {
            const existingTranslations = i18n.hasResourceBundle(language, namespace)
                ? i18n.getResourceBundle(language, namespace)
                : {};

            const mergedTranslations = {
                ...existingTranslations,
                ...translations[namespace],
            };


            i18n.addResourceBundle(language, namespace, mergedTranslations, true, true);
        });

        console.log(`User-specific translations for ${language} have been loaded.`);
    } catch (error) {
        console.error('Error loading user-specific translations:', error);
    }
};


export default { i18n };