import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import { geDateRangesForCk } from "../../Helper/DatePicker/DateConverter";
import dayjs from 'dayjs';
import { rangePresets } from "../../Static/Data/Analytics/Analytics";
import { ChartSectionContainer } from "../AlertDrillDown/Components/StyledComponent";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { Box } from "@mui/material";
import EnergyConsumedChart from "../ChargingAnalytics/Charts/EnergyConsumedChart";
import { calculateGranularity } from "../../Helper/Analytics/AnalyticsHelper";
import TotalChargingTimeChart from "../ChargingAnalytics/Charts/TotalChargingTimeChart";
import EnergyEfficiencyChart from "../ChargingAnalytics/Charts/EnergyEfficiencyChart";

function ChartSection({ granularity, setGranularity, handleChartFilter = () => {} }) {
  const defaultStartDate = geDateRangesForCk()[0]
  const defaultEndDate = geDateRangesForCk()[1]
  const calendarStartDate = useSelector((state) => state.ChargingAnalyticsStartDate.value) || defaultStartDate
  const calendarEndDate = useSelector((state) => state.ChargingAnalyticsEndDate.value) || defaultEndDate
  const [cardSubtitleTimePeriodText, setCardSubtitleTimePeriodText] = useState("")
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value)
  const queryParams = decryptTheParams()

  useEffect(() => {
    setGranularity({granularity: calculateGranularity(calendarStartDate, calendarEndDate, granularity.granularity, 'monthly')})
    calculateCardSubtitleTimePeriodText()
  },[calendarStartDate, calendarEndDate])

  const calculateCardSubtitleTimePeriodText = () => {
    const matchedPreset = rangePresets.find((preset) => {
      return preset.value[0].format("YYYY-MM-DD") === calendarStartDate && preset.value[1].format("YYYY-MM-DD") === calendarEndDate
    })

    if (matchedPreset) {
      setCardSubtitleTimePeriodText(`${matchedPreset?.label}`)
    } else {
      setCardSubtitleTimePeriodText(`${dayjs(calendarStartDate).format("D MMM, YYYY")} - ${dayjs(calendarEndDate).format("D MMM, YYYY")}`)
    }
  }

  let ChartComponent = Box

  switch(queryParams?.chartName) {
    case "energyConsumed":
      ChartComponent = EnergyConsumedChart
      break;
    case "chargingTime":
      ChartComponent = TotalChargingTimeChart
      break;
    case "energyEfficiency":
      ChartComponent = EnergyEfficiencyChart
      break;
  }


  return (
    <ChartSectionContainer>
      <ChartComponent chartSelectedFilter={handleChartFilter} cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
    </ChartSectionContainer>
  )
}


export default ChartSection
