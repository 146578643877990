import React from "react"
import HeartBeat from "../../../Assets/Icons/BatteryHealth/HeartBeat.png"

const HeartWithLine = ({
  width = "19px",
  height = "19px",
  ...props
  
}) => {
  return (
    <img 
      src={HeartBeat} 
      alt="Description" 
      style={{ width, height }}
    />
  )
}


export default HeartWithLine
