import { SvgIcon } from "@mui/material";
import React from "react";

export const ExtraSettingsIcon = (props) => (
    <SvgIcon viewBox="0 0 31 30" {...props}>
      <circle cx="15.5723" cy="15" r="15" fill="none" />
      <path
        d="M15.5703 15.625C15.9155 15.625 16.1953 15.3452 16.1953 15C16.1953 14.6548 15.9155 14.375 15.5703 14.375C15.2251 14.375 14.9453 14.6548 14.9453 15C14.9453 15.3452 15.2251 15.625 15.5703 15.625Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5703 11.25C15.9155 11.25 16.1953 10.9702 16.1953 10.625C16.1953 10.2798 15.9155 10 15.5703 10C15.2251 10 14.9453 10.2798 14.9453 10.625C14.9453 10.9702 15.2251 11.25 15.5703 11.25Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5703 19.999C15.9155 19.999 16.1953 19.7192 16.1953 19.374C16.1953 19.0288 15.9155 18.749 15.5703 18.749C15.2251 18.749 14.9453 19.0288 14.9453 19.374C14.9453 19.7192 15.2251 19.999 15.5703 19.999Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
  

export const DownloadFileIcon = (props) => (
  <SvgIcon viewBox="0 0 30 30" {...props}>
    <circle cx="15" cy="15" r="15" fill="none" />
    <g clipPath="url(#clip0_644_14800)" fill="none">
      <path
        d="M20.5703 16.6484V19.2214C20.5703 19.5625 20.4348 19.8898 20.1935 20.131C19.9523 20.3723 19.625 20.5078 19.2839 20.5078H10.2786C9.93746 20.5078 9.61024 20.3723 9.36898 20.131C9.12772 19.8898 8.99219 19.5625 8.99219 19.2214V16.6484"
        stroke="currentColor"
        strokeWidth="1.32412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5638 13.4316L14.7799 16.6478L17.9961 13.4316"
        stroke="currentColor"
        strokeWidth="1.32412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7812 16.6484V8.92969"
        stroke="currentColor"
        strokeWidth="1.32412"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);

export const BatchEmailIcon = (props) => (
  <SvgIcon viewBox="0 0 31 30" {...props}>
    <circle cx="15.2852" cy="15" r="15" fill="none" />
    <g clipPath="url(#clip0_644_14800)" fill="none">
      <path
        d="M10.2852 10H20.2852C20.9727 10 21.5352 10.5625 21.5352 11.25V18.75C21.5352 19.4375 20.9727 20 20.2852 20H10.2852C9.59766 20 9.03516 19.4375 9.03516 18.75V11.25C9.03516 10.5625 9.59766 10 10.2852 10Z"
        stroke="currentColor"
        strokeWidth="1.38158"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5352 11.25L15.2852 15.625L9.03516 11.25"
        stroke="currentColor"
        strokeWidth="1.38158"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);


export const FilterIconSvg = (props) => (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.69575 5.91375L13.9111 0.538396C14.006 0.441108 14.0265 0.305103 13.965 0.18948C13.9029 0.0738542 13.7699 0 13.6235 0H0.376485C0.230051 0 0.0970863 0.0738581 0.0350077 0.18948C-0.0264951 0.305106 -0.00599414 0.441107 0.0888961 0.538396L5.30425 5.91375C5.36341 5.97284 5.39504 6.04976 5.39269 6.1287V11.832C5.39094 11.8962 5.43252 11.9553 5.49871 11.9833C5.56548 12.0118 5.64456 12.0032 5.70138 11.9624L8.60723 9.88675V6.1287C8.60489 6.04975 8.63659 5.97284 8.69575 5.91375Z"/>
    </SvgIcon>
);

export const ColumnIconSvg = (props) => (
  <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
    <path d="M3.15755 0.000553449C3.80189 0.000553449 4.32422 0.522887 4.32422 1.16722V10.8339C4.32422 11.4782 3.80189 12.0006 3.15755 12.0006H1.49089C0.846552 12.0006 0.324219 11.4782 0.324219 10.8339V1.16722C0.324219 0.555107 0.795625 0.0530935 1.3952 0.00442012L1.49089 0.000553449H3.15755ZM12.5083 0.000553449C13.1526 0.000553449 13.6749 0.522887 13.6749 1.16722V10.8339C13.6749 11.4782 13.1526 12.0006 12.5083 12.0006H10.8416C10.1973 12.0006 9.67493 11.4782 9.67493 10.8339V1.16722C9.67493 0.522887 10.1973 0.000553449 10.8416 0.000553449H12.5083ZM7.82419 0C8.46852 0 8.99086 0.522333 8.99086 1.16667V10.8334C8.99086 11.4777 8.46852 12 7.82419 12H6.15753C5.51322 12 4.99089 11.4777 4.99089 10.8334V1.16667C4.99089 0.522333 5.51322 0 6.15753 0H7.82419Z"/>
  </SvgIcon>
)

export const SearchIcon = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
      <rect width="16.875" height="16.875" transform="translate(0.5 0.5625)" fill="white" fill-opacity="0.01"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8268 11.8762L15.7818 14.8837C15.9142 15.0268 15.9857 15.2158 15.9811 15.4107C15.9765 15.6056 15.8962 15.791 15.7573 15.9277C15.6183 16.0644 15.4316 16.1417 15.2367 16.1431C15.0418 16.1445 14.854 16.0699 14.7131 15.9352L11.7596 12.9307C10.62 13.7892 9.19673 14.1826 7.77802 14.0312C6.35931 13.8797 5.05116 13.1949 4.11843 12.1152C3.1857 11.0355 2.69811 9.64175 2.75438 8.21609C2.81065 6.79043 3.40658 5.43943 4.4215 4.43663C5.43642 3.43384 6.79449 2.85419 8.22072 2.81505C9.64695 2.77592 11.0348 3.28023 12.1031 4.22586C13.1715 5.1715 13.8406 6.48779 13.975 7.90822C14.1094 9.32865 13.6989 10.7471 12.8268 11.8762ZM8.37482 12.5625C9.46883 12.5625 10.518 12.1279 11.2916 11.3543C12.0652 10.5807 12.4998 9.53152 12.4998 8.4375C12.4998 7.34348 12.0652 6.29427 11.2916 5.52068C10.518 4.7471 9.46883 4.3125 8.37482 4.3125C7.2808 4.3125 6.23159 4.7471 5.458 5.52068C4.68441 6.29427 4.24982 7.34348 4.24982 8.4375C4.24982 9.53152 4.68441 10.5807 5.458 11.3543C6.23159 12.1279 7.2808 12.5625 8.37482 12.5625Z" />
  </SvgIcon>
)

export const SortIconUp = (props) => (
  <SvgIcon width="9" height="18" viewBox="0 0 9 18" {...props}>
      <path d="M4.24019 0.95C4.35566 0.75 4.64434 0.75 4.75981 0.95L8.13731 6.8C8.25278 7 8.10844 7.25 7.8775 7.25H1.1225C0.89156 7.25 0.747223 7 0.862693 6.8L4.24019 0.95Z"/>
  </SvgIcon>
)

export const SortIconDown = (props) => (
  <SvgIcon width="9" height="18" viewBox="0 0 9 18" {...props}>
      <path d="M4.75981 17.05C4.64434 17.25 4.35566 17.25 4.24019 17.05L0.862694 11.2C0.747224 11 0.891562 10.75 1.1225 10.75H7.8775C8.10844 10.75 8.25278 11 8.13731 11.2L4.75981 17.05Z"/>
  </SvgIcon>
)
