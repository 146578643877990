import React, { useEffect, useMemo, useState } from "react";
import {
	SetDigitalTwinsPageNumber,
	SetDigitalTwinsTabValue,
	SetDigitalTwinsTotal,
	SetDigitalTwinsListOfBatteries,
} from "../../Actions/DigitalTwinsList";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import { Box } from "@mui/material";
import { notification } from "antd";
import { InnerBox, OuterBox } from "../../Components/CustomBox";
import { useDispatch, useSelector } from "react-redux";
import {
	decryptTheParams,
	encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import TabWithColorDigitalTwins from "../../Features/Tabs/TabWithColorDigitalTwins";
import AddBatteryDialog from "../../Features/Dialog/DigitalTwin/AddBatteryConfigDialog";
import Events from "../../Analytics/Events";
import {
	getDigitalTwinConfigList,
	getDigitalTwinList,
} from "../../Api/DigitalTwinListApi";
import { SetSessionExpired } from "../../Actions";
import InventoryList from "./InventoryList";
import { getConnectedBatteries } from "../../Api/Api";
import { useTranslation } from 'react-i18next';

const Context = React.createContext({
	name: "Default",
});

export default function InventoryMain(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const queryParams = decryptTheParams();
	const [api, contextHolder] = notification.useNotification();
	const tabvalue = useSelector((state) => state.DigitalTwinsTabValue.value);

	const [mounted, setMounted] = useState(true);
	const [selectedFilters, setSelectedFilters] = useState(queryParams.filters ? JSON.parse(queryParams.filters) : {})
	const [listOfBatteries, setListOfBatteries] = useState({
		batteries: [],
		responseStatus: { code: -1, msg: "" },
	});

	const [digitalTwinsCount, setDigitalTwinsCount] = useState(null);
	const [digitalTwinConfigsCount, setDigitalTwinConfigsCount] = useState(null);
	const newColumns = useSelector((state) => state.DigitalTwinsColumns.value);
	const [connectedBatteries, setConnectedBatteries] = useState(0);
	const [isLoading, setIsLoading] = useState(true)

	const pageNumber = useSelector((state) => state.DigitalTwinsPageNumber.value);
	const rowsPerPage = useSelector(
		(state) => state.DigitalTwinsRowsPerPage.value
	);
	const [sorting, setSorting] = useState([])

	const [searchTerm, setSearchTerm] = useState(null)

	const applySelectedFilters = (selectedFilters) => {
		dispatch(SetDigitalTwinsPageNumber(1))
		let temp = {
			...queryParams,
			filters: JSON.stringify({ ...selectedFilters })
		};

		encryptTheParams(temp, navigate);
		setSelectedFilters(selectedFilters)
	}

	useEffect(() => {
		getConnectedBatteries().then((res) => {
			if (res.responseStatus.code === 200) {
				setConnectedBatteries(res.response.data[0]["count"]);
			} else if (res.responseStatus.code === -2) {
				dispatch(SetSessionExpired(true));
			}
		});
	}, [])

	useEffect(() => {
		if (pageNumber === 1) {
			GetListOfBatteries();
		} else {
			dispatch(SetDigitalTwinsPageNumber(1));
		}

	}, [tabvalue, selectedFilters, searchTerm, sorting[tabvalue]]);

	useEffect(() => {
		GetListOfBatteries();
	}, [pageNumber]);

	const batteryID = null;

	async function GetListOfBatteries() {
		listOfBatteries.responseStatus.code !== -1 &&
			setListOfBatteries({
				batteries: [],
				responseStatus: { code: -1, msg: "" },
			});

		setIsLoading(true)
		if (tabvalue === 0) {
			const sortBy = sorting[0]?.sortBy;
			const orderBy = sorting[0]?.orderBy;

			await getDigitalTwinList({
				filters: selectedFilters,
				pageNumber,
				rowsPerPage,
				sortBy,
				orderBy,
				batteryID,
				searchID: searchTerm,
				startDate: null,
				endDate: null,
			}).then((res) => {
				if (res.responseStatus.code === 200) {
					setListOfBatteries({
						batteries: res.response.data,
						responseStatus: res.response.responseStatus,
						pages: res.response.total
					});
					dispatch(SetDigitalTwinsListOfBatteries(res.response.data));
					dispatch(SetDigitalTwinsTotal(res.response.total?.total));
					setDigitalTwinsCount(res.response?.total?.total);
				} else {
					if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
					setListOfBatteries({
						batteries: [],
						pages: res.response.total,
						responseStatus: {
							code: res.responseStatus?.code,
							msg: res.responseStatus?.msg,
						},
					});
					dispatch(SetDigitalTwinsTotal(0));
					setDigitalTwinsCount(0);
				}
			});
		}
		if (tabvalue === 1) {
			const sortBy = sorting[1]?.sortBy;
			const orderBy = sorting[1]?.orderBy;

			await getDigitalTwinConfigList({
				filters: selectedFilters,
				pageNumber,
				rowsPerPage,
				sortBy,
				orderBy,
			}).then((res) => {
				if (res.responseStatus.code === 200) {
					setListOfBatteries({
						batteries: res.response.data,
						pages: res.response.total,
						responseStatus: res.responseStatus,
					});
					dispatch(SetDigitalTwinsListOfBatteries(res.response.data));
					dispatch(SetDigitalTwinsTotal(res.response.total?.total));
					setDigitalTwinConfigsCount(res.response?.total?.total);
				} else {
					if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
					setListOfBatteries({
						batteries: [],
						pages: res.response.total,
						responseStatus: {
							code: res.responseStatus?.code,
							msg: res.responseStatus?.msg,
						},
					});
				}
			});
		}
	}

	const ChangeTheTab = (newValue) => {
		setMounted(false);
		dispatch(SetDigitalTwinsTabValue(newValue));
		dispatch(SetDigitalTwinsPageNumber(1));
		if (selectedFilters && Object.keys(selectedFilters).length > 0) {
			applySelectedFilters({})
		}

		Events(`clicked Digital Twins ${newValue == 0 ? "Batteries" : "Configurations"}`);
		let temp;
		const queryParams = decryptTheParams();
		temp = {
			...queryParams,
			tabValue: newValue,
		};
		encryptTheParams(temp, navigate);
		setMounted(true);
	};

	const Tabs = [
		{ id: 1, name: t('inventory:tabs.digital_twin.label'), count: digitalTwinsCount, color: ThemeProperties.red_400 },
		{ id: 2, name: t('inventory:tabs.battery_config.label'), count: digitalTwinConfigsCount, color: ThemeProperties.green_400, },
	];

	const contextValue = useMemo(() => ({ name: "Toast" }), []);
	const [openBatteryConfigDialog, setOpenBatteryConfigDialog] = useState(false);

	const handleCloseDialog = () => {
		setOpenBatteryConfigDialog(false);
	};

	return (
		<div>
			<OuterBox>
				<InnerBox>
					<Context.Provider value={contextValue}>
						{contextHolder}
						<PageHeaderKPI
							name={t('inventory:page_header')}
							skipRightSide={true}
							nonClickableKpis={[]}
							connectedBatteries={0}
						/>

						<Box sx={{ display: "flex", position: 'relative', justifyContent: "space-between", mt: 2, mb: 2, borderBottom: "1px solid #E4E3E3", marginLeft: '-2rem', marginRight: '-2rem' }}>
							<TabWithColorDigitalTwins
								ChangeTheTab={ChangeTheTab}
								tabs={Tabs}
							/>
						</Box>


						<BasicTabPanel value={1} index={1} key={1}>
							{
								mounted && <InventoryList
									data={listOfBatteries}
									columns={newColumns}
									rowSelection={false}
									navigate={navigate}
									tabvalue={tabvalue}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									pagesContent={props.pagesContent}
									selectedFilters={selectedFilters}
									setSelectedFilters={applySelectedFilters}
									sorting={sorting}
									setSorting={setSorting}
									connectedBatteries={connectedBatteries}
									setOpenBatteryConfigDialog={setOpenBatteryConfigDialog}
									isLoading={isLoading}
									setIsLoading={setIsLoading}
									permissions={props?.permissions} />
							}
						</BasicTabPanel>
					</Context.Provider>
				</InnerBox>
			</OuterBox>
			<AddBatteryDialog
				open={openBatteryConfigDialog}
				handleCloseDialog={handleCloseDialog}
			/>
		</div>
	);
}
