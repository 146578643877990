import * as React from "react";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { Typography } from "@mui/material";
import {
  StyledTabContainer,
  StyledIndividualTab,
} from "../../Components/CustomTab";
import Events from "../../Analytics/Events";

export default function BasicTabs(props) {
  const handleChange = (event, newValue) => {
    Events("SB tab " + props.tabs[newValue].name + " clicked");
    props.ChangeTheTab(props.tabs[newValue].name);
  };
  return (
    <Box sx={{ width: "100%", borderRadius: "12px 12px 0px 0px" }}>
      <StyledTabContainer
        value={props.tabValue.current}
        onChange={handleChange}
        aria-label="styled tabs example"
        elevation={1}
        myColor={ThemeProperties.purple}
      >
        {props.tabs.map((item, index) => (
          <StyledIndividualTab
            disableRipple
            key={index}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="specificTabBold">
                  <b>{item.name}</b>
                </Typography>
              </div>
            }
            myColor={ThemeProperties.purple}
          />
        ))}
      </StyledTabContainer>
    </Box>
  );
}
