import React, { useEffect, useMemo } from "react";
import {
    Box,
    Paper,
    Grid,
} from "@mui/material";
import "../../Features/Table/AntDesign/table.css";
import { OuterBox, InnerBox, FlexStartBox, FilterAndSearchContainer } from "../../Components/CustomBox";

import TabWithColorDashboard from "../../Features/Tabs/TabWithColorDashboard";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import ListWithSelectionNew from "../../Features/Lists/ListWithSelectionDashboard";
import GenericSelectRedux from "../../Features/Menu/GenericSelectRedux";
import AutocompleteDashboard from "../../Features/Autocomplete/AutocompleteDashboard";

import Events from "../../Analytics/Events";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getConnectedBatteries } from "../../Api/Api";
import {
    postAddToCart,
    getCartBatteries,
    getSelectAllBatteries,
} from "../../Api/CartBatteriesApi";

import {
    getAlertTypeList,
    getTabCounts,
} from "../../Api/DashboardApi";
import {
    SetDashboardCategory,
    SetDashboardTabValue,
    SetDashboardAlertTypeCode,
    SetDashboardAlertTypeIndex,
    SetDashboardTotal,
    SetDashboardStartDate,
    SetDashboardEndDate,
    SetDashboardStack,
    SetDashboardColumns,
    DashboardExistingBatteries,
    SetDashboardOpenAddSelectedBatteries,
    SetDashboardSelectedBatteriesAction,
    SetDashboardTabCounts,
} from "../../Actions/Dashboard";
import { SetSessionExpired } from "../../Actions";
import { theme } from "antd";
import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";
import BatteriesInCart from "../../Features/Dialog/BatteriesInCart";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import {
    decryptTheParams,
    encryptTheParams,
    updateQueryParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import RemoveSelectedBatteries from "../../Features/Dialog/RemoveSelectedBatteries";
import "../../Features/Toast/NewNotification.css";
import AlertHeaderIcons from "../../PagesContent/Dashboard/AlertHeaderIcons";
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker";
import { getDatePickerDate } from "../../Helper/DatePicker/DateConverter";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import { dashboardNonClickablekpis } from "../../Static/Data/Dashboard/DashboardKPIS";
import GenericFilterContainer from "../../Features/Menu/GenericFilterContainer";
import AlertTableSection from "../../PagesContent/Dashboard/AlertTableSection";
import { SetSearchType } from "../../Actions/Search";
import { SetTablePageNumber } from "../../Actions/Table";
import { SetFilterValues } from "../../Actions/Filters";
import CODE from "../../Static/Constants/StatusCodes";
import { useTranslation } from "react-i18next";

const Context = React.createContext({
    name: "Default",
});

const { useToken } = theme;

function DashboardMain(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation(['common', 'alerts'])
    const { token } = useToken();

    const [api, contextHolder] = notification.useNotification();
    
    const openNotification = (placement, text) => {
        api.info({
            message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
            placement,
            duration: 4.5,
            className: "antnoti",
        });
    };

    const contextValue = useMemo(
        () => ({
            name: "Toast",
        }),
        []
    );

    const selectedFilters = useSelector((state) => state.SelectedFilterValues.value);
    const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);
    const universal = useSelector((state) => state.DashboardStack.arr);
    const rowsPerPageCart = useSelector(
        (state) => state.StackTableRowsPerPage.value
    );
    const orderChangedToCart = useSelector(
        (state) => state.StackTableOrder.value
    );
    const sortChangedToCart = useSelector((state) => state.StackTableSort.value);
    const searchID = useSelector((state) => state.SelectedSearchID.value);
    const searchType = useSelector((state) => state.SelectedSearchType.value);
    const searchConfig = useSelector((state) => state.SelectedSearchConfig.value);
    const tabValue = useSelector((state) => state.DashboardTabValue.value);
    const tabCounts = useSelector((state) => state.DashboardTabCounts);
    const alertCode = useSelector((state) => state.DashboardAlertTypeCode.value);
    const indexDashboardAlertType = useSelector(
        (state) => state.DashboardAlertTypeIndex.value
    );

    const [reduxCountItemIds, setReduxCountItemIds] = useState(0);
    const [connectedBatteries, setConnectedBatteries] = useState(0);
    const [batteries, setBatteries] = useState({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
    });

    const [alertTypeList, setAlertTypeList] = useState({
        list: [],
        responseStatus: { code: -1, msg: "" },
    });
    
    const batteryID = useSelector((state) => state.DashboardBatteryID.value);
    const category = useSelector((state) => state.DashboardCategory.value);
    const reduxStartDate = useSelector((state) => state.DashboardStartDate.value) || getDatePickerDate(undefined, -14);
    const reduxEndDate = useSelector((state) => state.DashboardEndDate.value) || getDatePickerDate();
    const newColumns = useSelector((state) => state.DashboardColumns.value);
    const [cartBatteriesCount, setCartBatteriesCount] = useState(0);
    const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
    const openRemoveSelectedBatteries = useSelector(
        (state) => state.DashboardOpenAddSelectedBatteries.value
    );

    const selectedBatteriesAction = useSelector(
        (state) => state.DashboardSelectedBatteriesAction.value
    );

    const [openBatteryStackDialog, setOpenBatteryStackDialog] = useState(false);
    const [addToCartInProgress, setAddToCartInProgress] = useState(false)

    const handleOpenBatteryStackDialog = (event) => {
        if (cartBatteriesCount + reduxCountItemIds > 0) {
            addToCart();
            setOpenBatteryStackDialog(true);
            Events("clicked Selected Batteries For Action");
        }
    };
    const handleCloseBatteryStackDialog = () => {
        setOpenBatteryStackDialog(false);
    };

    async function GetAlertTabs() {
        await getTabCounts(
            reduxStartDate,
            reduxEndDate,
            searchID,
            selectedFilters,
            defaultFilterValues
        ).then((res) => {
            if (res?.responseStatus?.code === 200) {
                dispatch(SetDashboardTabCounts(res.response.data));
            } else if (res?.responseStatus?.code === -2) {
                dispatch(SetSessionExpired(true));
            }
        });
    }

    const ChangeTheTab = (newValue) => {
        let itemIDS = [];
        universal.forEach((item) => itemIDS.push(...item.itemIDs));

        if (itemIDS?.length > 0) {
            dispatch(SetDashboardOpenAddSelectedBatteries(true));
        } else {
            let temp;
            setAlertTypeList({
                list: [],
                responseStatus: { code: -1, msg: "" },
            });
            dispatch(SetDashboardTabValue(newValue));
            dispatch(SetTablePageNumber(1));

            if (newValue === 0) {
                Events(`clicked Alerts Active Alerts`);
            } else if (newValue === 1) {
                Events(`clicked Alerts Open Alerts`);
            } else if (newValue === 2) {
                Events(`clicked Alerts Resolved Alerts`);
            } else if (newValue === 3) {
                Events(`clicked Alerts Ignored Alerts`);
            } else if (newValue === 4) {
                Events(`clicked Alerts Expired Alerts`);
            }
            const queryParams = decryptTheParams();
            temp = {
                ...queryParams,
                tabValue: newValue,
            };
            encryptTheParams(temp, navigate);
        }
    };

    function handleSetFilter(filterName, value) {
        dispatch(SetTablePageNumber(1));

        if (filterName === "Alert Type") {
            dispatch(SetDashboardCategory(value));
            dispatch(SetDashboardAlertTypeCode(""));
            updateQueryParams(navigate, {
                category: value,
                alertCode: ""
            })
        }
    }

    const resetTheFilters = () => {
        dispatch(SetDashboardStartDate(getDatePickerDate(undefined, -14)));
        dispatch(SetDashboardEndDate(getDatePickerDate()));
        dispatch(SetTablePageNumber(1));
    }

    const removeSelection = () => {
        const stackArr = alertTypeList?.list?.map((codeItem) => {
            return {
                alertCode: codeItem?.alertCode,
                alertDescription: codeItem?.alertDescription,
                itemIDs: [],
            };
        });
        dispatch(SetDashboardStack(stackArr));
        dispatch(SetDashboardSelectedBatteriesAction(""));
    };

    const addToCart = () => {
        setAddToCartInProgress(true)
        removeSelection();
        setBatteries({
            batteries: [],
            responseStatus: { code: -1, msg: "" },
        });

        let itemIDS = [];
        universal.forEach((item) => itemIDS.push(...item.itemIDs));
        if (itemIDS.length > 0) {
            postAddToCart(itemIDS)
                .then((res) => {
                    if (res.responseStatus.code === 200) {
                        dispatch(SetDashboardStack([]));
                    } else if (res.responseStatus.code === -2) {
                        dispatch(SetSessionExpired(true));
                    }
                })
                .then(() => {
                    getCartBatteries(
                        1,
                        sortChangedToCart,
                        orderChangedToCart,
                        rowsPerPageCart
                    ).then((res) => {
                        let items = [];
                        if (res.responseStatus.code === 200) {
                            res.response.data.forEach((item) => {
                                items.push(item.id);
                            });
                            dispatch(DashboardExistingBatteries(items));
                            setBatteries({
                                batteries: res.response.data,
                                total: res.response.total.total,
                                responseStatus: {
                                    code: res.responseStatus.code,
                                    msg: res.responseStatus.msg,
                                },
                            });
                            removeSelection();
                        } else {
                            if (res.responseStatus.code === -2)
                                dispatch(SetSessionExpired(true));

                            dispatch(DashboardExistingBatteries([]));
                            setBatteries({
                                batteries: [],
                                responseStatus: {
                                    code: res.responseStatus.code,
                                    msg: "",
                                },
                            });
                        }

                        setCartBatteriesCount(items?.length);
                        setAddToCartInProgress(false)
                    });
                });
        } else {
            setAddToCartInProgress(false)
        }
        dispatch(SetDashboardSelectedBatteriesAction(""));
    };

    const SetCountForCart = (type) => {
        getSelectAllBatteries()
            .then((res) => {
                if (res?.responseStatus?.code === 200) {
                    dispatch(DashboardExistingBatteries(res?.response.data));
                    setCartBatteriesCount(res?.response?.data?.length);
                } else {
                    if (res?.responseStatus.code === -2)
                        dispatch(SetSessionExpired(true));
                    setCartBatteriesCount(0);
                    dispatch(DashboardExistingBatteries([]));
                }
            })
            .then(() => {
                if (type === "empty") {
                    dispatch(DashboardExistingBatteries([]));
                }
            });
    };

    function changeTheDate(startDate, endDate) {
        let temp;
        const queryParams = decryptTheParams();

        dispatch(SetTablePageNumber(1));
        if (startDate === null && endDate === null) {
            dispatch(SetDashboardStartDate(""));
            dispatch(SetDashboardEndDate(""));
            temp = {
                ...queryParams,
                startDate: "",
                endDate: "",
            };
        } else if (endDate === null) {
            dispatch(SetDashboardStartDate(startDate));
            dispatch(SetDashboardEndDate(startDate));
            temp = {
                ...queryParams,
                startDate: startDate,
                endDate: startDate,
            };
        } else if (startDate === null) {
            dispatch(SetDashboardStartDate(endDate));
            dispatch(SetDashboardEndDate(endDate));
            temp = {
                ...queryParams,
                startDate: endDate,
                endDate: endDate,
            };
        } else {
            dispatch(SetDashboardStartDate(startDate));
            dispatch(SetDashboardEndDate(endDate));
            temp = {
                ...queryParams,
                startDate: startDate,
                endDate: endDate,
            };
        }
        encryptTheParams(temp, navigate);
    }

    const getAlertTypes = async () => {
        if (!defaultFilterValues) {
            return
        }
        setAlertTypeList({
            list: [],
            responseStatus: {
                code: CODE.LOADING,
                msg: "",
            },
        });
        if (selectedFilters && Object.keys(selectedFilters).length < 1) {
            return
        }
        const res = await getAlertTypeList(
            tabValue,
            category,
            searchID,
            searchConfig?.keys?.[searchType],
            reduxStartDate,
            reduxEndDate,
            selectedFilters,
            defaultFilterValues
        )
        if (res && res?.responseStatus?.code === 200) {
            let finalArr =
                category === "" || category?.toLowerCase() === "all"
                    ? res.response?.data
                    : res.response?.data?.filter(
                        (x) => x.alertType.toLowerCase() === category?.toLowerCase()
                    ) ?? [];

            finalArr = finalArr?.map((item) => {
                let color = "";
                let textColor = ThemeProperties.black;
                switch (item["alertType"].charAt(0).toLowerCase()) {
                    case "s":
                        color = ThemeProperties.safety_1;
                        textColor = ThemeProperties.white;
                        break;
                    case "o":
                        color = ThemeProperties.operational_1;
                        textColor = ThemeProperties.black;
                        break;
                    case "v":
                        color = ThemeProperties.violation_1;
                        textColor = ThemeProperties.black;
                        break;
                }
                return { ...item, color: color, textColor: textColor };
            });
            setAlertTypeList({
                list: finalArr,
                responseStatus: {
                    code: res.responseStatus.code,
                    msg: res.responseStatus.msg,
                },
            });

            if (!finalArr?.length) {
                dispatch(SetDashboardAlertTypeCode(""));
                dispatch(SetDashboardTotal(""));
                dispatch(SetDashboardAlertTypeIndex(0));
            } else if (alertCode !== "") {
                const selectedAlertCode = finalArr.find(item => item.alertCode === alertCode)
                if (selectedAlertCode) {
                    dispatch(SetDashboardAlertTypeIndex(selectedAlertCode.id));
                    dispatch(SetDashboardAlertTypeCode(selectedAlertCode.alertCode));
                    dispatch(SetDashboardTotal(selectedAlertCode.alertCount));
                } else {
                    dispatch(SetDashboardAlertTypeCode(finalArr[0]["alertCode"]));
                    dispatch(SetDashboardTotal(finalArr[0]["alertCount"]));
                    dispatch(SetDashboardAlertTypeIndex(finalArr[0]["id"]));
                }
            } else {
                dispatch(SetDashboardAlertTypeCode(finalArr[0]["alertCode"]));
                dispatch(SetDashboardTotal(finalArr[0]["alertCount"]));
                dispatch(SetDashboardAlertTypeIndex(finalArr[0]["id"]));
            }

            const stackArr = finalArr.map((codeItem) => {
                return {
                    alertCode: codeItem?.alertCode,
                    alertDescription: codeItem?.alertDescription,
                    itemIDs: [],
                };
            });
            dispatch(SetDashboardStack(stackArr));
        } else {
            if (res?.responseStatus?.code === -2) dispatch(SetSessionExpired(true));
            setAlertTypeList({
                list: [],
                responseStatus: {
                    code: res?.responseStatus.code,
                    msg: res?.responseStatus.msg,
                },
            });
        }
    }

    const onChangeSearchType = (dropdownName, value) => {
        let temp;
        const queryParams = decryptTheParams();
        temp = {
            ...queryParams,
            selectedSearchType: value
        };
        dispatch(SetSearchType(value))

        if (searchID !== "") {
            resetTheFilters();
            openNotification("bottomLeft", `Resetting all the filters.`);
        }

        encryptTheParams(temp, navigate);
    }

    const onClearSearch = () => {
        resetTheFilters();
        dispatch(SetFilterValues(defaultFilterValues))
        dispatch(SetDashboardCategory(""));
    }

    function filterBox() {
        return (
            <FilterAndSearchContainer>
                <FlexStartBox>
                    <GenericSelectRedux
                        filter={props.pagesContent.searchSelectConfig}
                        handleSetFilter={onChangeSearchType}
                        selected={searchType}
                        maxWidth={"max-content"}
                        showClearButton={false}
                    />
                    <AutocompleteDashboard
                        width={300}
                        onClearSearch={onClearSearch}
                        tabValue={tabValue}
                        page={props.pagesContent.pageName}
                        openNotification={openNotification}
                    />
                </FlexStartBox>

                <GenericFilterContainer
                    filtersConfig={props.pagesContent.filterConfigurations?.alertsDashboard?.filters}
                    onSelectFilter={(key, label, values) => { handleSetFilter("", "") }}
                    onResetFilters={resetTheFilters}
                />
            </FilterAndSearchContainer>
        );
    }

    useEffect(() => {
        getConnectedBatteries().then((res) => {
            if (res?.responseStatus?.code === 200) {
                setConnectedBatteries(res.response.data[0]["count"]);
            } else if (res?.responseStatus?.code === -2) {
                dispatch(SetSessionExpired(true));
            }
        });
    }, [props.pagesContent.batteryType]);

    useEffect(() => {
        GetAlertTabs();
    }, [
        searchID,
        tabValue,
        reduxEndDate,
        reduxStartDate,
        timezoneChanged,
        selectedFilters?.fleetName,
        selectedFilters?.location,
        selectedFilters?.severityName,
        selectedFilters?.batteryStatus,
        selectedFilters?.cellModelNumber,
        selectedFilters?.iotOEM,
        selectedFilters?.pingStatus,
        selectedFilters?.configName,
    ]);

    useEffect(() => {
        getAlertTypes();
    }, [
        tabValue,
        reduxStartDate,
        reduxEndDate,
        batteryID,
        category,
        searchID,
        searchType,
        selectedFilters,
        timezoneChanged
    ]);

    useEffect(() => {
        const tempColumns = [...newColumns];
        dispatch(SetDashboardColumns(tempColumns));
    }, [
        alertTypeList,
        alertCode,
    ]);

    useEffect(() => {
        let itemIDS = [];
        universal.forEach((item) => itemIDS.push(...item.itemIDs));

        setReduxCountItemIds(itemIDS?.length);
        SetCountForCart();
    }, [universal]);

    useEffect(() => {
        if (selectedBatteriesAction === "Add") {
            addToCart();
        }
        if (selectedBatteriesAction === "Remove") {
            removeSelection();
        }
    }, [selectedBatteriesAction]);

    return (
        <div id="alerts-dashboard-main">
            <OuterBox>
                <InnerBox>
                    <Context.Provider value={contextValue}>
                        {contextHolder}
                        <PageHeaderKPI
                            name={t('alerts:page_header')}
                            nonClickableKpis={dashboardNonClickablekpis}
                            connectedBatteries={connectedBatteries}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                pt: 1.5
                            }}
                        >
                            <AlertHeaderIcons
                                cartCount={cartBatteriesCount + reduxCountItemIds}
                                openBatteryStackDialog={handleOpenBatteryStackDialog}
                            />
                        </Box>
                        <Box sx={{ mt: 1, position: "relative" }}>
                            <Box sx={{ position: "relative" }}>
                                <Box sx={{ position: "absolute", right: 0, zIndex: 1, top: 5 }}>
                                    <AntDatePicker
                                        changeTheDate={changeTheDate}
                                        date={[reduxStartDate, reduxEndDate]}
                                        allowClear={true}
                                    />
                                </Box>
                                <TabWithColorDashboard
                                    ChangeTheTab={ChangeTheTab}
                                    tabs={props.pagesContent?.tabsConfig || []}
                                    tabCounts={tabCounts}
                                />
                                <Paper
                                    elevation={0}
                                    sx={{
                                        height: "100%",
                                        borderRadius: "0px 12px 12px",
                                        p: 1,
                                        border: "1px solid #ececec",
                                        minWidth: "800px",
                                        overflowX: "scroll",
                                    }}
                                >
                                    {filterBox()}
                                    <hr style={{ borderTop: "1px solid #ececec" }}></hr>
                                    {props.pagesContent?.tabsConfig?.map((item, index) => (
                                        <BasicTabPanel
                                            style={{
                                                marginTop: "10px"
                                            }}
                                            value={tabValue} index={index} key={index}
                                        >
                                            <Box sx={{ mt: 1.5 }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4} lg={3}>
                                                        <ListWithSelectionNew
                                                            data={alertTypeList}
                                                            batteryType={props.pagesContent.batteryType}
                                                            handleSetFilter={handleSetFilter}
                                                            alertType={props.pagesContent.alertType}
                                                            category={category}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} lg={9}>
                                                        <AlertTableSection key="alertTableSection" openNotification={openNotification} />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </BasicTabPanel>
                                    ))}
                                </Paper>
                            </Box>
                        </Box>
                    </Context.Provider>
                </InnerBox>
            </OuterBox>
            {openBatteryStackDialog && (
                <>
                    <BatteriesInCart
                        batteries={batteries}
                        openBatteryStackDialog={openBatteryStackDialog}
                        handleCloseBatteryStackDialog={handleCloseBatteryStackDialog}
                        SetCountForCart={SetCountForCart}
                        setCartBatteriesCount={setCartBatteriesCount}
                        alertTypeList={alertTypeList?.list}
                        addToCartInProgress={addToCartInProgress}
                        getALertType={getAlertTypes}
                        getALertTabs={GetAlertTabs}
                        pagesContent={props.pagesContent}
                    />
                </>
            )}

            <RemoveSelectedBatteries
                openRemoveSelectedBatteries={openRemoveSelectedBatteries}
                addToCart={addToCart}
                removeSelection={removeSelection}
            />
        </div>
    );
}

export default DashboardMain;
