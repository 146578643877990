import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Typography, Box } from "@mui/material"
import { ArrowContainer } from "../../Components/CustomBox.js"
import { toTitleCase } from "../../Helper/Formatters/TextFormatter.js"
import CustomIcon from "../../Components/CustomIcon.js"
import { Tooltip } from "antd"
import TextWithCopyIcon from "../../Helper/Operations/TextWithCopyIcon.js"
import { convertAndGetTimeStampInRequiredFormat } from "../../Helper/DatePicker/DateConverter";
import { decimalPlacesForMetrics, multiplyFactors } from "../../PagesContent/DigitalTwinV2/TabSection/_Constants.js"
import { getCustomerEntityName, getTruncatedEntityName } from "../../Helper/DigitalTwin/DigitalTwinHelper.js"
import { useTranslation } from "react-i18next"
import { normalizeKey } from "../../Helper/Common/common_helpers.js"

function CarouselSlider({ disabled, position, onClick }) {
  return (
    <ArrowContainer
      style={{
        top: "30px"
      }}
      disabled={disabled}
      onClick={onClick}
      left={position === "left" ? "-25px" : null}
      right={position === "right" ? "-25px" : null}
    >
      <CustomIcon
        name={
          position === "left"
            ? "chartCarouselPreviousArrow"
            : "chartCarouselNextArrow"
        }
        width={22}
        height={22}
        viewBox="0 0 22 22"
        fill="#828A92"
      />
    </ArrowContainer>
  )
}

const MultipleItems = ({
  hierarchy,
  selectedHierarchy,
  selectedHierarchyLevel,
  selectedComparisonModeMetric,
  chartColorCoding,
  slidesToShow,
  hierarchyLiveMetricData,
}) => {
  const {t} = useTranslation(['common', 'digital_twin'])
  const [activeSlide, setActiveSlide] = useState(0)
  const initialSlide = 0
  const startIndexOfSlide = parseInt(initialSlide / slidesToShow) * slidesToShow
  const [disablePreviousButton, setDisablePreviousButton] = useState(
    startIndexOfSlide === 0
  )
  const [disableNextButton, setDisableNextButton] = useState(
    startIndexOfSlide + slidesToShow >= selectedHierarchy.length
  )

  useEffect(() => {
    if (
      selectedHierarchy.length > slidesToShow &&
      initialSlide >= 0 &&
      initialSlide < selectedHierarchy.length
    ) {
      sliderRef.current.slickGoTo(startIndexOfSlide)
    }
  }, [initialSlide, selectedHierarchy.length])

  useEffect(() => {
    setDisablePreviousButton(activeSlide === 0)
    setDisableNextButton(activeSlide + slidesToShow >= selectedHierarchy.length)
  }, [activeSlide, selectedHierarchy.length])
  const sliderRef = React.createRef()

  var settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow:
      selectedHierarchy.length < slidesToShow
        ? selectedHierarchy.length
        : slidesToShow,
    slidesToScroll:
      selectedHierarchy.length < slidesToShow
        ? selectedHierarchy.length
        : slidesToShow,
    initialSlide: 0,
    beforeChange: (current, next) => setActiveSlide(next),
    centerMode: false,
    nextArrow: !disableNextButton ? (
      <CarouselSlider position={"right"} disabled={disableNextButton} />
    ) : (
      <></>
    ),
    prevArrow: !disablePreviousButton ? (
      <CarouselSlider position={"left"} disabled={disablePreviousButton} />
    ) : (
      <></>
    )
  }

  const getLiveMetricSection = (entityName, index, hierarchy) => {
    const customerEntityName = getCustomerEntityName(hierarchy, entityName) || entityName
    const id = hierarchyLiveMetricData?.[entityName]?.sequence || ""
    const truncatedName = getTruncatedEntityName(customerEntityName, 20)
    const value = Number.isFinite(
      hierarchyLiveMetricData?.[entityName]?.[selectedComparisonModeMetric]
    )
      ? (hierarchyLiveMetricData?.[entityName]?.[selectedComparisonModeMetric] * multiplyFactors[selectedComparisonModeMetric]).toFixed(decimalPlacesForMetrics[selectedComparisonModeMetric])
      : "NA"
    let unit
    switch (selectedComparisonModeMetric) {
      case "current":
        unit = value === "NA" ? "" : "A"
        break
      case "voltage":
        unit = value === "NA" ? "" : "V"
        break
      case "soc":
        unit = value === "NA" ? "" : "%"
        break
      case "temperature":
        unit = value === "NA" ? "" : "°C"
        break
      case "capacity":
        unit = value === "NA" ? "" : "Ah"
        break
      case "energy":
        unit = value === "NA" ? "" : "kWh"
        break
      case "balanceState":
        unit = value === "NA" ? "" : "%"
        break
    }
    return (
      <Box
        key={`comparisonPlotEntity${index}`}
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "6px",
          justifyContent: "center",
          border: "1px solid",
          borderRadius: "4px",
          minHeight: "50px",
          padding: "5px 12px",
          borderColor: "rgba(203, 203, 204, 0.5)",
          minWidth: "255px"
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <Box
            style={{
              width: "16px",
              height: "6px",
              top: "10px",
              left: "12px",
              gap: "0px",
              borderRadius: "5px",
              opacity: "0px",
              marginTop: "5px",
              marginRight: "6px",
              background: chartColorCoding?.[index] || "black"
            }}
          />
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "16px",
              paddingRight: "5px",
              color: "#828A92",
              whiteSpace: "nowrap"
            }}
          >
            {`${toTitleCase(t(`digital_twin:${normalizeKey(selectedHierarchyLevel)}`, selectedHierarchyLevel))} ${id}:`}
          </Typography>
          {TextWithCopyIcon(
            customerEntityName.toUpperCase(), "Battery Hierarchy ID", 
            {
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "16px",
              padding: 0,
              color: "#444B51"
            },
            "top", 
            truncatedName.toUpperCase(),
            {
              fontSize: "10px"
            }, 
            {
              padding: 0,
              marginLeft: "4px"
            },
            true
          )}
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Box
             style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: 'nowrap',
                marginTop: "4px",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                color: "#444B51"
              }}
            >
              {value}
              {unit}
            </Typography>
            <Tooltip
              placement="top"
              title={
                hierarchyLiveMetricData?.[entityName]?.pingStatus === "Active"
                  ? "Live Signal Values"
                  : "Offline"
              }
              zIndex={2000}
            >
              <Box
                style={{
                  marginLeft: "7px"
                }}
              >
                <CustomIcon
                  name={hierarchyLiveMetricData?.[entityName]?.pingStatus === "Active" ? "online" : "offline"}
                  style={{
                    marginTop: "3px",
                    width: "14px",
                    height: "10px"
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box>
            <Typography
              style={{
                marginTop: "4px",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "20px",
                color: "#444B51"
              }}
            >
              {convertAndGetTimeStampInRequiredFormat(hierarchyLiveMetricData?.[entityName]?.last_pinged_time, "DD MMM'YY, hh:mm A")}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <div
      style={{
        margin: `0px ${!disableNextButton ? "35px" : 0} 0px ${
          !disablePreviousButton ? "35px" : 0
        }`
      }}
    >
      {selectedHierarchy.length <= slidesToShow ? (
        <Box
          style={{
            display: "flex",
            marginLeft: "15px",
            gap: "20px"
          }}
        >
          {selectedHierarchy.map((entityName, index) =>
            getLiveMetricSection(entityName, index, hierarchy)
          )}
        </Box>
      ) : (
        <Slider {...settings} ref={sliderRef}>
          {selectedHierarchy.map((entityName, index) => (
            <>{getLiveMetricSection(entityName, index, hierarchy)}</>
          ))}
        </Slider>
      )}
    </div>
  )
}

export default MultipleItems
