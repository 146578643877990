import { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import Events from "../../../Analytics/Events";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/ChargingAnalytics";
import CODE from "../../../Static/Constants/StatusCodes";
import { findYAxisPartitionSizeForPositiveAxis, getXAxisDateString } from "../../../Helper/Charts/ChartHelper";
import { toTitleCase } from "../../../Helper/Formatters/TextFormatter";
import { encryptTheParams } from "../../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { formatDateWithoutYear } from "../../../Helper/DatePicker/DateFormatters";
import moment from "moment";
import AnalyticsColumnPlusLine from "../../../Charts/Mixed/AnalyticsColumnPlusLine";

const defaultChartState = {
  series: {
    line: [], 
    column:[]
  },
  statusCode: CODE.LOADING
}

const barPlotYAxisSetting = {
  title: {
    text: 'Energy Efficiency (kWh/Km)',
    style: {
      color: "#87939D",
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
  },
  showAlways: true,
  tickAmount: 5,
  decimalsInFloat: 1,
  axisBorder: {
    show: false,
    color: '#87939D',
    offsetX: 2.5,
    offsetY: 0
  },
  labels: {
    style: {
        colors: '#87939D',
        fontSize: '10px',
        fontFamily: 'Roboto',
        fontWeight: 500,
    },
  },
  min: 0,
  max: 0.5
}

const linePlotYAxisSetting = {
  title: {
    text: 'Number of Vehicles',
    style: {
      color: "#87939D",
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
  },
  showAlways: true,
  opposite: true,
  tickAmount: 5,
  decimalsInFloat: 0,
  axisBorder: {
    show: false,
    color: '#87939D',
    offsetX: 0,
    offsetY: 0
  },
  labels: {
    style: {
        colors: '#87939D',
        fontSize: '10px',
        fontFamily: 'Roboto',
        fontWeight: 500,
    },
  },
  max: (max) => {
    const calculatedMax = findYAxisPartitionSizeForPositiveAxis(max) * 5

    if (Number.isFinite(calculatedMax)) {
      return Math.max(calculatedMax, 5)
    }
    return 5
  },
  min: (min) => {
    if (Number.isFinite(min)) {
      return min
    }
    return 0
  }
}

const EnergyEfficiencyChart = ({ 
  filters, 
  startDate, 
  endDate, 
  granularity, 
  cardSubtitleTimePeriodText, 
  clickableHeader = false, 
  chartSelectedFilter = () => {} }) => {
  const navigate = useNavigate()
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const isFilterMounted = useSelector((state) => state.IsFiltersMounted.value);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  useEffect(() => {
    if (isFilterMounted) {
      fetchData();
      chartSelectedFilter(true, null, null)
    }
  }, [
    granularity,
    filters,
    isFilterMounted
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["efficiency", "vehiclesCount"], startDate, endDate, granularity.granularity, filters, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    const series =  {
      column: {
        name: 'Energy Efficiency (kWh/Km)',
        type: 'column',
        color: "#C8607B",
        data: response.data?.map(data => {
          let x, dateRangeString
          if (granularity.granularity === 'daily') {
            const date = new Date(data.range.startDate);
            x = formatDateWithoutYear(date)
            dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
          } else {
            const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
            x = result.x;
            dateRangeString = result.dateRangeString;
          }

          return {
            x,
            y: data.metricsArray.find(item => item.type === 'efficiency')?.value,
            dateRangeString
          }
        })
      },
      line: {
        name: "Number of Vehicles",
        type: 'line',
        color: "#7957FF",
        data: response.data?.map(data => {
          let x, dateRangeString
          if (granularity.granularity === 'daily') {
            const date = new Date(data.range.startDate);
            x = formatDateWithoutYear(date)
            dateRangeString = moment(data.range.startDate).format('DD MMM, YYYY')
          } else {
            const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
            x = result.x;
            dateRangeString = result.dateRangeString;
          }

          return {
            x,
            y: data.metricsArray.find(item => item.type === 'vehiclesCount')?.value,
            dateRangeString
          }
        })
      },
    }

    setChartState({
      series: series, 
      statusCode: responseStatus.code
    })
  }

  const generateCsvData = () => {
    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }
    const formatItem = (energyEfficiencyData, numberOfVehicleData) => ({
      "Date Range": energyEfficiencyData.dateRangeString?.replace(/,/g, " "),
      "Energy Efficiency (kWh/Km)": energyEfficiencyData.y?.toFixed(2),
      "Number of Vehicles": numberOfVehicleData.y
    });

    const data = [
      ...chartState.series.column.data.map((energyEfficiencyRow, index) => formatItem(energyEfficiencyRow, chartState.series.line.data[index])),
    ];

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }

  const onClickHeader = () => {
    const temp = {
      chartName: 'energyEfficiency',
      startDate,
      endDate 
    };
    encryptTheParams(temp, navigate, false, '/ChargerDrillDown', true);
  }
  
  return (
    <CardWithHeader
      id='energyEfficiencyChart'
      title="Fleet Energy Efficiency"
      headerClass="header-hover"
      subtitle={`${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="barGraph" style={{ width: 21, height: 21 }}/>}
      pageLabel="Charging Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"EnergyEfficiency"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Charging Analytics: Hover over Energy Efficiency chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{ height:"363px", position: "relative", paddingLeft: 0, paddingRight: 0 }}
      clickableHeader={clickableHeader}
      onClickHeader={onClickHeader}
    >
      <AnalyticsColumnPlusLine
        state={chartState.statusCode}
        granularity={toTitleCase(granularity.granularity)}
        columnPlotDataSeries={chartState.series.column}
        linePlotDataSeries={chartState.series.line}
        barPlotYAxisSetting={barPlotYAxisSetting}
        linePlotYAxisSetting={linePlotYAxisSetting}
        hideAlternateLabels={granularity.granularity === "daily"  && chartState.series?.column?.data?.length > 15}
      />
    </CardWithHeader>
  )
}


export default EnergyEfficiencyChart;
