import { SvgIcon } from "@mui/material";
import React from "react";

const BatteryChargingStateIcon = ({
  width = "34",
  height = "34",
  viewBox = "0 0 34 34",
  fill = "#3CA967",
  stroke = "#586CE9",
  strokeWidth = 1,
  strokeLinecap = "round",
  strokeLinejoin = "round",
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap={strokeLinecap}
    strokeLinejoin={strokeLinejoin}
    sx={{ fill, display: "block", ...props.sx }}
    {...props}
  >
    <circle cx={29} cy={29} r={3} fill={fill} />
    <circle cx={16} cy={16} r={11} stroke={stroke} />
    <rect x={12.7} y={10.5} width={6.38} height={4.73} rx={0.3} fill={stroke} />
    <path d="M17.1 10.5V8.85c0-.303.246-.55.55-.55s.55.247.55.55V10.5h-1.1Z" fill={stroke} />
    <path d="M13.8 10.5V8.85c0-.303.246-.55.55-.55s.55.247.55.55V10.5h-1.1Z" fill={stroke} />
    <path d="M16.55 14.9v7.7c0 .304-.246.55-.55.55s-.55-.246-.55-.55v-7.7h1.1Z" fill={stroke} />
    <path d="M17.1 11.6h-1.362l-.838 1.916h.629l-.629 1.384 1.886-1.916h-.733L17.1 11.6Z" fill="white" />
  </SvgIcon>
);

export default BatteryChargingStateIcon;
