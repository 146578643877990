import React, { useState, useEffect } from "react"
import { IconButton, Typography, Popover, Box, MenuItem, FormControl } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import LinkIcon from "@mui/icons-material/Link"
import DownloadIcon from "@mui/icons-material/Download"
import { SecondaryButton } from "../../Components/CustomButton"
import { getTruncatedName } from "../../Helper/Formatters/TextFormatter"
import Events from "../../Analytics/Events"
import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV"
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV"
import CODE from "../../Static/Constants/StatusCodes"
import {
  AnalyticsCardFilterMenuItem,
  AnalyticsCardFilterSelectedLabel,
  CardHeaderBox,
  CardHeaderContainer,
  CardHeaderIconBox,
  CardMenuPopoverContainer,
  ChartHeadingText,
  ChartSubTitleText,
  FilterArrowBox,
  FlexBox,
  SubTitleBox,
  FlexStartBox,
} from "../../Components/CustomBox"
import { decryptTheParams, encryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt"
import copy from "copy-to-clipboard"
import { AnalyticsCardSelect, BasicSelect } from "../../Components/CustomSelect"
import CustomIcon from "../../Components/CustomIcon"
import { useTranslation } from "react-i18next"

function GenericHeader({
  id,
  pageLabel = "",
  headerClass = "",
  heading = "",
  description = "",
  iconElement = null,
  showThreeDots = true,
  showCSVDownload = false,
  csvName = "CSV",
  csvData = {
    data: [],
    responseStatus: {
      code: null
    }
  },
  openNotification,
  closeNotification,
  generateCsvData,
  hasFilter = false,
  filterData = {},
  onFilterChange = {},
  onClickHeader = () => {},
  clickableHeader = false
}) {
  const {t} = useTranslation(['common'])
  const [anchorEl, setAnchorEl] = useState(null)
  const isPopoverOpen = Boolean(anchorEl)
  const popoverId = isPopoverOpen ? "simple-popover" : undefined

  useEffect(() => {
    handleCSVDownload()
  }, [csvData])

  const handleCSVDownload = () => {
    if (csvData?.responseStatus?.code === CODE.LOADING) {
      openNotification("bottomLeft", t('common:prepare_download'), 0, "info")
    } else if (csvData?.responseStatus?.code === CODE.SUCCESS) {
      closeNotification()
      const newBatteries = [...csvData.data]
      DownloadCSV(NameTheCSV(csvName, ""), newBatteries)

      setTimeout(() => {
        openNotification("bottomLeft", t('common:content_downloaded'), 2, "success")
      }, 100)
    } else if (csvData?.responseStatus?.code === CODE.NODATA) {
      closeNotification()
      setTimeout(() => {
        openNotification("bottomLeft", t('common:no_data'), 2, "error")
      }, 100)
    }
  }

  const handleMenuClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = (event) => {event.stopPropagation(); setAnchorEl(null)}

  const handleCopyLink = (event) => {
    event.stopPropagation();
    const queryParams = decryptTheParams()
    let temp;
    temp = {
      ...queryParams,
      id: id,
    };
    const que = new URLSearchParams(temp).toString();
    const encryptedParams = encryptTheParams(que)
    const page = window.location.pathname;
    const appURL = window.location.origin;
    const link = `${appURL}${page}?${encryptedParams}`
    copy(link)
    handleMenuClose(event)
  }

  const handleCSVDownloadClick = (event) => {
    Events(`${pageLabel}:Clicked on Csv Download`)
    generateCsvData()
    handleMenuClose(event)
  }

  const DropDownFilter = () => {
    const [open, setOpen] = React.useState(false);

    return <FormControl sx={{ m: 1, mt: 0.625, minWidth: 120, maxWidth: 200 }}>
      <AnalyticsCardSelect
        value={filterData?.selectedItemId}
        onChange={onFilterChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        open={open}
        cardColor="#EEEFFF"
        onClose={(event) => {event.stopPropagation(); setOpen(false)}}
        onOpen={(event) => {event.stopPropagation(); setOpen(true)}}
        renderValue={(selectedItemId) => {
          return <AnalyticsCardFilterSelectedLabel>{getTruncatedName(filterData?.menuItemData?.find(item => item.id === selectedItemId)?.value, 25, 14, 10)}</AnalyticsCardFilterSelectedLabel>
        }}
        IconComponent={() => <FilterArrowBox sx={{mr: "7px", ml: "7px"}} onClick={(event) => {event.stopPropagation(); setOpen(true)}}>
          <CustomIcon 
            stroke={"#6D72F6"}
            viewBox={"0 -4 20 12"} 
            name={open ? "upArrow" : "downArrow"}
          />
        </FilterArrowBox>}
        MenuProps={{
          style: { zIndex: 10001, marginTop: "5px" },
        }}
      >
        {filterData?.menuItemData?.map(item => <AnalyticsCardFilterMenuItem className="analytics-card-menu-item" selected={item.id === filterData?.selectedItemId} value={item.id}>{item.value}</AnalyticsCardFilterMenuItem>)}
      </AnalyticsCardSelect>
    </FormControl>
  }

  return (
    <CardHeaderContainer
      className={headerClass}
      onClick={() => {
        clickableHeader && onClickHeader();
      }}
      isClickable={clickableHeader}
    >
      <CardHeaderBox>
        <Box>
          <FlexBox>
            {iconElement && (
              <CardHeaderIconBox>{iconElement}</CardHeaderIconBox>
            )}
            <Box>
              <ChartHeadingText isClickable={clickableHeader}>
                {heading}
              </ChartHeadingText>
            </Box>
          </FlexBox>
          <SubTitleBox>
            {description && (
              <ChartSubTitleText>{description}</ChartSubTitleText>
            )}
          </SubTitleBox>
        </Box>
        <FlexStartBox>
          {hasFilter && <DropDownFilter />}
          {showThreeDots && (
            <IconButton
              aria-describedby={popoverId}
              onClick={handleMenuClick}
              sx={{
                mt: hasFilter ? "-5px": "5px",
                backgroundColor: isPopoverOpen ? "#6D72F6" : "transparent",
                color: isPopoverOpen ? "white" : "#828A92",
              }}
            >
              <MoreVertIcon fontSize="small" sx={{ fontSize: "18px" }} />
            </IconButton>
          )}
        </FlexStartBox>

        {/* Popover Menu */}
        <Popover
          id={popoverId}
          open={isPopoverOpen}
          onClick={(e) => e.stopPropagation()}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              borderRadius: "12px",
              padding: "12px",
              maxWidth: "300px",
            },
          }}
        >
          <CardMenuPopoverContainer>
            <SecondaryButton
              onClick={handleCopyLink}
              sx={{ pr: 1, width: "100%" }}
            >
              <LinkIcon
                sx={{
                  color: "#6D72F6",
                  fontSize: "18px",
                  mr: 1,
                }}
                fontSize="small"
              />
              <Typography
                sx={{ color: "#6D72F6", fontSize: 12, fontFamily: "Roboto" }}
              >
                {t('common:copy_link')}
              </Typography>
            </SecondaryButton>

            {showCSVDownload && (
              <SecondaryButton onClick={handleCSVDownloadClick} sx={{ pr: 1 }}>
                <DownloadIcon
                  sx={{
                    color: "#6D72F6",
                    fontSize: "18px",
                    mr: 1,
                  }}
                  fontSize="small"
                />
                <Typography
                  sx={{ color: "#6D72F6", fontSize: 12, fontFamily: "Roboto" }}
                >
                  {t('common:download_csv')}
                </Typography>
              </SecondaryButton>
            )}
          </CardMenuPopoverContainer>
        </Popover>
      </CardHeaderBox>
    </CardHeaderContainer>
  );
}

export default GenericHeader
