import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { Dialog, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  SetAllBatteriesBatteryPartnerSelect,
  SetAllBatteriesFoundBatteries,
  SetAllBatteriesNotFoundBatteries,
  SetAllBatteriesUserInputBatteries,
  SetAllBatteriesMultiplePartners,
} from "../../Actions/AllBatteries";
import { getFoundAndNotFoundBatteries } from "../../Api/AllBatteriesApi";
import MultipleSearchState from "../Autocomplete/MultipleSearchTags/MultipleSearchState";
import MultipeSearchCheckbox from "../Autocomplete/MultipeSearchCheckbox";

import GenericSelectRedux from "../Menu/GenericSelectRedux";
import { SetSessionExpired } from "../../Actions";
import { Paper, Box } from "@mui/material";
import { OutlinedButton } from "../../Components/CustomButton";
import Events from "../../Analytics/Events";
import { encryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";

function AdvanceSearchBatteries(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const batteryPartnerSelect = useSelector(
    (state) => state.AllBatteriesBatteryPartnerSelect.value
  );
  const userInputBatteries = useSelector(
    (state) => state.AllBatteriesUserInputBatteries.value
  );
  const foundBatteriesRedux = useSelector(
    (state) => state.AllBatteriesFoundBatteries.value
  );
  const multipePartners = useSelector(
    (state) => state.AllBatteriesMultiplePartners.value
  );

  function handleSetSelect(name, value) {
    dispatch(SetAllBatteriesBatteryPartnerSelect(value));
    dispatch(SetAllBatteriesUserInputBatteries([]));
    dispatch(SetAllBatteriesMultiplePartners([]));
  }

  function handleClickSearch() {
    let temp;
    if (batteryPartnerSelect === "Battery ID") {
      temp = {
        userInputAllBatteries: userInputBatteries,
        multipleAllPartners: [],
        prevLinkAllBatteries: location.pathname + location.search,
      };
    } else {
      temp = {
        userInputAllBatteries: [],
        multipleAllPartners: multipePartners,
        prevLinkAllBatteries: location.pathname + location.search,
      };
    }
    const que = new URLSearchParams(temp).toString();
    const hash = encryptTheParams(que);
    navigate(`/AllBatteries?${hash}`);
  }


  useEffect(() => {
    if (
      batteryPartnerSelect === "Battery ID" &&
      userInputBatteries.length > 0
    ) {
      getFoundAndNotFoundBatteries(userInputBatteries).then((res) => {
        if (
          (res && res.responseStatus.code === 200) ||
          res.responseStatus.code === 204 ||
          res.responseStatus.code === 1999
        ) {
          dispatch(
            SetAllBatteriesNotFoundBatteries(res.response.batteriesNotFound)
          );
          const notFoundBatteriesSet = new Set(res.response.batteriesNotFound);
          const batteriesFound = userInputBatteries.filter((name) => {
            return !notFoundBatteriesSet.has(name);
          });

          dispatch(SetAllBatteriesFoundBatteries(batteriesFound));
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          dispatch(SetAllBatteriesNotFoundBatteries([]));
          dispatch(SetAllBatteriesFoundBatteries([]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batteryPartnerSelect, userInputBatteries, multipePartners]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={props.advancedSearch}
      onClose={props.handleCloseAdvancedSearch}
      maxWidth="lg"
      PaperProps={{
        sx: {
          width: "100%",
        },
      }}
    >
      <div style={{ padding: "16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 0px",
          }}
        >
          <Typography variant="paperHeader">Advance Search</Typography>
          <IconButton
            onClick={props.handleCloseAdvancedSearch}
            fontSize="small"
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </div>

        <div>
          <Typography style={{ width: "500px" }}></Typography>
        </div>

        <Paper
          elevation={0}
          sx={{
            height: "100%",
            borderRadius: "12px",
            p: 1,
            mt: 2.5,
            display: "flex",
            alignItems: "center",
            border: "1px solid #ececec",
          }}
        >
          <GenericSelectRedux
            filter={{
              items: ["Battery ID", "Occupant"],
              itemName: "Select",
            }}
            handleSetFilter={handleSetSelect}
            selected={batteryPartnerSelect}
            maxWidth={"max-content"}
            showClearButton={false}
            id="batteryState"
          />
          {batteryPartnerSelect === "Battery ID" ? (
            <MultipleSearchState
              type="Advance Search"
              readOnly={false}
              limitTags={100}
              maxHeight={400}
            />
          ) : (
            <MultipeSearchCheckbox data={[]} type="Advance Search" />
          )}
        </Paper>
        <br></br>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <OutlinedButton
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => {
              let text =
                batteryPartnerSelect === "Battery ID"
                  ? "Advance Search batteries searched"
                  : "Advance Search single occupants searched";
              Events(text);
              handleClickSearch();
              props.handleCloseAdvancedSearch();
            }}
            disabled={
              foundBatteriesRedux.length === 0 && multipePartners.length === 0
            }
          >
            <Typography variant="textSubtitleWhite">Search</Typography>
          </OutlinedButton>
        </Box>
      </div>
    </Dialog>
  );
}

export default AdvanceSearchBatteries;
