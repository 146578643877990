import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
} from "@mui/material";

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileFilledIcon from "@mui/icons-material/InsertDriveFile";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DownloadCSV from "../../../Helper/CSVOperations/DownloadCSV";
import { BatchActionIconButton, PrimaryFormLoadingActionButton, PrimaryTableChip } from "./TableStyles";
import { BatchEmailIcon } from "../Icons";
import { useTranslation } from "react-i18next";


const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    width: "600px",
    maxWidth: "90%",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  backgroundColor: "#424242",
  color: "#fff",
}));

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    fontSize: "14px",
    autocomplete: '0ff'
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none !important",
  },
  "& .MuiInput-underline:hover:before": {
    borderBottom: "none !important",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
});

const EmailChipsInput = ({ placeholder, emails, setEmails, addCcButton, availableEmailDomains }) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setHelperText("Invalid email format.");
      return false;
    }

    const domain = email.split("@")[1];
    if (!availableEmailDomains.includes(domain)) {
      setHelperText(`Invalid domain. Valid domains are: ${availableEmailDomains.join(", ")}`);
      return false;
    }

    setHelperText("");
    return true;
  };

  const handleKeyDown = (e) => {
    setError(false);
    setHelperText("");
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();

      if(!isValidEmail(inputValue.trim())) {
        setError(true);
        return;
      }

      setEmails([...emails, inputValue.trim()]);
      setInputValue("");
      setError(false);
    }
  };

  const handleRemoveEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        borderBottom: "1px solid #EDEFF1",
        paddingBottom: "4px",
        marginBottom: "12px",
      }}
    >
      {emails.map((email, index) => (
        <PrimaryTableChip 
          key={index} 
          label={email} 
          onDelete={() => handleRemoveEmail(index)}
          deleteIcon={<CloseRoundedIcon />}
        />
      ))}
      <StyledTextField
        fullWidth
        autoComplete="off"
        variant="standard"
        placeholder={emails.length > 0 ? "" : placeholder}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{ flex: 1, minWidth: "150px" }}
        error={true}
        helperText = {helperText}
      />
      {addCcButton && (
        <Button
          onClick={addCcButton}
          size="small"
          sx={{
            textTransform: "none",
            color: "#0000008A",
            fontSize: "14px",
          }}
        >
          Cc
        </Button>
      )}
    </Box>
  );
};

const EmailComposer = ({ 
    active, 
    selectedRows, 
    getCSVData, 
    availableEmailDomains,
    onSendEmail
}) => {
  
  const {t} = useTranslation(['common', 'generic_table'])

  const [open, setOpen] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const [emails, setEmails] = useState([]); // Recipients list
  const [ccEmails, setCcEmails] = useState([]); // CC list
  const [formData, setFormData] = useState({ subject: "", body: "" });
  const [csvData, setCsvData] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const body =  t('generic_table:email_composer.body', {count: selectedRows.length})

  const prepareCsvData = async () => {
    if(selectedRows.length > 0) {
        let csvData = await getCSVData()
        setCsvData(csvData)
    }
  }
  
  useEffect(() => {
    prepareCsvData()
  }, [selectedRows])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev,  body,  [name]: value }));
  };

  const handleToggleCc = () => {
    setShowCc(true);
  };

  const handleSend = async () => {
    setIsLoading(true);
    try {
      await onSendEmail({
        sendTo: emails,
        sendCc: ccEmails,
        ...formData,
      });
      setIsLoading(false);
      handleClose();
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setShowCc(false);
    setEmails([]);
    setCcEmails([]);
    setFormData({ subject: "" });
  };

  return (
    <>
        <BatchActionIconButton onClick={handleOpen} active = {active}>
            <BatchEmailIcon fontSize="inherit" />
        </BatchActionIconButton>

      <StyledDialog open={open} onClose={handleClose}>
        <Header>
          <Typography variant="fw500s14lh16cFFFFFF">{t('generic_table:email_composer.title')}</Typography>
          <IconButton onClick={handleClose} size="small" sx={{ color: " #FFFFFF99" }}>
            <CloseIcon fontWeight = 'bold' />
          </IconButton>
        </Header>

        {/* Dialog Content */}
        <DialogContent dividers sx = {{ minHeight: "350px"}}>
          {/* Recipients input */}
          <EmailChipsInput
            placeholder= {t('generic_table:email_composer.recipients_placeholder')}
            emails={emails}
            setEmails={setEmails}
            addCcButton={!showCc ? handleToggleCc : null}
            availableEmailDomains = {availableEmailDomains}
          />

          {/* CC input */}
          {showCc && <EmailChipsInput placeholder="Cc" emails={ccEmails} setEmails={setCcEmails} availableEmailDomains = {availableEmailDomains} />}

          {/* Subject Input */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              borderBottom: "1px solid #EDEFF1",
              paddingBottom: "4px",
              marginBottom: "12px",
            }}
          >
            <StyledTextField
              fullWidth
              autoComplete="off"
              variant="standard"
              placeholder={t('generic_table:email_composer.subject_placeholder')}
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </Box>

          <Box sx={{ padding: "0px 8px", borderRadius: "4px"}}>
            <Typography variant="fw400s14lh16c4B4B4B"> {body} </Typography>
          </Box>

          {/* File Attachment */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "12px",
              padding: "8px 16px",
              backgroundColor: "#F2F4F7",
              borderRadius: "12px",
              fontSize: "14px"
            }}
          >
            <InsertDriveFileFilledIcon sx={{ color: "#424242", fontSize: 20 }} />
            <Box sx={{ flexGrow: 1, marginLeft: "10px" }}>
              <Typography variant="fw400s12lh14c4B4B4B"> {t('generic_table:email_composer.attachment_name')} </Typography>
            </Box>
            <IconButton sx={{ color: "#6D72F6" }} onClick={() => DownloadCSV("Document.csv", csvData)}>
              <DownloadForOfflineOutlinedIcon  sx={{ fontSize: '20px' }} />
            </IconButton>
          </Box>
        </DialogContent>

        {/* Send Button */}
        <DialogActions sx={{ padding: "8px 16px", justifyContent: "flex-start" }}>
          <PrimaryFormLoadingActionButton
            disabled = {!formData.subject || emails.length < 1 }
            onClick={handleSend}
            loading={isLoading}
          > {t('actions.send')}
          </PrimaryFormLoadingActionButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default EmailComposer;
