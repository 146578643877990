import copy from "copy-to-clipboard";



const encryptQueryParams = (queryString = '', key = 'default-secret-key') => {
  if (key.length < 16) return 'key must be at least 16 characters long';

  // Simple Base64 Encoding
  const encoded = btoa(unescape(encodeURIComponent(queryString)));

  return encoded;
};

const decryptQueryParams = (encryptedHash = '', key = 'default-secret-key') => {
  try {
      // Simple Base64 Decoding
      const decoded = decodeURIComponent(escape(atob(encryptedHash)));

      // Convert back to an object
      return Object.fromEntries(new URLSearchParams(decoded));
  } catch (error) {
      console.error("Decryption failed:", error);
      return {};
  }
};

const decryptTheParams = (query = null) => {
  if (!query) {
    query = window.location.search.slice(1);
  }
  const queryParams = decryptQueryParams(query);
  return queryParams;
};

const encryptTheParams = (temp, navigate = null, specificBattery = null, path=null, openInNewTab=false) => {
  const que = new URLSearchParams(temp).toString();
  const hash = encryptQueryParams(que);

  if(!navigate) {
    return hash;
  }
  
  if (specificBattery) {
    if (temp && temp.navigateFromTab === true) {
      window.open(`/specificBattery?${hash}`, "_blank", "noreferrer");
    } else {
      navigate(`/specificBattery?${hash}`);
    }
  } else if (path) {
    if (openInNewTab){
      window.open(`${path}?${hash}`, "_blank", "noreferrer");
      return
    }
    navigate(`${path}?${hash}`);
  } else {
    navigate(`${window.location.pathname}?${hash}`);
  }
};

const encryptTheParamsForOldDigitalTwinPage = (temp, navigate, specificBattery, path) => {  // Temporary Changes to access old Digital Twin Page
  const que = new URLSearchParams(temp).toString();
  const hash = encryptQueryParams(que);
  if (specificBattery) {
    if (temp && temp.navigateFromTab === true) {
      window.open(`/specificBatteryOld?${hash}`, "_blank", "noreferrer");
    } else {
      navigate(`/specificBatteryOld?${hash}`);
    }
  } else if (path) {
    navigate(`${path}?${hash}`);
  } else {
    navigate(`${window.location.pathname}?${hash}`);
  }
};

const setTheFilterValues = (filterName, value, navigate) => {
  let temp;
  const queryParams = decryptTheParams();

  if (value !== "") {
    temp = {
      ...queryParams,
      [filterName]: value,
    };
  } else {
    temp = Object.fromEntries(
      Object.entries(queryParams).filter(([key, val]) => key !== filterName)
    );
  }
  delete temp["id"];
  if (Object.keys(temp).length === 0) {
    navigate(`${window.location.pathname}`);
  } else {
    encryptTheParams(temp, navigate);
  }
};

const resetTheFilterValues = (arr, navigate) => {
  const queryParams = decryptTheParams();
  arr.forEach((filterName) => {
    for (let key in queryParams) {
      if (key === filterName || key === "endDate" || key === "startDate") {
        delete queryParams[key];
      }
    }
  });
  encryptTheParams(queryParams, navigate);
};

const updateQueryParams = (navigate, params, isDigitalTwinPage=false) => {
  const queryParams = decryptTheParams()
  let temp = {
    ...queryParams,
    ...params
  };
  encryptTheParams(temp, navigate, isDigitalTwinPage);
}

const copyCurrentURLToClipboard = () => {
  const currentURL = window.location.href;
  copy(currentURL);
};

export {
  updateQueryParams,
  decryptTheParams,
  encryptTheParams,
  setTheFilterValues,
  resetTheFilterValues,
  copyCurrentURLToClipboard,
  encryptTheParamsForOldDigitalTwinPage  // Temporary Changes to access old Digital Twin Page
};
