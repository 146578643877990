import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/material";

const AntSwitch = styled(Switch)(
  ({ theme, width, height, thumbSize, checkedColor, uncheckedColor }) => ({
    width: width, 
    height: height,
    padding: 6,
    display: "flex",
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        transform: `translateX(${width / 3}px)`, // Dynamic translation
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: checkedColor,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: thumbSize,
      height: thumbSize * 0.9,
      borderRadius: 40,
    },
    "& .MuiSwitch-track": {
      borderRadius: height / 2,
      backgroundColor: uncheckedColor,
      opacity: 1,
    },
  })
);

function CustomSwitchLarge({
  checked,
  onChange,
  style,
  width = 54,
  height = 36,
  thumbSize = 20,
  checkedColor = "#768CFF",
  uncheckedColor = "#B0B0B0",
}) {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    if (onChange) onChange(event.target.checked);
  };

  return (
    <Box style={style}>
      <AntSwitch
        checked={isChecked}
        onChange={handleChange}
        width={width}
        height={height}
        thumbSize={thumbSize}
        checkedColor={checkedColor}
        uncheckedColor={uncheckedColor}
      />
    </Box>
  );
}

export default CustomSwitchLarge;
