import moment from "moment";
import momentTZ from "moment-timezone";
import Cookies from "universal-cookie";
import { formatDateNumeric } from "./DateFormatters";
import dayjs from "dayjs";
import utc1 from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const cookies = new Cookies();
const testTimezone = "Asia/Kolkata";
dayjs.extend(utc1);
dayjs.extend(timezone);
dayjs.tz.setDefault(cookies.get("timeZone"));

function sendTheConvertedDate(m, format) {
  return m.format(format);
  // if (testTimezone === cookies.get("timeZone")) return m.format(format);
  // else return m.utc().format(format);
}

var ist_offset = -5.5 * 60;
// const timeZone = cookies.get("timeZone");

function UTCtoISTConverter(date) {
  var d = new Date(date);

  d.setMinutes(d.getMinutes() - ist_offset);

  return d;
}

function getChartTimeStamp(date) {
  var format = "YYYY-MM-DD hh:mm:ss A";
  var zone = cookies.get("timeZone");

  return moment.utc(date, "YYYY-MM-DD HH:mm:ss")?.tz(zone)?.format(format);
}

function getDatePickerDate(date, daysToAdd = 0) {
  var zone = cookies.get("timeZone");

  if (date !== undefined)
    return moment.utc(new Date(date))?.tz(zone)?.add(daysToAdd, "days")?.format("YYYY-MM-DD");
  else return moment.utc(new Date())?.tz(zone)?.add(daysToAdd, "days")?.format("YYYY-MM-DD");
}

function getMetricTimeStamp(date, format = "DD MMM'YY hh:mm A") {
  var zone = cookies.get("timeZone");
  let momentDate = moment.utc(date, "YYYY-MM-DD HH:mm:ss")?.tz(zone)

  if (format === 'relative') {
    return momentDate?.fromNow();
  }

  return momentDate?.format(format);
}

function convertToZoneDateNumeric(datestring) {
  return moment(convertZonetoUTCDate(datestring), "YYYY-MM-DD").toDate();
}

function convertToZoneDate(datestring) {
  return moment(convertZonetoUTCDate(datestring)).toDate();
}

function convertUTCtoZone(date, seconds) {
  var dString = date + " UTC";
  var g = new Date(
    (typeof dString === "string" ? new Date(dString) : dString).toLocaleString(
      "en-US",
      { timeZone: cookies.get("timeZone") }
    )
  );

  if (seconds) {
    var finalDate = moment(g).format("DD MMM'YY, hh:mm:ss A");
    if (finalDate === "Invalid date") return date;
    else return finalDate;
  } else {
    var finalDate2 = moment(g).format("DD MMM'YY, hh:mm A");
    if (finalDate2 === "Invalid date") return date;
    else return finalDate2;
  }
}

function convertUTCtoZoneYYYYMMDD(date) {
  var dString = date + " UTC";
  var g = new Date(
    (typeof dString === "string" ? new Date(dString) : dString).toLocaleString(
      "en-US",
      { timeZone: cookies.get("timeZone") }
    )
  );
  var finalDate = moment(g).format("YYYY-MM-DD HH:mm:ss");
  if (finalDate === "Invalid date") return date;
  else return finalDate;
}

function convertZonetoUTCDate(date) {
  var dString = date + " 00:00:00";

  var format = "YYYY-MM-DD";
  var zone = cookies.get("timeZone");

  var m = moment.tz(dString, format, zone);
  // console.log(m.utc().format(format))
  let convertedDate = sendTheConvertedDate(m, format);
  return convertedDate;
}

function convertZonetoUTCStart(date) {
  if (!date) {
    return null
  }

  var dString = date + " 00:00:00";

  var format = "YYYY-MM-DD HH:mm:ss";
  var zone = cookies.get("timeZone");

  var m = moment.tz(dString, format, zone);
  var utcDate = m.utc().format(format);

  if (utcDate === 'Invalid date') {
    return date
  }

  return utcDate
}

function convertZonetoUTCEnd(date) {
  if (!date) {
    return null
  }

  var dString = date + " 00:00:00";
  var d = new Date(dString);
  d.setMinutes(d.getMinutes() + 24 * 60);

  var newDString = formatDateNumeric(d) + " 00:00:00";

  var format = "YYYY-MM-DD HH:mm:ss";
  var zone = cookies.get("timeZone");

  var m = moment.tz(newDString, format, zone).subtract(1,'seconds');
  var utcDate = m.utc().format(format);

  if (utcDate === 'Invalid date') {
    return date
  }

  return utcDate
}

function convertAndGetTimeStampInRequiredFormat(date, format)
{
  if (!date) {
    return date
  }
  var zone = cookies.get("timeZone");

  return moment?.utc(date, 'YYYY-MM-DD HH:mm:ss')?.tz(zone)?.format(format)
}

function getCSVTimeStamp(date, format) {
  if (!date) {
    return date;
  }
  var zone = cookies.get("timeZone");

  return moment.utc(date, "DD MMM'YY, hh:mm:ss A").tz(zone).format(format);
}

const getCurrentTimestamp = (format="DD MMM'YY, hh:mm:ss A") => {
  var zone = cookies.get("timeZone");
  return moment.utc().tz(zone).format(format);
}

const getPreviousMonthDate = (dateString, format='YYYY-MM-DD') => {
  if (!dateString) {
    dateString = getDatePickerDate()
  }
  const date = moment(dateString);
  const previousMonthDate = date.clone().subtract(1, 'months');

  return previousMonthDate.format(format)
}

// We will removed this function later. This will be used until we start receiving CK realtime events data
const geDateRangesForCk = () => {
  const startDate = "2024-12-01"
  const endDate = "2024-12-31"

  return [startDate, endDate]
}

const getCurrentMonthStartAndEndDate = () => {
  const format='YYYY-MM-DD'
  const currentDate = moment(getDatePickerDate())
  const startDate = currentDate.startOf('month').format(format);
  const endDate = currentDate.endOf('month').format(format);

  return [startDate, endDate]
}

const analyticsDisabledDate = (current) => {
  if (!current) {
    return false
  }
  current = current.format('YYYY-MM-DD')
  const minDate = dayjs().tz().add(-1, "d").subtract(6, "month").format('YYYY-MM-DD')
  const maxDate = dayjs().tz().format('YYYY-MM-DD')
 
  return current < minDate || current >= maxDate
};

const analyticsCalendarEndDate = () => {
  return moment(getDatePickerDate()).subtract(1, 'days').format('YYYY-MM-DD')
}

// This is a temporary function which will be removed once we start receiving live data for CK
const ckDisabledDate = (current) => {
  if (!current) {
    return false
  }
  current = current.format('YYYY-MM-DD')
  const minDate = '2024-01-01'
  const maxDate = getDatePickerDate()
 
  return current < minDate || current >= maxDate
};

const changeDateFormat = (dateStr, format = "DD MMM YYYY") => {
  if (!dateStr) return "";
  return moment(dateStr).format(format);
};

export {
  ckDisabledDate,
  geDateRangesForCk,
  getPreviousMonthDate,
  UTCtoISTConverter,
  convertUTCtoZone,
  convertUTCtoZoneYYYYMMDD,
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
  convertToZoneDate,
  convertToZoneDateNumeric,
  getChartTimeStamp,
  getMetricTimeStamp,
  getDatePickerDate,
  getCSVTimeStamp,
  analyticsDisabledDate,
  getCurrentTimestamp,
  analyticsCalendarEndDate,
  getCurrentMonthStartAndEndDate,
  convertAndGetTimeStampInRequiredFormat,
  changeDateFormat
};
