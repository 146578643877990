import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Tooltip } from "antd";
import { styled } from '@mui/system';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";

const StatusDot = styled('span')(({ theme, color }) => ({
  display: 'inline-block',
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: theme.spacing(1),
}));

const DeviceIDLink = ({ record, config, iconButtonStyle = {}, iconStyle = {}, textStyle = {}, onTextClick }) => {
  const idText = record?.[config?.dataIndex];
  const isActive = record?.pingStatus?.toLowerCase() === 'active';

  return (
    <Box display="flex" alignItems="center">
      <StatusDot color={isActive ? '#09B820' : '#8F8F8F'} />
      <Typography
        variant="fw400s14lh24c0061CA"
        onClick={() => onTextClick()}
        sx={{
          ":hover": {
            color: '#4D44D8' + " !important",
            cursor: "pointer",
          },
        }}
      >
        {idText}
      </Typography>

      <Tooltip title={"Click to copy"} placement={'top'}>
        <IconButton
          style={{ 
            ...iconButtonStyle
           }}
          onClick={(event) => {
            event.stopPropagation();
            copy(idText);
          }}
        >
          <ContentCopyIcon
            style={{
              fontSize: "13px",
              cursor: "pointer",
              "&:hover": {
                color: "4D44D8",
              },
              ...iconStyle
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default DeviceIDLink;
