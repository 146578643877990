import { decryptTheParams } from "../QueryParams/EncryptDecrypt";


const ScrollToTheElement = () => {
  let paramObj = {};
  const queryParams = decryptTheParams();
  if (queryParams.id == null) {
    return;
  }
  for (var value of Object.keys(queryParams)) {
    if (value === "id") {
      paramObj[value] = queryParams[value];
    }
  }

  if (paramObj.id) {
    document.getElementById(paramObj.id)?.classList.add("view");
    document.getElementById(paramObj.id)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
};
export default ScrollToTheElement;
