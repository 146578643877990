import { Typography, Box, IconButton } from "@mui/material";
import { SortIconDown, SortIconUp } from "../../Icons";
import { useTranslation } from 'react-i18next';
import { normalizeKey } from "../../../../Helper/Common/common_helpers";


const TableHeader = ({ titleProps, config }) => {
	const { t } = useTranslation();
	const title = t(`generic_table:columns.${normalizeKey(config.title)}`, config.title);
	const key = config?.dataIndex;
	const sorter = config?.sorter;

	const sortedColumn = titleProps?.sortColumns?.find(({ column }) => column.key === key);
	const isAscActive = sortedColumn?.order === "ascend";
	const isDescActive = sortedColumn?.order === "descend";

	return (
		<Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
			<Box display="flex" alignItems="center" justifyContent={'space-between'}>
				<Typography sx={{
					marginRight: '12px',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis'
				}} onClick={(e) => e.stopPropagation()} variant="fw500s14lh24c2E2E2E">{title}</Typography>

				{sorter && (
					<Box display="flex" flexDirection="column" alignItems="center">
						<IconButton size="small" sx={{ width: 10, height: 2, p: 0 }}>
							<SortIconUp sx={{ fontSize: "17px", color: isAscActive ? "#6D72F6" : "#BBBBBB" }} />
						</IconButton>

						<IconButton size="small" sx={{ width: 10, height: 2, p: 0 }}>
							<SortIconDown sx={{ fontSize: "17px", color: isDescActive ? "#6D72F6" : "#BBBBBB" }} />
						</IconButton>
					</Box>
				)}

			</Box>
		</Box>

	);
};

export default TableHeader;
