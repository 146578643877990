import { SvgIcon } from "@mui/material";
import React from "react";

const ImageIcon = ({
  fill = "none",
  stroke = "#444B51",
  strokeWidth = 2,
  viewBox = "0 0 18 18",
  width = "18",
  height = "18",
  ...props
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    sx={{ fill, display: "block" }}
    {...props}
  >
    <path d="M14.2499 2.25H3.74999C2.92157 2.25 2.25 2.92157 2.25 3.74999V14.2499C2.25 15.0783 2.92157 15.7499 3.74999 15.7499H14.2499C15.0783 15.7499 15.7499 15.0783 15.7499 14.2499V3.74999C15.7499 2.92157 15.0783 2.25 14.2499 2.25Z" />
    <path d="M15.75 11.25L12 7.5L3.75 15.75" />
    <path d="M6.37513 7.50026C6.99652 7.50026 7.50026 6.99652 7.50026 6.37513C7.50026 5.75374 6.99652 5.25 6.37513 5.25C5.75374 5.25 5.25 5.75374 5.25 6.37513C5.25 6.99652 5.75374 7.50026 6.37513 7.50026Z" />
  </SvgIcon>
);

export default ImageIcon;
