export const normalizeKey = (key) => {
    const updatedKey =  key
        ?.replace(/([a-z])([A-Z])/g, '$1_$2') // Convert camelCase to snake_case
        .toLowerCase()
        .replace(/\s+/g, '_')  // Replace spaces with underscores
        .replace(/[^a-z0-9_]/g, '')  // Remove special characters except underscores
        .replace(/_+$/g, ''); // Remove trailing underscores

    return updatedKey
};
