import { SvgIcon } from "@mui/material";
import React from "react";

const EnergyEfficiencySvg = ({ 
  width = "22", 
  height = "22", 
  viewBox = "0 0 22 22", 
  fill = "none", 
  stroke = "#444B51", 
  strokeWidth = 0.916667, 
  ...props 
}) => (
  <SvgIcon
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={width}
    height={height}
    sx={{ fill, display: "block" }}
    {...props}
  >
    <path 
      d="M21 11.3448C21 5.63154 16.3684 1 10.6552 1" 
      stroke={stroke} 
      strokeWidth={strokeWidth} 
      strokeDasharray="1.38 1.38" 
    />
    <path 
      d="M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11" 
      stroke={stroke} 
      strokeWidth={strokeWidth} 
    />
    <path 
      d="M11 1C5.47715 1 1 5.47715 1 11" 
      stroke={stroke} 
      strokeWidth={strokeWidth} 
    />
    <path 
      d="M8.32845 11.8266H11.0698L10.2237 16.8949C10.1891 17.1064 10.3143 17.3093 10.5172 17.3784C10.5604 17.3913 10.6079 17.4 10.6511 17.4C10.8108 17.4 10.9619 17.3093 11.0353 17.1625L14.7481 9.73293C14.8128 9.59909 14.8085 9.43936 14.7308 9.31417C14.6531 9.18897 14.5149 9.11126 14.3638 9.11126H11.7347L12.9133 5.94678C12.9608 5.81295 12.9435 5.66617 12.8615 5.5496C12.7794 5.42872 12.6499 5.35965 12.5075 5.35965H10.1849C9.99923 5.35965 9.83518 5.48053 9.77473 5.65753L7.91835 11.2614C7.87518 11.3909 7.89677 11.5377 7.9788 11.6499C8.06082 11.7622 8.1903 11.8266 8.32845 11.8266ZM10.4957 6.2227H11.8858L10.7072 9.38718C10.6597 9.52102 10.677 9.6678 10.759 9.78436C10.841 9.90092 10.9705 9.97 11.113 9.97H13.6644L11.5447 14.2051L12.0024 11.4681C12.0239 11.3429 11.9894 11.2134 11.9074 11.1184C11.8254 11.0234 11.7045 10.9673 11.5793 10.9673H8.92854L10.4957 6.2227Z" 
      fill={stroke} 
    />
  </SvgIcon>
);

export default EnergyEfficiencySvg;
