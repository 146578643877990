import React from "react";
import { NavLink as NavLinkBase } from "react-router-dom";
import { Box, ListItem } from "@mui/material";
import Events from "../../../Analytics/Events";
import { Tooltip } from "antd";
import CustomIcon from "../../../Components/CustomIcon"
import { NavListItemBox, NavListItemContainer, NavListItemText } from "../../../Components/StyledComponent";

const NavLink = React.forwardRef((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={props.activeClassName}
  />
));

const MyNavLink = (props) => {
  const isActivePage = window.location.pathname === props.to || (window.location.pathname === '/' && props.to === '/inventory')

  return (
    <NavListItemContainer
      component={NavLink}
      activeClassName={({ isActive }) =>
        (isActive || isActivePage) ? "activeClass" : "inactiveClass"
      }
      to={props.to}
      onClick={(event) => {
        Events("clicked " + props.textPrimary);
      }}
      key={props.mykey}
    >
      <Tooltip
        placement="right"
        title={!props.open ? props.textPrimary : ""}
        zIndex={2000}
      >
        <NavListItemBox
          key={props.mykey}
        >
          <CustomIcon
            name={props.iconName}
            isSelected={isActivePage}
            style={{fontSize: "19px", padding: 0, paddingLeft: "1px"}}
          />
          {props.open && (
            <NavListItemText isSelected={isActivePage}>
              {props.textPrimary}
            </NavListItemText>
          )}
        </NavListItemBox>
      </Tooltip>
    </NavListItemContainer>
  );
};

export default MyNavLink;
