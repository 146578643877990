function SecondsFormatter(interval) {
    let alertTime = 0,midvalue = 0;
    if (interval < 60)
    {
        if (interval === 1)
            alertTime = "1 sec"
        else
            alertTime = interval + " secs "
    }
    if (60 <= interval  && interval < 3600)
    {
        midvalue = Math.round(interval/60);
        if (midvalue === 1)
            alertTime = "1 minute "
        else
            alertTime = midvalue + " minutes "
    }
    if (3600 <= interval && interval < 86400)
    {
        midvalue = Math.round(interval/(60*60));
    if (midvalue === 1)
            alertTime = "1 hour"
        else
            alertTime = midvalue + " hours "
    }
    if (interval > 86400)
    {
        midvalue = Math.round(interval/(60*60*24));
        if (midvalue === 1)
            alertTime = "1 day "
        else
            alertTime = midvalue + " days "
    }

    return alertTime
}

export default SecondsFormatter;