import React, { useEffect, useState } from "react";
import MapMain from "./MapMain";
import { getPagesComponents } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch } from "react-redux";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import {
  SetMapAlertType,
  SetMapBatteryPartnerSelect,
  SetMapBatteryVendor,
  SetMapCity,
  SetMapIotVendor,
  SetMapMultiplePartners,
  SetMapSeverity,
  SetMapStatus,
  SetMapUserInputBatteries,
  SetMapVolt,
  SetMapZone,
  SetOrgFilter,
} from "../../Actions/Map";
import Cookies from "universal-cookie";
import { getLocationFilter, getOrgFilter } from "../../Api/MapApi";
import { GetFilterKeyValue } from "../../Helper/Operations/GetFilterKeyValue";
import { SetDiagnosticsCity } from "../../Actions/Diagnostics";
import CODE from "../../Static/Constants/StatusCodes";
import AppCache from "../../Helper/Common/AppCache";

function getActualArrayValues(data) {
  let arr = data.length === 0 ? [] : data.split(",");
  return arr;
}

function Map(props) {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    filteredArray: [],
    batteryType: "",
    mounted: false,
  });

  const [locationFilter, setLocationFilter] = useState({
    data: [],
    responseStatus: {
      code: -1,
    },
  });

  const [orgFilter, setOrgFilter] = useState({
    data: [],
    responseStatus: {
      code: -1,
    },
  });

  function SetTheFilters(locationFilter, orgFilter) {
    setTimeout(() => {
      let newFilterWithData = [];

      const filters = [
        {
          itemName: "Organization",
          items: orgFilter,
        },
        {
          itemName: "Status",
          items: [
            {
              value: "Idle",
              id: "0",
            },
            {
              value: "Charging",
              id: "4",
            },
            {
              value: "Discharging",
              id: "2",
            },
          ],
        },
        {
          itemName: "Location",
          items: locationFilter,
        },
        {
          itemName: "IOT",
          items: [
            {
              value: "TrakMate",
              id: "TrakMate",
            },
          ],
        },
        {
          itemName: "Product ID",
          items: [
            {
              value: "AAUAAD-29-100080-001",
              id: "AAUAAD-29-100080-001",
            },
          ],
        },
      ];
      //res.response.response.filter.filtersWithData;
      filters.map(
        (
          item //res.response.response.filter.filtersWithData
        ) => newFilterWithData.push(item["itemName"])
      );
      const filteredArray = newFilterWithData;

      let arr = localStorage.getItem("InputMapBatteries");
      if (arr) {
        dispatch(SetMapUserInputBatteries(arr.split(",")));
      } else {
        dispatch(SetMapUserInputBatteries([]));
      }

      const queryParams = decryptTheParams();

      for (var value of Object.keys(queryParams)) {
        if (filteredArray.includes(value)) {
          if (value === "Location") {
            dispatch(SetMapCity(getActualArrayValues(queryParams[value])));
          } else if (value === "Status") {
            dispatch(SetMapStatus(getActualArrayValues(queryParams[value])));
          } else if (value === "Organization") {
            dispatch(SetOrgFilter(getActualArrayValues(queryParams[value])));
          } else if (value === "IOT") {
            dispatch(SetMapIotVendor(getActualArrayValues(queryParams[value])));
          } else if (value === "Cell") {
            dispatch(
              SetMapBatteryVendor(getActualArrayValues(queryParams[value]))
            );
          }
        } else {
          if (value === "batteryPartnerSelect") {
            dispatch(SetMapBatteryPartnerSelect("Device ID"));
          }
        }
      }
      setPagesContent({
        ...pagesContent,
        pageHeader: "Map",
        filtersWithData: filters, //res.response.response.filter.filtersWithData
        filteredArray: filteredArray,
        searchTypeFilter: {
          //res.response.response.filter.searchTypeFilter
          items: [
            // "Battery ID",
            "Device ID",
          ],
          itemName: "Select",
        },
        batteryType: "",
        mounted: true,
      });
    }, 2);
  }

  function SetLocationFilterState(code) {
    const filtersInLocalStorage = AppCache.getItem("LocationFilter")
    console.log("filtersInLocalStorage", filtersInLocalStorage)
    
    setLocationFilter({
      data: filtersInLocalStorage ? GetFilterKeyValue(filtersInLocalStorage) : [],
      responseStatus: {
        code: code,
      },
    });
  }

  function GetLocationFilter() {
    getLocationFilter().then((res) => {
      dispatch(SetMapCity(res.response?.locationFilterData || []));
      dispatch(SetDiagnosticsCity(res.response?.locationFilterData || []));
      cookies.set("locationFilter", res.response?.locationFilterData || [], {
        path: "/",
        maxAge: 1800, //1800
      });
      AppCache.setItem("LocationFilter", (res.response?.locationFilterData || []))
      SetLocationFilterState(CODE.SUCCESS);
    });
  }

  function SetOrgFilterState(code) {
    setOrgFilter({
      data: GetFilterKeyValue(localStorage.getItem("OrgFilter")),
      responseStatus: {
        code: code,
      },
    });
  }

  function GetOrgFilter() {
    getOrgFilter().then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS) {
        let fleetName = res.response.filterData.find(
          (i) => i.type === "fleetName"
        );
        if (fleetName !== undefined) {
          cookies.set("orgFilter", fleetName.value, {
            path: "/",
            maxAge: 86400,
          });
          localStorage.setItem("OrgFilter", fleetName.value);
          dispatch(SetOrgFilter(fleetName.value));
        }
      }
      SetOrgFilterState(res.responseStatus.code);
    });
  }

  useEffect(() => {
    const locationFilterInCookies = cookies.get("locationFilter")
    if (!locationFilterInCookies || locationFilterInCookies?.length === 0) {
      GetLocationFilter();
    } else {
      SetLocationFilterState(CODE.SUCCESS);
    }

    if (!cookies.get("orgFilter")) {
      GetOrgFilter();
    } else {
      SetOrgFilterState(CODE.SUCCESS);
    }

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      locationFilter.responseStatus.code === CODE.SUCCESS &&
      orgFilter.responseStatus.code === CODE.SUCCESS
    ) {
      SetTheFilters(locationFilter.data, orgFilter.data);
    }
  }, [locationFilter, orgFilter]);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <MapMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default Map;
