import React from "react"

import { Typography, Box } from "@mui/material"
import CustomIcon from "../../../../Components/CustomIcon"
import { Tooltip } from "antd"
import { useTranslation } from "react-i18next"
import { normalizeKey } from "../../../../Helper/Common/common_helpers"

function LiveMetricSection(props) {
  const {t} = useTranslation(['common', 'digital_twin'])
  const labelStyle = {
    fontFamily: "Roboto",
    fontSize: "10px",
    fontWeight: "400",
    color: "#828A92",
    lineHeight: "16px"
  }

  const valueStyle = {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    color: "#444B51",
    lineHeight: "16px"
  }
  const { metricData, ...other } = props
  return (
    <Box
      sx={{
        display: "flex",
        border: "1px solid #E9E9E9",
        borderRadius: "4px",
        padding: "10px",
        ...props.style
      }}
    >
      <Box
        sx={{
          display: "flex",
          pr: "24px",
          borderRight: "1px solid rgba(135, 147, 157, 0.6)"
        }}
      >
        <Box
          sx={{
            display: "flex",
            mr: "24px"
          }}
        >
          <Tooltip
            placement="top"
            title={
              props.metricData.batteryPingedStatus === "Online"
                ? "Live Signal Values"
                : "Offline"
            }
            zIndex={2000}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingTop: "2px",
                paddingRight: "4px",
              }}
            >
              <CustomIcon
                name={props.metricData.batteryPingedStatus === "Online" ? "online" : "offline"}
                style={{
                  width: "14px",
                  height: "10px"
                }}
              />
            </Box>
          </Tooltip>
          <Box>
            <Typography style={labelStyle}>
              { t(`digital_twin:${normalizeKey(metricData?.lastPingedTime?.label)}`, metricData?.lastPingedTime?.label)}
            </Typography>
            <Typography style={valueStyle}>
              {metricData?.lastPingedTime?.value}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            mr: "24px"
          }}
        >
          <Tooltip
            placement="top"
            title={
              props.metricData.batteryPingedStatus === "Online"
                ? "Live Signal Values"
                : "Offline"
            }
            zIndex={2000}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                paddingTop: "2px",
                paddingRight: "4px",
              }}
            >
              <CustomIcon
                name={props.metricData.batteryPingedStatus === "Online" ? "online" : "offline"}
                style={{
                  width: "14px",
                  height: "10px"
                }}
              />
            </Box>
          </Tooltip>
          <Box>
            <Typography style={labelStyle}>
              { t(`digital_twin:${normalizeKey(props.metricData?.location?.label)}`, props.metricData?.location?.label)}
            </Typography>
            <Typography style={valueStyle}>
              {props.metricData?.location?.value}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          ml: "24px"
        }}
      >
        <Box sx={{ mr: "24px" }}>
          <Typography style={labelStyle}>{metricData?.soh?.label}</Typography>
          <Typography style={valueStyle}>{`${metricData?.soh?.value}${metricData?.soh?.value !== "NA" ? metricData?.soh?.unit : ""
            }`}</Typography>
        </Box>
        <Box sx={{ mr: "24px" }}>
          <Typography style={labelStyle}>
            {metricData?.eqCycle?.label}
          </Typography>
          <Typography style={valueStyle}>
            {metricData?.eqCycle?.value}
          </Typography>
        </Box>
        <Box sx={{ mr: "24px" }}>
          <Typography style={labelStyle}>
            { t(`digital_twin:${normalizeKey(metricData?.maxEnergyStored?.label)}`, metricData?.maxEnergyStored?.label)}  
          </Typography>
          <Typography style={valueStyle}>{`${metricData?.maxEnergyStored?.value
            } ${metricData?.maxEnergyStored?.value !== "NA"
              ? metricData?.maxEnergyStored?.unit
              : ""
            }`}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default LiveMetricSection
